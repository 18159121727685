import React from "react";
import { useNavigate } from "react-router-dom";

import { PopupButton } from "react-calendly";
import ScrollToTop from "../../Reusable/ScrollToTop";


import bidding from "./Img/bidding.png";
import txtbidding from "./Img/text-bidding.png";
import ani1 from "./Img/ani1.png";
import ani2 from "./Img/ani2.png";
import BiddingForm from '../Thefork/BiddingForm';
import Phone from "../../Assets/Phone.svg";
import ques from "./Img/ques.png";
import post1 from "./Img/post1.png";
import post2 from "./Img/post2.png";
import chef from "./Img/chef.png";



import Frame4 from "../../Assets/img1.svg";
import Frame5 from "../../Assets/converti.svg";
import Frame6 from "../../Assets/img3.svg";

import BottomForm from '../../Reusable/BottomForm';
import BiddingForm1 from './BiddingForm1';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";



function Home() {

  const navigate = useNavigate();

  return (
    <div className=' w-full flex flex-col gap-y-8'>
      <ScrollToTop />

      <div className=' flex flex-col '>


{/*------------------------------------------------------ (HERO - soldi) ----------------------------------------------------------------*/}
        <div className='w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px]  mx-auto xsm:w-full sm:w-full  md:w-full  xmd:w-full items-center flex flex-row sm:flex-col xsm:flex-col  sm:mb-10  my-[30px] gap-x-12  h-fit relative ' >
          <div
            className='w-1/2  sm:w-full xsm:w-full md:w-full  xmd:w-full
                                   text-left flex flex-col 
                                   sm:p-5 xmd:p-10 md:p-5 gap-y-[40px] xsm:gap-y-[20px] sm:gap-y-[20px] mx-auto  
                                   items-left justify-center '>

            <p className='Brevia850 text-5xl font-black not-italic leading-[60px] xmd:text-3xl md:text-3xl sm:text-3xl xsm:text-3xl  sm:text-center xsm:text-center text-[#114653]'>
            Just Eat ha i tuoi clienti.
            Smetti di pagare commissioni!
            </p>

            <p className='pop500 text-lg xmd:text-lg sm:text-xmd sm:text-center xsm:text-center text-[#114653]'>
            <b><i>Restaurants Club</i></b> è la piattaforma con cui puoi far conoscere il tuo ristorante online e creare una solida base clienti fedele. Sempre senza commissioni.
            </p>


        <div className='w-full  mx-auto my-6' >
        
        <BiddingForm1 />
        </div>
    
          
       


          </div>

          <div className=' xsm:w-full sm:w-full p-3 m-10 flex items-end justify-end w-1/2 '>
            <img
              src={bidding}
              alt='hero'
              className='h-full w-[80%] mx-auto md:w-full '
            />
          </div>
         
        </div>





{/* --------------------------- RIPRENDI I CLIENTI---------------------------------------------------------------------------------------------------------------- */}

        <div id='wibx' className='flex flex-col my-10 '>


              <div className='w-[80%] px-[40px] mx-auto xsm:w-full sm:w-full  md:w-full  xmd:w-full items-center flex flex-row sm:flex-col xsm:flex-col p-10   '>
                <div className='w-1/3 xsm:mx-auto sm:mx-auto  md:mx-auto '>
                  <div className=' w-[80%] mx-auto flex items-center justify-center xsm:hidden sm:hidden'>
                  <img src={txtbidding}/>
                  </div>
                </div>

                <div className='w-2/3 flex xsm:w-full sm:w-full  md:w-full  xmd:w-full '>
                  <div className='flex flex-col  w-[95%]  px-10 sm:px-0 xsm:px-0 mx-auto '>
                    <p className=' text-5xl xsm:text-4xl sm:text-4xl xsm:text-center sm:text-center  text-[#FC6371] Brevia850 leading-[60px] '>
                    Just Eat ha i tuoi clienti. <br></br>Riprenditeli.
                    </p>
                    <div className='flex flex-col gap-x-4 w-full my-4'>
                      <p className='pop300 text-lg text-[#505050] w-full xsm:text-center sm:text-center'>
                      Con Restaurants Club attrai nuovi clienti grazie a campagne pubblicitarie efficaci sul tuo sito web e app messe a disposizione da Restaurants Club. Il cliente è sempre tuo, mai della piattaforma: crea una base clienti affezionata a te con gli strumenti Cartà Fedeltà digitale e Newsletter.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>






            <div className="blue-grad">
          <div className=' w-[85%] px-[40px]  xsm:w-full sm:w-full    flex flex-col justify-center mx-auto py-10 '>

            <div className='w-full gap-4 flex flex-row xsm:flex-col sm:flex-col  '>

              <div className='w-1/3 xsm:w-full sm:w-full '>
                <p className='text-4xl  text-[#fff] Brevia850 line-height-4'>
                  Riprenditi i tuoi clienti.
                  Inizia ora.
                </p>
              </div>

              <div className='w-2/3 flex flex-col gap-6 xsm:w-full sm:w-full  md:w-full p-3 md:p-5 md:py-3 sm:p-0 mx-auto '>
                <BiddingForm />
              </div>
            </div>

            <div className='  w-full py-3 '>
              <div className=' flex flex-row xsm:flex-col sm:flex-col  md:flex-col gap-5 w-full  m-3' >
              <PopupButton
                className='pop400 text-base text-[#fff] text-underline'
                url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club"
                rootElement={document.getElementById("root")}
                text="Oppure chiamaci per avere maggiori informazioni"

              />
            <WhatsAppIcon color="white" size={20} className="text-white" />{" "}
            <a href="https://wa.me/+393312910408" className="text-white">
              331 291 0408
            </a>{" "}
              </div>
            </div>
          </div>
        </div>



<div className=' w-[80%] px-[40px] xsm:w-full sm:w-full  md:w-full  xmd:w-full  mx-auto flex flex-col   h-fit relative pt-9 py-5 mt-5 '>
          <div className='w-[70%] xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto pb-6 '>
            <p className='Brevia850 text-5xl xsm:text-4xl sm:text-4xl md:text-4xl xmd:text-4xl text-[#114653] text-center font-black '>
            Smetti di pagare commissioni sul tuo lavoro.
            </p>
          </div>

          <div className='flex flex-row sm:flex-col my-2'>
            <div className=' '>
              <div className='flex flex-col items-center w-[80%] xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto  pb-6 '>
                <p className='pop400 text-lg text-center text-black font-medium '>
                Stanco di pagare commissioni che si portano via il tuo fatturato? Ricevere molti ordini online senza marketplace come Just Eat è possibile: utilizza il modulo Delivery sul tuo sito web e app forniti da Restaurants Club e inizia subito a ricevere ordinazioni, senza pagare commissioni.
                </p>
              </div>
            </div>
          </div>
</div>


        <div className=' w-[80%] xsm:w-full sm:w-full  md:w-full  xmd:w-full  px-[40px] mx-auto flex flex-col   h-fit relative pt-9 pb-5 '>
          <div className="animation-color-div-flex">
            <div className="ani-img">
              <img src={ani1} />
            </div>
            <div className="ani-txt bg-pink">
              <p>10 ordini ricevuti su Just Eat</p>
              <p className="big-p">=</p>
              <p className="big-p">10 </p>
              <p>x Commissioni su ogni ordine</p>
            </div>
          </div>
          <div className="animation-color-div-flex">
            
            <div className="ani-txt bg-green">
              <p>10 Clienti presi con il tuo sito</p>
              <p className="big-p">=</p>
              <p className="big-p">0%</p>
              <p>Commissioni. Sempre.</p>
            </div>
            <div className="ani-img">
              <img src={ani2} />
            </div>
          </div>
        </div>


  <div id='wibx' className='flex flex-col mb-8 '>


<div className='w-[80%] px-[40px] mx-auto xsm:w-full sm:w-full  md:w-full  xmd:w-full items-center flex flex-row sm:flex-col xsm:flex-col p-10   '>
  <div className='w-1/3 xsm:w-full sm:w-full  md:w-[30%]   xsm:mx-auto sm:mx-auto  md:mx-auto p-5 '>
    <div className=' w-[95%]  mx-auto flex items-center justify-center '>
    <img src={ques}/>
    </div>
  </div>

  <div className='w-2/3 flex xsm:w-full sm:w-full  md:w-full  xmd:w-full '>
    <div className='flex flex-col  w-full  px-10 xsm:px-3 sm:px-3 mx-auto '>

      <div className='flex flex-col gap-x-4 w-full my-4 '>
        <p className='pop300 text-xl text-[#505050] w-full xsm:text-center sm:text-center '>
        Quando tu fai bene il tuo lavoro, i tuoi clienti sono entusiasti di riordinare dal tuo ristorante! Just Eat si prende il merito del tuo lavoro e ti applica una commissione anche sui riordini. Con Restaurants Club incentiva i riordini sul tuo sito con le Mail Automatiche e il Modulo offerte.
        </p>
      </div>
    </div>
  </div>
</div>
</div>




<div className="blue-grad">
          <div className=' w-[85%] px-[40px]  xsm:w-full sm:w-full    flex flex-col justify-center mx-auto py-10 '>

            <div className='w-full gap-4 flex flex-row xsm:flex-col sm:flex-col  '>

              <div className='w-1/3 xsm:w-full sm:w-full '>
                <p className='text-4xl  text-[#fff] Brevia850 line-height-4'>
                  Riprenditi i tuoi clienti.
                  Inizia ora.
                </p>
              </div>

              <div className='w-2/3 flex flex-col gap-6 xsm:w-full sm:w-full  md:w-full p-3 md:p-5 md:py-3 sm:p-0 mx-auto '>
                <BiddingForm />
              </div>
            </div>

            <div className='  w-full py-3 '>
              <div className=' flex flex-row xsm:flex-col sm:flex-col  md:flex-col gap-5 w-full  m-3' >
              <PopupButton
                className='pop400 text-base text-[#fff] text-underline'
                url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club"
                rootElement={document.getElementById("root")}
                text="Oppure chiamaci per avere maggiori informazioni"

              />
                
                <WhatsAppIcon color="white" size={20} className="text-white" />{" "}
            <a href="https://wa.me/+393312910408" className="text-white">
              331 291 0408
            </a>
              </div>
            </div>
          </div>
        </div>


<div className=' w-[80%] px-[40px] xsm:w-full sm:w-full  md:w-full  xmd:w-full  mx-auto flex flex-col   h-fit relative pt-9 py-5 mt-5 '>
          <div className='w-[70%] xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto pb-6 '>
            <p className='Brevia850 text-5xl xsm:text-4xl sm:text-4xl md:text-4xl xmd:text-4xl text-[#114653] text-center font-black '>
            Fai parlare del tuo ristorante. Non di Just Eat.
            </p>
          </div>

          <div className='flex flex-row sm:flex-col my-2'>
            <div className=' '>
              <div className='flex flex-col items-center w-[80%] xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto  pb-6 '>
                <p className='pop400 text-lg text-center text-black font-medium '>
                I Marketplace come Just Eat lavorano facendo pubblicità al Marketplace stesso, non al tuo ristorante. Il tuo ristorante è uno dei tanti, un mezzo per ottenere più clienti e quindi più commissioni dal tuo lavoro.
                </p>
              </div>
            </div>
          </div>
</div>




        <div className='w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] xmd:w-[95%]  mx-auto flex flex-col '>
       
          
              <div className='w-full flex flex-row xsm:flex-col sm:flex-col  md:flex-col     '>
                <div className='w-1/2 xsm:mx-auto sm:mx-auto md:mx-auto sm:pt-16   md:pt-16  xmd:pt-16  xsm:w-full sm:w-full  md:w-full'>
                  <div className=' w-full flex items-center justify-center flex-column '>
                  <div className="same-height">
                  <img
              src={post1}
            />
                    </div>
             <div className="animation-color-div-flex">
            <div className="ani-img">
              <img src={ani1} />
            </div>
            <div className="ani-txt bg-pink">
              <p>La pubblicità di Just <br></br>Eat sui social</p>
            </div>
          </div>
                  </div>
                </div>
                <div className='w-1/2 xsm:mx-auto sm:mx-auto md:mx-auto sm:pt-16   md:pt-16  xmd:pt-16  xsm:w-full sm:w-full  md:w-full '>
                  <div className=' w-full flex items-center justify-center flex-column '>
                  <div className="same-height"><img
              src={post2}
            /></div>
            <div className="animation-color-div-flex">
            <div className="ani-img">
              <img src={ani2} />
            </div>
            <div className="ani-txt bg-green">
              <p>La pubblicità de “Il Canguro”  con Restaurants Club</p>
            </div>
            
          </div>
                  </div>
                </div>
              </div>
            </div>
        
     









            <div className=' w-[80%] px-[40px] xsm:w-full sm:w-full  md:w-full  xmd:w-full   mx-auto flex flex-col   h-fit relative pt-9 pb-5 '>
          <div className='w-full xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto pb-6 '>
            <p className='Brevia850 text-5xl xsm:text-4xl sm:text-4xl md:text-4xl xmd:text-4xl text-[#114653] text-center font-black '>
              Perché Restaurants Club
            </p>
          </div>

          <div className='flex flex-row sm:flex-col my-1 justify-center'>
            <div className=' '>
              <div className='flex flex-col items-center w-8/10 xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto'>
                <p className='pop500 text-xl text-center text-black font-medium leading-9 '>
                  <i>Cosa puoi fare con gli strumenti di Restaurants Club</i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='py-5 ' >
          <div className='w-[80%] px-[40px] sm:px-2 sm:w-full sm:flex-col gap-y-6 md:w-full md:px-6 md:flex-col    xsm:flex-col  mx-auto flex flex-row gap-x-9'>

            <div className='w-1/3 md:w-full sm:w-full xmd:w-full xsm:w-full  mx-auto flex flex-col gap-y-5 items-center'>

              <div className=' w-full flex items-center justify-center  p-6'>
                <img src={Frame6} className='xsm:w-1/2 sm:w-1/2  md:w-1/2 w-full h-full' alt="" />
              </div>
              <p className='Brevia700 not-italic font-bold  text-5xl sm:text-3xl text-center text-[#114653] '>Attrai</p>
              <p className='pop400    not-italic font-normal text-xl sm:text-lg text-center text-black  mx-auto'>Cattura l’attenzione su internet e fai conoscere la tua attività</p>

            </div>

            <div className=' w-1/3 md:w-full sm:w-full xmd:w-full xsm:w-full  mx-auto flex flex-col gap-y-5 '>
              <div className=' w-full  flex items-center justify-center  p-6'>
                <img src={Frame5} className='xsm:w-1/2 sm:w-1/2  md:w-1/2 w-full h-full' alt="" />
              </div>
              <p className='Brevia700 not-italic font-bold  text-5xl sm:text-3xl  text-center text-[#114653]   '>Converti </p>
              <p className='pop400 not-italic font-normal  text-xl sm:text-lg text-center text-black  mx-auto'>Porta nuovi clienti al tuo ristorante e aumenta il tuo fatturato</p>

            </div>

            <div className=' w-1/3 md:w-full sm:w-full xmd:w-full xsm:w-full mx-auto flex flex-col gap-y-5 '>
              <div className='w-full flex items-center justify-center  p-6'>
                <img src={Frame4} className='xsm:w-1/2 sm:w-1/2  md:w-1/2 w-full h-full' alt="" />
              </div>
              <p className='Brevia700  not-italic font-bold  text-5xl sm:text-3xl  text-center text-[#114653]   '>Fidelizza </p>
              <p className='pop400     not-italic font-normal text-xl sm:text-lg text-center text-black mx-auto'>Crea una base clienti fedele e affezionata con cui crescere</p>

            </div>

          </div>
          <div className='my-10 mx-auto w-[80%]  '>
            <div className='mx-auto my-5 w-72 h-12 border-[3px] bg-[#DCFFCF] border-black items-center flex justify-center pop600 text-lg text-black'>

              <PopupButton
                url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club"
                rootElement={document.getElementById("root")}
                text="Vedilo in azione"
              />

            </div>

            <div className='mx-auto flex text-center'>

              <PopupButton
                className='text-center pop600 text-lg mx-auto text-black sm:text-sm xmd:text-sm underline'
                url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club"
                rootElement={document.getElementById("root")}
                text="Oppure chiamaci per avere maggiori informazioni"

              />

            </div>
          </div>
        </div>


        <div className='w-[80%] px-[40px]  sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] xsm:w-full sm:w-full  md:w-full  xmd:w-full  mx-auto flex flex-col   h-fit relative pt-9 pb-5 '>
          <div className='w-full mx-auto pb-6 '>
            <p className='Brevia850 text-5xl xsm:text-4xl sm:text-4xl md:text-4xl xmd:text-4xl text-[#00B27A] text-center font-black '>La storia del ristorante “Il Canguro”</p>
          </div>
        </div>


        <div className='w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] flex flex-col mb-10  mx-auto  '>


          <div className='w-full flex flex-row xsm:flex-col sm:flex-col  md:flex-col  gap-x-14   '>


            <div className='w-2/3 xsm:w-full sm:w-full  md:w-full  xmd:w-full  content-center grid pb-5'>
              <div className=''>
                <div className='flex flex-col gap-x-4 w-full  '>
                  <p className='text-center pop400  text-lg text-black '>
                    "Ora è molto più semplice prenotare per i clienti. Le persone riconoscono il mio locale, vedono l'insegna e chiedono subito un tavolo."
                  </p>
                  <span className="mt-5 mb-3 pop400  text-lg text-black w-full text-right">-Daniele, proprietario de “Il Canguro”</span>
                  <div className="w-full flex flex-row xsm:flex-col sm:flex-col  md:flex-col justify-center ">
                    <div className='xsm:w-full sm:w-full  md:w-full  xmd:w-full  px-[10px] mx-auto flex flex-col   h-fit relative pt-9 pb-5'>
                      <div className='w-full xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto  '>
                        <p className='mb-3 Brevia850 text-4xl xsm:text-4xl sm:text-4xl md:text-4xl xmd:text-4xl text-[#00B27A] text-center font-black '>
                          + 51 mila
                        </p>
                        <p className='text-center pop400  text-lg text-black w-full xsm:text-center sm:text-center md:text-center'>
                          utenti raggiunti tramite social media </p>
                      </div>
                    </div>
                    <div className='xsm:w-full sm:w-full  md:w-full  xmd:w-full  px-[10px] mx-auto flex flex-col   h-fit relative pt-9 pb-5'>
                      <div className='w-full xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto '>
                        <p className='mb-3 Brevia850 text-4xl xsm:text-4xl sm:text-4xl md:text-4xl xmd:text-4xl text-[#00B27A] text-center font-black '>
                          + 48,8%
                        </p>
                        <p className='text-center pop400  text-lg text-black w-full xsm:text-center sm:text-center md:text-center'>
                          richieste di indicazioni stradali su Google </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=' md:w-1/2 md:mx-auto'>
              <div className=' w-full flex items-center justify-center after-img'>
                <img src={chef} />
              </div>
            </div>
          </div>
        </div>
       
      </div>
      <BottomForm title={`Hai ancora dubbi?  Chiedi un parere a un nostro esperto!`} />
   


            </div>











  );
}

export default Home;
