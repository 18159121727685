import ArrowIcon from "./ArrowIcon";

const ArrowDownButton = ({ onClick }) => {
  return (
    <button onClick={onClick}>
      <ArrowIcon />
    </button>
  );
};
export default ArrowDownButton;
