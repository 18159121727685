import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { logger } from '../../Services/Logger';

function HubspotContactForm() {
  
    return (

      <HubspotForm

        portalId='26462757'
        formId='85158c63-3313-4c08-bbc7-3f2a513a934d'
        onSubmit={() => logger.info('Contact form submit!')}
        onReady={(form) => logger.debug('Contact form ready!')}
        loading={<div>Loading...</div>}

      />
        
    )
}

export default HubspotContactForm