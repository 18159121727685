import cn from "classnames";
import img from "../img/image26.png";
import styles from "./SloganBanner.module.css";

const SloganBanner = ({ t }) => {
  return (
    <div className={cn(styles.container,)}>
      <div className="flex flex-row xsm:flex-col-reverse sm:flex-col-reverse  md:flex-col-reverse ">
        <div className={cn(styles.text, "w-[75%]")}>
          <h3 className={cn(styles.title, "Brevia700 ")}>
            {t("functionPage.bunner.title")}
          </h3>
        </div>
        <div className="justify-end flex w-[45%] xsm:w-full sm:w-full md:w-full ">
          <img src={img} alt="img" />
        </div>
      </div>
    </div>
  );
};
export default SloganBanner;
