import Facebook from "../../Assets/facebook.svg";
import Instagram from "../../Assets/instagram.svg";
import LinkedIn from "../../Assets/linkedin.svg";
import YouTube from "../../Assets/youtube.svg";
import styles from "./Footer.module.css";
import { useTranslation } from "react-i18next";
import { FaPhoneAlt } from "react-icons/fa";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

export const useFooter = (landingFooter) => {
  const { t } = useTranslation();
  const mobileSocialLinks = [
    {
      link: "https://www.facebook.com/restaurantsclubitalia",
      icon: <img src={Facebook} alt="Facebook" />,
    },
    {
      link: "https://www.instagram.com/restaurantsclubitalia/",
      icon: <img src={Instagram} alt="Instagram" />,
    },
    {
      link: "https://www.youtube.com/channel/UC3wwBpKvOFF2-CGTjZzfC1A",
      icon: <img src={YouTube} alt="YouTube" />,
    },
    {
      link: "https://www.linkedin.com/products/aigot-srl-restaurants-club/",
      icon: <img src={LinkedIn} alt="LinkedIn" />,
    },
  ];
  const landingBlocks = [
    {
      title: t("footer.contact.title"),
      links: [
        {
          url: "/tel:+393334857104",
          title: "050 098 5159",
          external: true,
          icon: <FaPhoneAlt color="white" size={20} />,
          className:
            "pop700 text-2xl  xmd:text-xl md:text-[18px] flex flex-row items-center gap-x-2 text-white",
        },
        {
          url: "https://wa.me/+393312910408",
          title: "331 291 0408",
          external: true,
          icon: <WhatsAppIcon color="white" size={20} />,
          className:
            "pop700 text-2xl  xmd:text-xl md:text-[18px] flex flex-row items-center gap-x-2 text-white",
        },
        { title: t("footer.contact.links.1"), external: true },
        { title: t("footer.contact.links.2"), external: true },
        { title: t("footer.contact.links.3"), external: true },
      ],
      className: styles.landing_block,
    },
  ];
  const normalFooterBlocks = [
    {
      title: t("footer.product.title"),
      links: [
        { url: "/funzioni", title: t("footer.product.links.1") },
        { url: "https://blog.restaurants.club", title: t("footer.product.links.blog") },
        { url: "/prezzi", title: t("footer.product.links.2") },
        { url: "https://blog.restaurants.club/faq", title: t("footer.product.links.3") },
        {
          url: "/tel:+393334857104",
          title: t("footer.product.links.4"),
          external: true,
        },
      ],
      className: styles.block,
      calendlyItems: [t("footer.product.links.5"), t("footer.product.links.6")],
    },
    {
      title: t("footer.information.title"),
      links: [
        {
          url: require("../../Assets/Documents/contrattoinbreve.pdf"),
          title: t("footer.information.links.1"),
          external: true,
        },
        {
          url: require("../../Assets/Documents/CodiceEtico.pdf"),
          title: t("footer.information.links.2"),
          external: true,
        },
        { url: "/cookiepolicy", title: t("footer.information.links.3") },
        { url: "/privacypolicy", title: t("footer.information.links.4") },
        { url: "/termsandconditions", title: t("footer.information.links.5") },
      ],
      className: styles.block,
    },
  ];
  let blocks;

  if (landingFooter) {
    blocks = [...landingBlocks];
  } else {
    blocks = [...normalFooterBlocks];
  }
  blocks.push({
    title: t("footer.follow"),
    links: [
      {
        url: "https://www.facebook.com/restaurantsclubitalia",
        title: "Facebook",
        icon: <img src={Facebook} alt="Facebook" />,
        external: true,
      },
      {
        url: "https://www.instagram.com/restaurantsclubitalia/",
        title: "Instagram",
        icon: <img src={Instagram} alt="Instagram" />,
        external: true,
      },
      {
        url: "https://www.youtube.com/channel/UC3wwBpKvOFF2-CGTjZzfC1A",
        title: "YouTube",
        icon: <img src={YouTube} alt="YouTube" />,
        external: true,
      },
      {
        url: "https://www.linkedin.com/products/aigot-srl-restaurants-club/",
        title: "LinkedIn",
        icon: <img src={LinkedIn} alt="LinkedIn" />,
        external: true,
      },
    ],
    className: styles.desktop_socials,
  });
  return {
    mobileSocialLinks,
    blocks,
    t,
  };
};
