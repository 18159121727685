import React from "react";
import '../../App.css';
import ScrollToTop from '../../Reusable/ScrollToTop';

function PrivacyContratto() {

    return (
        <>
            <>

                <ScrollToTop />

                <div className="flex flex-col w-[80%] text-left justify-center items-center mx-auto">

                    <h1 className="my-8 Brevia700 text-5xl text-[#00B27A]">Informativa sul trattamento dei Dati Personali</h1>

                    <p>


                        Lo scopo del presente documento è di informare la persona fisica (di seguito "<span className="font-bold">Interessato</span>" ) relativamente al
                        trattamento dei suoi dati personali (di seguito “<span className="font-bold">Dati Personali</span>”) raccolti da <span className="font-bold">AiGot S.r.l.</span>
                        , con sede legale in Via Comelico 18, 20135 Milano (MI) e sede operativa in Pisa (PI) via Santa Maria 25, cap 56126, CF/P.IVA 11498080966, indirizzo e-mail privacy@aigot.com, indirizzo
                        PEC aigot@pec.com, telefono 0500985159 (di seguito “<span className="font-bold">AiGot</span>” o “<span className="font-bold">Titolare</span>”).<br></br>
                        Il  Titolare potrà modificare o semplicemente aggiornare, in  tutto o in  parte, la  presente informativa  dandone comunicazione agli Interessati ove necessario.



                    </p>

                    <br />



                    <br />

                    <div className="flex flex-col" >

                        <ol className="list-decimal">

                            <div className="flex flex-row ml-4 mb-2">

                                <p className="mr-2 font-bold">1. Categorie di Dati Personali trattati</p>

                            </div>

                            <p className="ml-10 mb-2">Il Titolare tratta le seguenti tipologie di Dati Personali forniti volontariamente dall’Interessato:</p>

                            <ul className="flex flex-col list-disc gap-y-4" id="cookie">

                                <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p><span className="font-bold">Dati di contatto:</span> nome, cognome, indirizzo, e-mail, telefono, immagini, credenziali di autenticazione, eventuali ulteriori informazioni comunicate dall'Interessato
                                </p></li>

                                <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p><span className="font-bold">Dati fiscali e di pagamento:</span> codice fiscale, partita IVA, dati della carta di credito, estremi del conto corrente bancario e ulteriori informazioni comunicate dall’Interessato</p></li>


                                <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p><span className="font-bold">Dati tecnici:</span>indirizzo IP, indicazione tipo di browser, indicazione provider internet
                                </p></li>

                                <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p><span className="font-bold">Dati sensibili:</span> origine razziale od etnica, convinzioni religiose, filosofiche. Tali dati verranno richiesti soltanto se strettamente necessario al fine di adempiere alle obbligazioni derivanti dal Contratto, previo consenso dell’Interessato, o per obbligo di Legge o nei casi previsti dall’art. 9 del GDPR.
                                </p></li>



                            </ul>



                            <p className="ml-10 my-2">Il mancato conferimento da parte dell’Interessato dei Dati Personali per i quali sussiste l’obbligo legale o contrattuale  o  che  costituiscano  requisito  necessario  per  la  conclusione  del  contratto  da sottoscrivere con  il Titolare, in particolare quelli richiesti nel Modulo d’ordine di cui al Contratto sottoscritto dalle Parti,   comporterà   l’impossibilità   per il   Titolare   di   instaurare   o   proseguire   -in tutto o in parte- il   rapporto   con l’ Interessato.
                                L’Interessato che  comunichi  al  Titolare  Dati  Personali  di  terzi  è  direttamente  ed  esclusivamente responsabile della provenienza, raccolta, trattamento, comunicazione e diffusione di tali dati.
                            </p>

                        </ol>

                    </div>



                    <br />

                    <div className="flex flex-col">

                        <ol className="list-decimal">

                            <div className="flex flex-col ml-4 mb-2">

                                <p className="mr-2 font-bold">2. Base giuridica e finalità del trattamento</p>
                                <p className="ml-10 mb-2">Il trattamento dei Dati Personali di cui all’art. 1 è effettuato dal Titolare:</p>

                            </div>

                            <ol className="flex flex-col list-decimal gap-y-1">

                                <li className="flex flex-row ml-20 mt-4"><p className="mr-4 font-bold">a)</p><p><span className="font-bold">per l'esecuzione del contratto </span>sottoscritto tra l’Interessato ed il Titolare, in particolare:</p></li>

                                <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p><strong>per l’adempimento di ogni obbligo derivante dal rapporto precontrattuale o contrattuale</strong> in essere tra Titolare e Interessato;</p></li>

                                <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p><strong>per contattare l’Interessato</strong>, anche al fine di dare assistenza e supporto e per rispondere alle richieste di questo;</p></li>

                                <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> <strong>per la gestione dei pagamenti</strong> contrattualmente previsti e dei relativi adempimenti; </p></li>

                                <li className="flex flex-row ml-20"><p className="mr-4">-</p><p><strong>per la gestione,  ottimizzazione  e  monitoraggio  dell’infrastruttura  tecnica del Software e dei relativi servizi:</strong>  per  identificare  e risolvere  eventuali  problemi  tecnici,  per gestire e organizzare le informazioni del sistema informatico (ad es. server, database etc.), per  migliorare  le  performance  del Software e i servizi ad esso correlati nell’interesse del Ristoratore;</p></li>

                                <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> <strong>per finalità di marketing nell’interesse del Ristoratore</strong> , in particolare, per elaborare Campagne Marketing volte a sponsorizzare e promuovere i prodotti e i Servizi dell’Interessato presso Utenti terzi, in adempimento di quanto previsto dal Contratto in essere tra le Parti. </p></li>

                            </ol>

                            <ol className="flex flex-col list-decimal gap-y-1">

                                <li className="flex flex-row ml-20 mt-4"><p className="mr-4 font-bold">b)</p><p><span className="font-bold">ai fini dell’adempimento di qualunque obbligo previsto dalle normative vigenti</span>, in particolare, in materia tributaria e fiscale.</p></li>
                                <li className="flex flex-row ml-20"><p className="mr-4 font-bold">c)</p><p><span className="font-bold">per legittimo interesse del Titolare</span> nei seguenti casi:</p></li>

                                <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> <strong>a fini di sicurezza e antifrode</strong>: per garantire la sicurezza del patrimonio, delle infrastrutture e delle reti del Titolare;</p></li>

                                <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> <strong>per la gestione,  ottimizzazione  e  monitoraggio  dell’infrastruttura  tecnica del Software e dei relativi servizi:</strong>  per  identificare  e risolvere  eventuali  problemi  tecnici,  per  migliorare  le  performance  del Software e i servizi ad esso correlati,  per gestire e organizzare le informazioni del sistema informatico (ad es. server, database etc.); </p></li>

                                <li className="flex flex-row ml-20"><p className="mr-4">-</p><p><strong>ai fini di statistica con dati anonimi</strong>: per  effettuare analisi statistiche su dati aggregati e anonimi per analizzare i comportamenti dell’Interessato al fine di migliorare i  prodotti e/o i  servizi  forniti dal Titolare e meglio soddisfare le aspettative dell’Interessato;</p></li>

                                <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> <strong>per eventuali collaborazioni professionali esterne</strong>: nel limite di quanto necessario ai fini dell’adempimento degli obblighi contrattuali e/o di legge.</p></li>
                            </ol>

                            <ol className="flex flex-col list-decimal gap-y-1">

                                <li className="flex flex-row ml-20 mt-4"><p className="mr-4 font-bold">d)</p><p><span className="font-bold">sulla base di apposito consenso esplicito dell’Interessato</span>, in particolare:</p></li>

                                <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> <strong>per la profilazione dell’Interessato</strong>: per la raccolta automatizzata delle  informazioni  personali  dell’Interessato volta ad analizzare,  prevedere  e  valutare le  sue  preferenze  o comportamenti;</p></li>

                                <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> <strong>per finalità  di  marketing  di  prodotti  e/o  servizi  del  Titolare</strong>:  per  inviare ai contatti dell'Interessato,  informazioni  o materiali commerciali e/o promozionali, per effettuare attività di promozione e vendita di prodotti e/o servizi  del  Titolare  o  per  compiere  ricerche  di  mercato  con  modalità  automatizzate  e tradizionali; </p></li>

                            </ol>

                            <ol className="flex flex-col list-decimal gap-y-1">

                                <li className="flex flex-row ml-20 mt-4"><p className="mr-4 font-bold">e)</p><p>I  Dati  Personali  dell’Interessato  possono  inoltre  essere  utilizzati  dal  Titolare  <strong>in sede giudiziale</strong>, innanzi ad ogni Autorità Giudiziaria e in qualsiasi grado di giudizio, nonché in sede stragiudiziale per la tutela dei propri diritti e interessi.</p></li>

                            </ol>

                        </ol>

                    </div>

                    <br />

                    <div className="flex flex-col">

                        <ol className="list-decimal">

                            <div className="flex flex-row ml-4 mb-2">

                                <p className="mr-2 font-bold">3. Modalità di trattamento dei Dati Personali e soggetti autorizzati</p>

                            </div>

                            <p className="ml-10 mb-2"> Il trattamento dei Dati Personali viene effettuato mediante strumenti cartacei e/o informatizzati con modalità
                                organizzative e con logiche strettamente correlate alle finalità indicate dalla presente Informativa e
                                mediante l'adozione di adeguate misure di sicurezza.
                                Tutte le operazioni di trattamento dei dati sono attuate in modo da garantire l’integrità, la riservatezza e la
                                disponibilità dei dati personali.<br></br>
                                Tutte le operazioni di trattamento dei dati sono attuate in modo da garantire l’integrità, la riservatezza e la disponibilità dei dati personali.
                            </p>

                            <p className="ml-10 mb-4"> I Dati Personali sono trattati esclusivamente da:</p>

                        </ol>

                        <ul className="flex flex-col list-disc gap-y-4">

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p>persone  autorizzate  dal  Titolare  del  trattamento  dei  Dati  Personali  che  si  sono  impegnate  alla riservatezza o che hanno un adeguato obbligo legale di riservatezza;</p></li>

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p>

                                <p>

                                    soggetti che operano in autonomia come distinti titolari del trattamento o da soggetti designati quali responsabili del trattamento dal Titolare al fine di svolgere tutte le attività di trattamento necessarie a perseguire le finalità  di  cui  alla  presente Informativa  (ad  esempio  partner  commerciali,  consulenti, società informatiche, fornitori di servizi, hosting provider);


                                </p>

                            </li>


                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p>

                                <p>
                                    soggetti o enti a cui è necessario comunicare i Dati Personali ai sensi dell’art. 4 o per obbligo di legge o per ordine dell’Autorità.


                                </p>

                            </li>
                            <p className="ml-10 my-2">I soggetti sopra elencati sono tenuti a utilizzare le dovute cautele previste dalla legge nonché tutti i comportamenti che si rendano necessari nel caso concreto al fine di proteggere i Dati Personali.
                                Tali soggetti possono accedere solo a quei dati necessari per eseguire i compiti a loro assegnati.
                            </p>

                            <p className="ml-10 my-2">I Dati Personali non verranno diffusi indiscriminatamente in alcun modo.
                            </p>

                        </ul>

                    </div>

                    <br />

                    <div className="flex flex-col">

                        <ol className="list-decimal">

                            <div className="flex flex-row ml-4 mb-2">

                                <p className="mr-2 font-bold">4.Ambito di comunicazione</p>

                            </div>

                            <p className="ml-10 mb-2">
                                In relazione alle finalità indicate all’art. 2 i dati potranno essere comunicati ai seguenti soggetti (a mero titolo esemplificativo e non esaustivo): società di  istituti bancari per la gestione di incassi e pagamenti; amministrazioni finanziarie o istituti pubblici in adempimento di obblighi normativi;  società e studi legali per la tutela dei diritti;  agenti, rappresentanti;  committente e/o appaltatore nell’ambito del contratto di appalto/subappalto (anche per assolvere agli oneri derivanti dalla responsabilità solidale ex art. 29 del D.Lgs. n. 276/2003).

                            </p>

                        </ol>


                    </div>

                    <br />

                    <div className="w-full">

                        <ol className="list-decimal">

                            <div className="flex flex-row ml-4 mb-2">

                                <p className="mr-2 font-bold">5.Ambito di comunicazione</p>

                            </div>

                            <p className="ml-10 mb-2">
                                I  Dati  Personali  vengono trattati all’interno del territorio dello  Spazio Economico Europeo (SEE).

                            </p>

                        </ol>


                    </div>

                    <br />

                    <div className="flex flex-col">

                        <ol className="list-decimal">

                            <div className="flex flex-row ml-4 mb-2">

                                <p className="mr-2 font-bold">6. Periodo di conservazione dei Dati Personali</p>

                            </div>

                            <p className="ml-10 mb-2"> I  Dati  Personali  saranno conservati per  il periodo di  tempo necessario per il raggiungimento delle finalità  per  i quali sono stati raccolti, in particolare: </p>

                        </ol>

                        <ul className="flex flex-col list-disc gap-y-4">

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p>

                                <p>

                                    i Dati Personali raccolti per finalità relative all’esecuzione del contratto tra il Titolare e l’Interessato, saranno conservati per tutta la durata del rapporto contrattuale e, dopo la cessazione, per il periodo di prescrizione ordinario pari a 10 anni;

                                </p>

                            </li>

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> i Dati Personali raccolti per l’adempimento di un obbligo di legge o per ordine di un’Autorità, saranno conservati  nel  rispetto  delle  tempistiche  previste  da  detti  obblighi e dalle normative  applicabili e  comunque  sino  al compimento del termine prescrizionale previsto dalle norme vigenti;
                            </p></li>

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> i Dati Personali raccolti per legittimo interesse del Titolare saranno conservati fino al compimento di tale interesse;
                                i Dati Personali raccolti sulla base di apposito consenso esplicito del Ristoratore, saranno conservati sino alla revoca di tale consenso.

                            </p></li>

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> nel caso di contenzioso giudiziale, i Dati Personali raccolti saranno conservati per tutta la durata dello stesso, fino all’esaurimento in tutti i gradi di giudizio.
                            </p></li>

                            <p className="ml-10 my-2">Al termine del periodo di conservazione, tutti i Dati Personali saranno cancellati o conservati in una
                                forma che non consenta l’identificazione dell’Interessato.</p>

                        </ul>

                    </div>

                    <br />

                    <div className="flex flex-col">

                        <ol className="list-decimal">

                            <div className="flex flex-row mb-2">

                                <p className="mr-2 font-bold">7. Diritti dell’Interessato</p>

                            </div>

                            <p className="ml-10 mb-2"> In relazione ai dati oggetto del trattamento di cui alla presente informativa, all’Interessato è riconosciuto in qualsiasi momento il diritto di:
                            </p>

                        </ol>

                        <ul className="flex flex-col list-disc">

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> essere informato sul trattamento dei propri Dati Personali</p></li>

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p>accedere ai propri Dati Personali (art 15 GDPR)</p></li>

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> chiedere la rettifica dei propri Dati Personali (art. 16 GDPR)</p></li>

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p>ottenere la cancellazione dei propri Dati Personali («diritto all’oblio» - art. 17 GDPR), salvo che non
                                sussista fondamento giuridico per il trattamento</p></li>

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> limitare il trattamento dei propri Dati Personali (art. 18 GDPR)</p></li>

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> di trasferire o chiedere al Titolare la trasmissione dei propri Dati Personali ad altro titolare (art 20 GDPR)</p></li>

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> opporsi in qualsiasi momento al trattamento dei propri Dati Personali (art. 21 GDPR) salvo che non
                                sussista fondamento giuridico per il trattamento</p></li>

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> opporsi al trattamento automatizzato dei propri Dati Personali (art. 22 GDPR)
                            </p></li>

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p>revocare in qualsiasi momento il consenso prestato per finalità di marketing e profilazione
                            </p></li>

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> proporre reclamo all’Autorità Garante per la Protezione dei dati personali e/o agire in sede giudiziale.</p></li>

                            <p className="ml-10 my-2">Per esercitare i propri diritti, gli Interessati possono indirizzare una richiesta al seguente indirizzo e-mail privacy@aigot.com. </p>

                        </ul>

                    </div>

                    <br />


                    <p className="flex self-start mb-8"> Ultimo aggiornamento: 05/05/2023 </p>

                </div>

            </>
        </>
    );
};

export default PrivacyContratto;