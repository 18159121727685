import React from "react";
import cn from "classnames";
import megaphone from "../img/megaphone.png";
import PhoneChat from "../img/phoneChat.png";
import phoneFbIg from "../img/phoneFbIg.png";
import styles from "./GoalsSection.module.css";
import { useNavigate } from 'react-router-dom';

const GoalsSection = ({ t }) => {
  const navigate = useNavigate();
  const iconsWithText = [
    {
      img: megaphone,
      text: t("functionPage.goalsSection.items.title1"),
      des: t("functionPage.goalsSection.items.des1"),
    },
    {
      img: phoneFbIg,
      text: t("functionPage.goalsSection.items.title2"),
      des: t("functionPage.goalsSection.items.des2"),
    },
    {
      img: PhoneChat,
      text: t("functionPage.goalsSection.items.title3"),
      des: t("functionPage.goalsSection.items.des3"),
    },
  ];
  return (
    <>
      <div
        id="one_platform_package_section"
        className="2xl:w-[80%] m-auto p-12"
      >
        <div className="w-full text-center mx-auto">
          <h3 className={styles.title}>
            {t("functionPage.goalsSection.title")}
          </h3>

        </div>

        <div className="m-auto flex flex-row xsm:flex-col sm:flex-col md:flex-col gap-5 my-12">
          {iconsWithText.map((item, index) => (
            <div className={styles.img_item} key={index}>
              <div className={styles.img_container}>
                <img
                  src={item.img}
                  alt="img"
                  className={cn(styles.img)}
                />
              </div>
              <div className={styles.text_container}>
                <h6 className={styles.img_sub_title}>{item.text}</h6>
                <p className={styles.img_sub_text}>{item.des}</p>
              </div>
            </div>
          ))}
        </div>

        <div className="flex justify-center ">
          <button
            onClick={() => navigate('/contatti')}
            className={styles.button}
          >
            {t("functionPage.goalsSection.button")}
          </button>
        </div>
      </div>

    </>

  );
};

export default GoalsSection;
