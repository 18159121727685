import React from "react";
import { useNavigate } from "react-router-dom";
import QuizImage from "../../Assets/quiz.svg";

import ScrollToTop from "../../Reusable/ScrollToTop";

import ScrollToButton from "../../Reusable/ScrollToButton";
import FirstButton from "../../Reusable/LandingButton/FirstButton";

function Preventive() {
  const navigate = useNavigate();

  return (
    <div className=" w-full flex flex-col gap-y-8">
      <ScrollToTop />

      <div className=" flex flex-col ">
        <div className="w-[80%] mx-auto xsm:w-full sm:w-full   flex flex-row sm:flex-col xsm:flex-col  p-3 m-auto gap-3 gap-y-10 my-10" >


          <div className="w-2/3 sm:w-full xsm:w-full md:w-full xmd:w-full text-left flex flex-col gap-8">
            <h1 className="Brevia850 sm:text-center xsm:text-center text-[#114653]">
              Crea un preventivo personalizzato{" "} <span className='xsm:hidden sm:hidden md:hidden'><br className='xmd:hidden'></br>in base ai tuoi obiettivi</span>
            </h1>
            <div className="flex flex-col xsm:flex-col-reverse sm:flex-col-reverse ">
              <p className="pop500 sm:text-center xsm:text-center ">
                Ciao! Rispondi a qualche domanda per scoprire qual è il piano
                perfetto per te, ci vorrà meno di un minuto!{" "}
              </p>
              <p className="pop500 sm:text-center xsm:text-center xsm:hidden sm:hidden md:hidden mt-5 ">
              Andremmo a valutare insieme i tuoi obiettivi, le tue problematiche attuali e gli strumenti digitali che già utilizzi{" "}
              </p>
            </div>

            <div className="flex  w-[71%]  xsm:mx-auto sm:mx-auto  xsm:justify-center sm:justify-center  ">
              
                <FirstButton
                  onClick={() => navigate("/quiz1")}
                  className="   "
                  text="Inizia subito"
                />
              
            </div>
          </div>

          <div className="w-[50%] xsm:w-full sm:w-full flex items-center justify-center   ">
            <img
              src={QuizImage}
              alt="hero"
              className=" "
            />
          </div>
</div>

        </div>
      </div>

  );
}

export default Preventive;
