import React from "react";
import Banner from "../../Components/Banner";
import WebVersion from "./WebVersion";
import { useTranslation } from "react-i18next";
import MobileVersion from "./MobileVersion";
import RestaurantsIcon from "../../Assets/NavLogo5.svg";

const NavBar = ({ location }) => {
  const { t } = useTranslation();
  const shouldRenderNavBar = !location.pathname.includes("iera");

  return shouldRenderNavBar ? (
    <React.Fragment>
      <Banner t={t} />
      <MobileVersion t={t} />
      <WebVersion RestaurantsIcon={RestaurantsIcon} t={t} />
    </React.Fragment>
  ) : (
    <div className="w-full z-20 sticky -top-1 bg-white">
      <div className="flex flex-col px-20 mx-auto   ">
        <img src={RestaurantsIcon} alt="logo" className="py-2 h-[50px]" />
      </div>
    </div>
  );
};
export default NavBar;
