import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { logger } from '../../Services/Logger';

function HubspotSigepForm() {
  
    return (

      <HubspotForm

        portalId='26462757'
        formId='597c8a0e-03a2-406f-bcc6-d06d6b634f4c'
        onSubmit={() => logger.info('Sigep form submit!')}
        onReady={(form) => logger.log('Sigep form ready!')}
        loading={<div>Loading...</div>}

      />
        
    )
}

export default HubspotSigepForm