import React from "react";
import styles from "./TrailButton.module.css";
import cn from "classnames";
const TrialButton = ({ text, borderColor, textColor, className, onClick }) => {
  return (
    <button
      onClick={onClick}
      className={cn(
        className,
        `mx-5 h-11 mb-3 border-2 border-${borderColor} pop600 text-${textColor} w-[90%]`,
        styles.trail_button
      )}
    >
      {text}
    </button>
  );
};

export default TrialButton;
