import React from "react";
import altoparlanteImg from "../img/Frame3.png";
import binocoleImg from "../img/Frame1.png";
import personImg from "../img/Frame2.png";
import styles from "./TreeGoalsBanner.module.css";
import cn from "classnames";

const TreeGoalsBanner = ({ t }) => {
  const iconsWithText = [
    {
      img: binocoleImg,
      text: t("functionPage.goalsBanner.goals.items.1"),
    },
    {
      img: altoparlanteImg,
      text: t("functionPage.goalsBanner.goals.items.2"),
    },
    {
      img: personImg,
      text: t("functionPage.goalsBanner.goals.items.3"),
    },
  ];
  return (
    <>
      <div
        id="one_platform_package_section"
        className="2xl:w-[80%] m-auto p-12"
      >
        <div className="w-full text-center mx-auto ">
          <h3 className={styles.title}>
            {t("functionPage.goalsBanner.title")}
          </h3>

        </div>

        <div className="flex flex-row justify-between w-full flex-wrap ">
          {iconsWithText.map((item, index) => (
            <div className={styles.img_item} key={index}>
              <img
                src={item.img}
                alt="img"
                className={cn(styles.img, {
                  [styles.more_bottom_img]: index === 2,
                })}
              />
              <h6 className={styles.img_sub_text}>{item.text}</h6>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TreeGoalsBanner;
