import { PopupButton } from "react-calendly";

const ProductTourButton = ({ fill, t }) => {
  const { REACT_APP_CALENDLY_ENDPOINT } = process.env;
  return (
    <div className="flex flex-row md:flex md:justify-center  xsm:flex xsm:justify-center  sm:flex sm:justify-center">
      <PopupButton
        url={REACT_APP_CALENDLY_ENDPOINT}
        rootElement={document.getElementById("root")}
        text={t("homePage.attractiveSection.productTour")}
        className={`pop400 text-xl text-${fill} xsm:text-center sm:text-center md:text-center underline`}
      />
      <svg
        className="m-3"
        width="18"
        height="18"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.33333 0L4.39333 0.915905L8.11333 4.54705H0V5.8462H8.11333L4.39333 9.47735L5.33333 10.3933L10.6667 5.19663L5.33333 0Z"
          fill={fill}
        />
      </svg>
    </div>
  );
};
export default ProductTourButton;
