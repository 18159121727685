import { useTranslation } from "react-i18next";
import ImageHero from "../Containers/Functions/ImgeHero";
import PlanSectionInfo from "../Containers/Functions/PlanSectionInfo";
import PlanSectionInfo1 from "../Containers/Functions/PlanSectionInfo1";
import HeroTitle from "../Containers/Functions/HeroTitle";
import GoalsSection from "../Containers/Functions/GoalsSection";
import TreeGoalsBanner from "../Containers/Functions/TreeGoalsBanner";
import PackagesSection from "../Containers/Pricing/PackagesSection";
import ReachGoalsSection from "../Containers/Pricing/ReachGoolsSection";
import SloganBanner from "../Containers/Functions/SloganBanner";
import ScrollToTop from "../Reusable/ScrollToTop";

const Functions= () => {
  const { t } = useTranslation();
  return (
    <div className="w-full py-12">
      <ScrollToTop />
      <HeroTitle t={t}  />
      <ImageHero t={t} />
      <PackagesSection t={t} />
      <ReachGoalsSection t={t} />
      <PlanSectionInfo1 t={t} />
      <PlanSectionInfo t={t} />
      <ReachGoalsSection t={t} />
      <GoalsSection t={t} />
      <SloganBanner t={t} />
      <TreeGoalsBanner t={t} />
    </div>
  );
};

export default Functions;
