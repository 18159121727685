import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import "./cookie-component.css"
import "./ToggleBtn.css"

import CookieImg from "./img/Group 1000002156.png";
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import ClearIcon from '@mui/icons-material/Clear';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { logger } from '../../Services/Logger';

function CookieBanner() {
    const security = true;
    const [cookies, setCookies] = useState({

        'ad': document.cookie.includes('"ad_storage":true'),
        'analytics': document.cookie.includes('"analytics_storage":true'),
        'functional': document.cookie.includes('"functional_storage":true'),
        'personalization': document.cookie.includes('"personalization_storage":true')

    });
    const [showSection, setShowSection] = useState(!document.cookie.includes('RestaurantsClubCookies'));
    const [popUp, setPopUp] = useState(false);
    const [cookieChoiceWindow, setCookieChoiceWindow] = useState(false);
    const [isCookieCreated, setIsCookieCreated] = useState(document.cookie.includes('RestaurantsClubCookies'));

    const cookieNames = {

        'ad': 'ad_storage',
        'analytics': 'analytics_storage',
        'functional': 'functional_storage',
        'personalization': 'personalization_storage'

    };

    useEffect(() => {

        if (document.cookie.includes('RestaurantsClubCookies') === false) {
            localStorage.removeItem('RestaurantsClubCookies');
        }

    })

    const navigate = useNavigate();

    function setCookie(cname, cvalue, exdays) {

        const d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";

    }

    function handleToggle(event, cookie) {

        const { name, checked } = event.target;

        setCookies(prevCookies => ({
            ...prevCookies,
            [name]: checked
        }));

        const cookieValue = checked ? 'true' : 'false';
        setCookie(cookieNames[name], cookieValue, 30);

    }

    function acceptCookies() {

        const cookieValues = Object.values(cookies);
        const cookieNamesList = Object.values(cookieNames);
        const cookieObject = {};

        cookieValues.forEach((value, index) => {
            cookieObject[cookieNamesList[index]] = !value;
        });

        setCookie('RestaurantsClubCookies', JSON.stringify(cookieObject), 180);
        localStorage.setItem('RestaurantsClubCookies', JSON.stringify(cookieObject));
        setCookies({

            'ad': document.cookie.includes('"ad_storage":true'),
            'analytics': document.cookie.includes('"analytics_storage":true'),
            'functional': document.cookie.includes('"functional_storage":true'),
            'personalization': document.cookie.includes('"personalization_storage":true')

        });
        setShowSection(false);
        setIsCookieCreated(true);
        logger.debug("Cookies created", cookies)

    }

    function denyCookies() {

        const cookieValues = Object.values(cookies);
        const cookieNamesList = Object.values(cookieNames);
        const cookieObject = {};

        cookieValues.forEach((value, index) => {
            cookieObject[cookieNamesList[index]] = value;
        });

        setCookie('RestaurantsClubCookies', JSON.stringify(cookieObject), 180);
        localStorage.setItem('RestaurantsClubCookies', JSON.stringify(cookieObject));
        setShowSection(false);
        setIsCookieCreated(true);

    }

    function denyCookies2() {

        const cookieValues = Object.values(cookies);
        const cookieNamesList = Object.values(cookieNames);
        const cookieObject = {};

        cookieValues.forEach((value, index) => {
            cookieObject[cookieNamesList[index]] = value;
        });

        setCookie('RestaurantsClubCookies', JSON.stringify(cookieObject), 180);
        localStorage.setItem('RestaurantsClubCookies', JSON.stringify(cookieObject));

    }

    const goToCookie = () => {

        navigate("/cookiepolicy");

    };

    const cookieValue = (name) => {

        const cookies = document.cookie.split(';');

        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();

            if (cookie.startsWith('RestaurantsClubCookies')) {
                const value = cookie.split('=')[1];
                return value.includes(name)
            }
        }

    };

    return (

        <>
            {popUp ? (

                <div className={`popup xsm:w-[85%] sm:w-[85%] md:w-[85%] xmd:w-[65%] lg:w-[65%] xl:w-[65%] ${showSection ? 'visible' : 'invisible'} border-[3px] border-[#114653]`}>

                    {document.cookie.includes('RestaurantsClubCookies') ? (

                        <>

                            <div className='border-b-[2px] border-b-[#D9D9D9] text-scrollBlue p-5 flex'>

                                <div className='pop600 text-base w-[99%] text-center'>
                                    Personalizzazione cookie
                                </div>

                                <div>
                                    <button onClick={() => { setPopUp((val) => !val); setShowSection((val) => !val); setIsCookieCreated((val) => !val) }} className='text-end w-[1%]'>
                                        <ClearIcon />
                                    </button>
                                </div>

                            </div>

                        </>

                    ) : (

                        <div className='border-b-[2px] border-b-[#D9D9D9] text-center text-scrollBlue p-5'>
                            <span className='pop600 text-base'>
                                Personalizzazione cookie
                            </span>
                        </div>

                    )
                    }

                    <div className='flex flex-col items-center border-t-[0px] border-[#114653]  text-scrollBlue xsm:h-[450px] sm:h-[450px] md:h-[450px] xmd:h-[400px] overflow-auto'>

                        <div className="w-full p-2">

                            <div className="flex justify-between pb-1">

                                <div className='flex items-center'>
                                    <span className="pop600 flex items-center">Necessari</span>
                                </div>

                                <div>

                                    <label
                                        htmlFor="security"
                                        className="flex items-center cursor-pointer toggle relative"
                                    >

                                        <input
                                            checked={security}
                                            name="security"
                                            type="checkbox"
                                            id="security"
                                            className="sr-only"
                                            required
                                            disabled
                                        />
                                        <div className="bg-gray-300 w-14 h-8 rounded-full"></div>
                                        <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>

                                    </label>

                                </div>

                            </div>

                            <div className="text-start border-b-[1px] pt-1">
                                <p>
                                    I cookie necessari contribuiscono a rendere fruibile il sito web
                                    abilitandone funzionalità di base quali la navigazione sulle pagine
                                    e l'accesso alle aree protette del sito. Il sito web non è in grado
                                    di funzionare correttamente senza questi cookie.
                                </p>
                            </div>

                        </div>

                        <div className="w-full px-2 pb-2">

                            <div className="flex justify-between pb-1">

                                <div className='flex items-center'>
                                    <span className="pop600 flex items-center">Marketing</span>
                                </div>

                                <div>

                                    <label htmlFor="ad" className="flex items-center cursor-pointer toggle relative">

                                        <input
                                            checked={cookies.ad}
                                            onChange={handleToggle}
                                            name="ad"
                                            type="checkbox"
                                            id="ad"
                                            className="sr-only"
                                        />
                                        <div className="block bg-gray-400 w-14 h-8 rounded-full"></div>
                                        <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>

                                    </label>

                                </div>

                            </div>

                            <div className="text-start border-b-[1px] pt-1">
                                <p>
                                    I cookie di marketing vengono utilizzati per tracciare i visitatori sui siti web. La finalità è quella di presentare annunci pubblicitari che siano rilevanti e coinvolgenti per il singolo utente e quindi di maggior valore per editori e inserzionisti di terze parti.
                                </p>
                            </div>

                        </div>

                        <div className="w-full px-2 pb-2">

                            <div className="flex justify-between pb-1">

                                <div className='flex items-center'>
                                    <span className="pop600 flex items-center">Statistiche</span>
                                </div>

                                <div>

                                    <label htmlFor="analytics" className="flex items-center cursor-pointer toggle relative">

                                        <input
                                            checked={cookies.analytics}
                                            onChange={handleToggle}
                                            name="analytics"
                                            type="checkbox"
                                            id="analytics"
                                            className="sr-only"
                                        />
                                        <div className="block bg-gray-400 w-14 h-8 rounded-full"></div>
                                        <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>

                                    </label>

                                </div>

                            </div>

                            <div className="text-start border-b-[1px] pt-1">
                                <p>
                                    I cookie statistici aiutano i proprietari del sito web a capire come i visitatori interagiscono con i siti raccogliendo e trasmettendo informazioni in forma anonima.
                                </p>
                            </div>

                        </div>

                        <div className="w-full px-2 pb-2">

                            <div className="flex justify-between pb-1">

                                <div className='flex items-center'>
                                    <span className="pop600 flex items-center">Funzionali</span>
                                </div>

                                <div>

                                    <label htmlFor="functional" className="flex items-center cursor-pointer toggle relative">

                                        <input
                                            checked={cookies.functional}
                                            onChange={handleToggle}
                                            name="functional"
                                            type="checkbox"
                                            id="functional"
                                            className="sr-only"
                                        />
                                        <div className="block bg-gray-400 w-14 h-8 rounded-full"></div>
                                        <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>

                                    </label>

                                </div>

                            </div>

                            <div className="text-start border-b-[1px] pt-1">
                                <p>
                                    I cookie funzionali sono essenziali per il funzionamento del sito web e offrono funzionalità aggiuntive per migliorare l'esperienza dell'utente. Memorizzano le preferenze dell'utente e facilitano l'interazione con il sito.
                                </p>
                            </div>

                        </div>

                        <div className="w-full px-2 pb-2">

                            <div className="flex justify-between pb-1">

                                <div className='flex items-center'>
                                    <span className="pop600 flex items-center">Preferenze</span>
                                </div>

                                <div>

                                    <label htmlFor="personalization" className="flex items-center cursor-pointer toggle relative">

                                        <input
                                            checked={cookies.personalization}
                                            onChange={handleToggle}
                                            name="personalization"
                                            type="checkbox"
                                            id="personalization"
                                            className="sr-only"
                                        />
                                        <div className="block bg-gray-400 w-14 h-8 rounded-full"></div>
                                        <div className="dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition"></div>

                                    </label>

                                </div>

                            </div>

                            <div className="text-start pt-1">
                                <p>
                                    I cookie di preferenza consentono al sito web di memorizzare informazioni che ne influenzano il comportamento o l'aspetto, quali la lingua preferita o la località nella quale ti trovi.
                                </p>
                            </div>

                        </div>

                    </div>

                    <div className='border-t-[2px] border-t-[#D9D9D9]'>

                        {document.cookie.includes('RestaurantsClubCookies') ? (

                            <div className='flex flex-col md:w-[57%] xmd:w-[47%] lg:w-[37%] xl:w-[37%] px-3 mx-auto'>
                                <button onClick={() => { setPopUp((val) => !val); setShowSection((val) => !val); setIsCookieCreated((val) => !val); denyCookies2() }} className='w-full px-12 py-3 xsm:px-6 xsm:py-4 sm:px-6 sm:py-4 md:px-4 pop600 border-[2px] border-[#114653] p-1 bg-[#114653] text-white mb-5 mt-5'>
                                    Accetta selezionati
                                </button>
                            </div>

                        ) : (

                            <div className='flex flex-col md:w-[57%] xmd:w-[47%] lg:w-[37%] xl:w-[37%] px-3 mx-auto'>

                                <button onClick={() => setPopUp((val) => !val)} className='w-full px-12 py-3 xsm:px-6 xsm:py-4 sm:px-6 sm:py-4 md:px-4 pop600 border-[2px] border-[#114653] text-scrollBlue p-1 my-5'>
                                    Vedi di meno
                                </button>

                                <button onClick={denyCookies} className='w-full px-12 py-3 xsm:px-6 xsm:py-4 sm:px-6 sm:py-4 md:px-4 pop600 border-[2px] border-[#114653] p-1 bg-[#114653] text-white mb-5'>
                                    Accetta selezionati
                                </button>

                            </div>

                        )
                        }

                    </div>

                </div>

            ) : (

                <div className={`bannerlgview ${showSection && !document.cookie.includes('RestaurantsClubCookies') ? 'visible' : 'invisible'} p-2 border-[3px] border-[#114653] `}>

                    <div className='flex flex-wrap'>

                        <div className=' md:p-3 xmd:p-3 lg:pl-12 xl:pl-12 xmd:mt-4 lg:mt-4 xl:mt-4 w-[70%] xsm:w-full sm:w-full md:w-full xmd:w-full xsm:text-center sm:text-center md:text-center xmd:text-center xsm:h-fit sm:h-fit overflow-y-auto scrollbar-hidden text-scrollBlue'>

                            <div className='flex flex-col'>
                                <button onClick={denyCookies} className='pop400 small underline xsm:mb-2 sm:mb-2 md:mb-2 lg:hidden xl:hidden text-end'>
                                    Continua senza accettare
                                    <KeyboardArrowRightOutlinedIcon />
                                </button>
                            </div>

                            <h6 className='xsm:mt-2 sm:mt-2 md:mt-2 xmd:mt-2'> Questo sito web utilizza i cookie </h6>

                            <p className='small'>Noi e terze parti selezionate utilizziamo cookie o tecnologie simili per finalità tecniche e, con il tuo consenso, anche per altre finalità come specificato nella <button onClick={goToCookie} className='underline'>cookie policy</button>. Il rifiuto del consenso può rendere non disponibili le relative funzioni.</p>

                        </div>

                        <div className='flex flex-col w-[30%] xsm:w-full sm:w-full md:w-full xmd:w-full justify-between xsm:items-center sm:items-center md:items-center xmd:items-center lg:items-end xl:items-end text-scrollBlue lg:pr-28 xl:pr-28'>

                            <div className='flex flex-col'>
                                <button onClick={denyCookies} className='mt-3 pop400 small underline xsm:mb-2 sm:mb-2 md:mb-2 xsm:hidden sm:hidden md:hidden xmd:hidden'>
                                    Continua senza accettare
                                    <KeyboardArrowRightOutlinedIcon />
                                </button>
                            </div>

                            <div className='flex flex-col xsm:h-[99%] sm:h-[99%] md:h-[99%]'>

                                <button onClick={acceptCookies} className='w-full xsm:px-6 xsm:py-3 sm:px-6 sm:py-3 md:px-4 md:py-3 xmd:py-3 xmd:px-4 pop600 border-[2px] border-[#114653] p-1 bg-[#114653] text-white xsm:mt-5 mt-3'>
                                    Accetta i cookie
                                </button>

                                <button onClick={() => setPopUp((val) => !val)} className='w-full xsm:px-6 xsm:py-3 sm:px-6 sm:py-3 md:px-4 md:py-3 xmd:py-3 xmd:px-4 pop600 border-[2px] py-1 px-2 border-[#114653] mt-3 xsm:mb-5 sm:mb-5 md:mb-5 mb-2'>
                                    Personalizza i cookie
                                </button>

                            </div>

                        </div>

                    </div>

                </div>
            )

            }

            {
                isCookieCreated ? (

                    <>
                        {cookieChoiceWindow ? (

                            <>

                                <div className="xsm:w-[60%] sm:w-[60%] md:w-[60%] xmd:w-[30%] w-[20%] border-[3px] border-[#114653] fixed bottom-5 left-0 z-50 bg-white">

                                    <div className='border-b-[2px] border-b-[#D9D9D9] text-scrollBlue px-2 py-3 flex items-center justify-between'>
                                        <span className='pop600'>
                                            Impostazioni Cookie
                                        </span>
                                        <button onClick={() => setCookieChoiceWindow((val) => !val)} className=''>
                                            <ClearIcon />
                                        </button>
                                    </div>

                                    <div className='flex flex-col items-center border-t-[0px] border-[#114653] xmd:h-[300px] text-scrollBlue overflow-auto'>

                                        <div className="w-full p-2">

                                            <div className="flex pb-1">

                                                <div className='flex items-center'>
                                                    {security ? (

                                                        <span className='text-secondarycol'>
                                                            <LockOutlinedIcon />
                                                        </span>

                                                    ) : (

                                                        <span className='text-black'>
                                                            <ClearIcon />
                                                        </span>

                                                    )
                                                    }
                                                    <span className="pop400">Necessari</span>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="w-full px-2 pb-2">

                                            <div className="flex justify-between pb-1">

                                                <div className='flex items-center'>
                                                    {cookieValue('"ad_storage":true') ? (

                                                        <span className='text-secondarycol'>
                                                            <LockOutlinedIcon />
                                                        </span>

                                                    ) : (

                                                        <span className='text-black'>
                                                            <ClearIcon />
                                                        </span>

                                                    )
                                                    }
                                                    <span className="pop400 flex items-center">Marketing</span>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="w-full px-2 pb-2">

                                            <div className="flex justify-between pb-1">

                                                <div className='flex items-center'>
                                                    {cookieValue('"analytics_storage":true') ? (

                                                        <span className='text-secondarycol'>
                                                            <LockOutlinedIcon />
                                                        </span>

                                                    ) : (

                                                        <span className='text-black'>
                                                            <ClearIcon />
                                                        </span>

                                                    )
                                                    }
                                                    <span className="pop400 flex items-center">Statistiche</span>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="w-full px-2 pb-2">

                                            <div className="flex justify-between pb-1">

                                                <div className='flex items-center'>
                                                    {cookieValue('"functional_storage":true') ? (

                                                        <span className='text-secondarycol'>
                                                            <LockOutlinedIcon />
                                                        </span>

                                                    ) : (

                                                        <span className='text-black'>
                                                            <ClearIcon />
                                                        </span>

                                                    )
                                                    }
                                                    <span className="pop400 flex items-center">Funzionali</span>
                                                </div>

                                            </div>

                                        </div>

                                        <div className="w-full px-2 pb-2">

                                            <div className="flex justify-between pb-1">

                                                <div className='flex items-center'>
                                                    {cookieValue('"personalization_storage":true') ? (

                                                        <span className='text-secondarycol'>
                                                            <LockOutlinedIcon />
                                                        </span>

                                                    ) : (

                                                        <span className='text-black'>
                                                            <ClearIcon />
                                                        </span>

                                                    )
                                                    }
                                                    <span className="pop400 flex items-center">Preferenze</span>
                                                </div>

                                            </div>

                                        </div>

                                    </div>

                                    <div className=''>

                                        <div className='text-end mr-3 text-scrollBlue pop400 pb-2'>

                                            {popUp ? (

                                                <button onClick={() => { setPopUp((val) => val); setShowSection((val) => !val); setIsCookieCreated((val) => !val) }} className='underline'>
                                                    Modifica il consenso
                                                    <KeyboardArrowRightOutlinedIcon />
                                                </button>

                                            ) : (

                                                <button onClick={() => { setPopUp((val) => !val); setShowSection((val) => !val); setIsCookieCreated((val) => !val) }} className='underline'>
                                                    Modifica il consenso
                                                    <KeyboardArrowRightOutlinedIcon />
                                                </button>

                                            )}

                                        </div>

                                    </div>
                                </div>
                            </>

                        ) : (

                            <button onClick={() => setCookieChoiceWindow((val) => !val)} className='w-16 h-16 fixed bottom-5 left-8 z-50'>
                                <img src={CookieImg} alt="CookieImg">

                                </img>
                            </button>

                        )

                        }

                    </>
                ) : (
                    <>

                    </>
                )
            }
        </>

    );
};

export default CookieBanner;