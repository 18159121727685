import "./App.css";
import React, { useEffect } from "react";
import Routing from "./Navigation/Routes";
import NavBar from "./Navigation/NavBar";
import "animate.css";
import "animate.css/animate.min.css";
import { useLocation } from "react-router-dom";
import ScrollToTopButton from "./Reusable/ScrollToTopButton";
import CookieBanner from "./Reusable/CookieBar/CookieBanner";
import i18n from "./locales";
import Footer from "./Navigation/Footer";
import { logger } from "./Services/Logger";

if (!i18n.isInitialized) {
  i18n.init();
}

const App = () => {
  useEffect(() => {
    logger.init();
  }, []);

  const location = useLocation();
  return (
    <React.Fragment>
      <NavBar location={location} />
      <Routing />
      <Footer landingFooter={location.pathname.includes("iera")} />
      <ScrollToTopButton hideOnSmallScreen={location.pathname === "/quiz1"} />
      <CookieBanner />
    </React.Fragment>
  );
};

export default App;
