const ObjectSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <mask
        id="mask0_0_7627"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.259399" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_0_7627)">
        <path
          d="M18 13.2594V11.2594H22V13.2594H18ZM19.2 20.2594L16 17.8594L17.2 16.2594L20.4 18.6594L19.2 20.2594ZM17.2 8.2594L16 6.6594L19.2 4.2594L20.4 5.8594L17.2 8.2594ZM5 19.2594V15.2594H4C3.45 15.2594 2.97917 15.0636 2.5875 14.6719C2.19583 14.2802 2 13.8094 2 13.2594V11.2594C2 10.7094 2.19583 10.2386 2.5875 9.8469C2.97917 9.45523 3.45 9.2594 4 9.2594H8L13 6.2594V18.2594L8 15.2594H7V19.2594H5ZM14 15.6094V8.9094C14.45 9.3094 14.8125 9.7969 15.0875 10.3719C15.3625 10.9469 15.5 11.5761 15.5 12.2594C15.5 12.9427 15.3625 13.5719 15.0875 14.1469C14.8125 14.7219 14.45 15.2094 14 15.6094ZM4 11.2594V13.2594H8.55L11 14.7094V9.8094L8.55 11.2594H4Z"
          fill="#475467"
        />
      </g>
    </svg>
  );
};
export default ObjectSvg;
