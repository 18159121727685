import cn from "classnames";
import styles from "./ImagesContainer.module.css";
import AttractiveFrame from "./icons/AttractiveFrame";
import ConvertedFrame from "./icons/ConvertedFrame";
import LoyaltyFrame from "./icons/LoyaltyFrame";
const ImagesContainer = ({
  attractiveSectionInView,
  convertedSectionInView,
  loyaltySectionInView,
}) => {
  return (
    <div
      className={cn(
        styles.sticky_picture,
        "w-full flex items-center justify-center mt-48"
      )}
    >
      <div
        className={cn(styles.display_first_frame, {
          [styles.hide_first_frame]: !attractiveSectionInView,
          [styles.display_first_frame]: attractiveSectionInView,
        })}
      >
        <AttractiveFrame />
      </div>
      <div
        className={cn({
          [styles.display_second_frame]: convertedSectionInView,
          [styles.hide_second_frame]:
            loyaltySectionInView || attractiveSectionInView,
        })}
      >
        <ConvertedFrame />
      </div>
      <div
        className={cn(
          loyaltySectionInView
            ? styles.display_third_frame
            : styles.hide_third_frame,
          "mb-48"
        )}
      >
        <LoyaltyFrame />
      </div>
    </div>
  );
};
export default ImagesContainer;
