import ImageIg1 from "./img/Ig7.png";
import ImageIg2 from "./img/Ig2.png";
import ImageIg3 from "./img/Ig1.png";
import RestaurantStoryElement from "../../../Components/Home/HomeElements/RestaurantStoryElement";

const RestaurantHistorySection = ({ t }) => {
  const sections = [
    {
      img: ImageIg1,
      title: t("homePage.RestaurantHistorySection.sections.1.title"),
      description: t(
        "homePage.RestaurantHistorySection.sections.1.description"
      ),
    },
    {
      img: ImageIg2,
      title: t("homePage.RestaurantHistorySection.sections.2.title"),
      description: t(
        "homePage.RestaurantHistorySection.sections.2.description"
      ),
    },
    {
      img: ImageIg3,
      title: t("homePage.RestaurantHistorySection.sections.3.title"),
      description: t(
        "homePage.RestaurantHistorySection.sections.3.description"
      ),
    },
  ];
  return (
    <div className=" w-[80%] xsm:w-full sm:w-full  md:w-full  xmd:w-full  px-[40px] mx-auto flex flex-col   h-fit relative pt-9 pb-20">
      <div className="w-full xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto pb-6 ">
        <p className="Brevia850 text-5xl xsm:text-4xl sm:text-4xl md:text-4xl xmd:text-4xl text-[#00B27A] text-center font-black ">
          {t("homePage.RestaurantHistorySection.title")}
        </p>
      </div>

      <div className="flex flex-row sm:flex-col my-2">
        <div className=" ">
          <div className="flex flex-col items-center w-8/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto pb-6 ">
            <p className="pop500 text-xl text-center text-black font-medium leading-9">
              "{t("homePage.RestaurantHistorySection.description")} "
            </p>
          </div>

          <div className="flex flex-col items-center w-8/12 mx-auto ">
            <p className="pop500 text-lg italic text-center text-black font-medium leading-9">
              {t("homePage.RestaurantHistorySection.tag")}
            </p>
          </div>

          <div className="w-full flex flex-row xsm:flex-col sm:flex-col gap-x-[20px] md:gap-x-[10px] ">
            {sections.map((section) => (
              <RestaurantStoryElement key={section.title} {...section} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default RestaurantHistorySection;
