import TrialButton from "../TrailButton";
import cn from "classnames";
import styles from "./PlanCard.module.css";
import { scrollToSection } from "../../../utils/scrollToSection";
const PlanCard = ({
  icon,
  price,
  type,
  addition,
  offers,
  index,
  plan,
  buttonProps,
  sectionId,
  t,
}) => {
  return (
    <div
      className={cn(
        "border-[3px] border-rcblue relative",
        styles.card_container,
        {
          [styles.middle_card]: index === 1,
          [styles.first_card]: index === 0,
        }
      )}
    >
      {index === 1 && (
        <div className={styles.badge}>
          <img src={icon} alt="cockade"></img>
          <span>{t("pricesPage.plansSection.badge")}</span>
        </div>
      )}

      <>
        <div className="text-center pt-12 h-[245px]">
          <h3 className={styles.price}>{price}€</h3>

          <span className={styles.iva}>
            {plan === 2 && t("pricesPage.plansSection.month")} + IVA
          </span>
          <div className={styles.type_addition}>
            <div>
              {type}
              {index !== 0 && " +"}
            </div>
            <div>{addition}</div>
          </div>
        </div>

        <div className="px-6 h-[250px]">
          {offers.map((offer, index1) => (
            <>
              {index === 1 && index1 === 1 && (
                <p className={styles.choose_one}>
                  {t("pricesPage.plansSection.chooseOne")}
                </p>
              )}
              <div className={"mb-3 flex"}>
                <div className={styles.svg_container}>
                  <offer.icon />
                </div>
                <span className={styles.item_text}>{offer.offer}</span>
              </div>
            </>
          ))}

          {index === 0 && (
            <div className={styles.read_more}>
              {t("pricesPage.plansSection.more")}
            </div>
          )}
        </div>

        <TrialButton
          {...buttonProps}
          onClick={() => scrollToSection(sectionId)}
        />
      </>
    </div>
  );
};
export default PlanCard;
