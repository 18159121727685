import React from "react";

export default function PdfDocuments() {

  return (

    <div className='flex flex-row w-full justify-center items-center my-12' >

      <a href={require('./AllegatoAaContrattodiconsulenzaeprocacciamentodiaffari_.pdf')} target="_blank">

      <p className='text-black pop400 text-xl '>Leggi qui </p>
        <p className='text-[#00B17A] Brevia700 text-lg hover:cursor-pointer hover:underline'>ALLEGATO A Contratto di consulenza e procacciamento di affari</p>
        
      </a>

    </div>

  );

}