import { ExpandMore } from "@mui/icons-material";
import React from "react";
import { NavLink } from "react-router-dom";

const NavBarLink = ({ children, title, shouldExpand, link, t }) => {
  const [menuToggled, setMenuToggled] = React.useState(false);
  return (
    <div>
      <NavLink
        to={link}
        onMouseEnter={() => shouldExpand && setMenuToggled(true)}
        onMouseLeave={() => shouldExpand && setMenuToggled(false)}
        className={({ isActive }) =>
          `pop500 hover:cursor-pointer hover:underline hover:underline-offset-4 ${
            isActive && link && "link--active"
          }`
        }
      >
        <div className="flex items-center p-4 hover:underline hover:underline-offset-4 cursor-pointer">
          {t(title)}
          {shouldExpand && (
            <ExpandMore
              size="large"
              onMouseEnter={() => shouldExpand && setMenuToggled(true)}
              className={`${
                menuToggled && "rotate-180 "
              } transition-all duration-1000`}
            />
          )}
        </div>
      </NavLink>
      {menuToggled &&
        children &&
        React.cloneElement(children, {
          onOpen: () => setMenuToggled(true),
          onClose: () => setMenuToggled(false),
        })}
    </div>
  );
};
export default NavBarLink;
