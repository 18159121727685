import React from "react";
import cn from "classnames";
import desk from "../img/desk.png";
import styles from "./ImgeHero.module.css"

const ImageHero = ({ t }) => {

  return (
    
    <>
      <img
        src={desk}
        alt=""
        className={cn("", styles.img_container)}
      ></img>
      <div className={cn(" w-full bg-rcgreen ", styles.main_container)}></div>
    </>

  );
};

export default ImageHero;
