import React from 'react';
import { createTheme } from '@mui/system';
import Check from "../../Assets/Check.svg";
import { PopupButton } from 'react-calendly';

const Contact = ({ t }) => {

  return (
    <>
      <div className='flex flex-col w-1/2 xsm:w-full sm:w-full md:w-full xmd:w-full mx-auto'>
        <div className='flex justify-self-start'>
          <div className='w-full text-left flex flex-col sm:p-5 py-20 xmd:py-10 md:py-5 gap-y-6'>
            <h1 className='Brevia850 text-5xl md:text-3xl sm:text-2xl text-[#00B27A] w-full leading-[60px] xmd:text-center md:text-center sm:text-center xsm:text-center'> {t("contact.title")}</h1>
            <h3 className='Brevia700 italic text-2xl md:text-xl sm:text-lg text-black w-[75%] xmd:text-center md:text-center sm:text-center xsm:text-center xsm:mx-auto sm:mx-auto  md:mx-auto xmd:mx-auto'> {t("contact.des1")}</h3>
            <div className='flex flex-row gap-x-4 w-full xsm:hidden sm:hidden md:hidden xmd:hidden'>
              <img src={Check} />
              <p className='pop400 text-xl md:text-lg sm:text-lg text-black w-[90%]'> {t("contact.items.firstText")}</p>
            </div>
            <div className='flex flex-row gap-x-4 w-full xsm:hidden sm:hidden md:hidden xmd:hidden'>
              <img src={Check} />
              <p className='pop400 text-xl md:text-lg sm:text-lg text-black w-[90%]'>{t("contact.items.secondText")}</p>
            </div>

            <div className='flex flex-row gap-x-4 w-full xsm:hidden sm:hidden md:hidden xmd:hidden'>
              <img src={Check} />
              <p className='pop400 text-xl md:text-lg sm:text-lg text-black w-[90%]'>{t("contact.items.thirdText")}</p>
            </div>

            <div className='flex flex-row gap-x-4 w-full xsm:hidden sm:hidden md:hidden xmd:hidden'>
              <p className='pop400 text-xl md:text-lg sm:text-lg text-black w-[90%] '>Per questioni tecniche e richieste generali, contatta <span className='pop700'>l’assistenza tramite la Dashboard </span> se sei già nostro cliente, altrimenti puoi chiamare il <span className='pop700'>050 0985159</span> o contattarci tramite WhatsApp al numero <span className='pop700'>331 291 0408</span></p>
            </div>

            <h3 className='Brevia700 italic text-2xl md:text-xl sm:text-lg text-black w-[75%] xsm:hidden sm:hidden md:hidden xmd:hidden'>{t("contact.buttondes")}</h3>

            <PopupButton
              className='w-[50%] md:w-[75%] sm:w-[75%] h-14 border-[3px] xmd:hidden border-black items-center flex justify-center pop600 text-lg xsm:hidden sm:hidden md:hidden '
              url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club"
              rootElement={document.getElementById("root")}
              text="Prenota una Chiamata"
            />

          </div>
        </div>
      </div>
    </>
  )
}

export default Contact