import React from "react";

import planImg from "../img/1.png";
import styles from "./DesignYourPlan.module.css";
import TrialButton from "../../../Components/Pricing/TrailButton";
import cn from "classnames";
import { scrollToSection } from "../../../utils/scrollToSection";

const DesignYourPlanSection = ({ t }) => {
  return (
    <div className={cn("w-[60%] m-auto flex flex-wrap", styles.container)}>
      <div className={cn("w-[50%] m-auto", styles.text_container)}>
        <div className="">
          <h4 className={styles.title}>
            {t("pricesPage.designYourPlanSection.title")}
          </h4>
        </div>

        <p className={styles.text}>
          {t("pricesPage.designYourPlanSection.description")}
        </p>

        <div className={cn("flex mt-6", styles.button_container)}>
          <TrialButton
            text={t("pricesPage.designYourPlanSection.button")}
            className={styles.trial_button}
            borderColor="[#114653]"
            onClick={() => scrollToSection("contact_form")}
          />
        </div>
      </div>
      <div className={cn("w-[50%] flex justify-end", styles.img_container)}>
        <img src={planImg} alt="planImage"></img>
      </div>
    </div>
  );
};

export default DesignYourPlanSection;
