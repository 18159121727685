import { Link } from "react-router-dom";
import heroHomePicture from "../../../Assets/herohome.png";
import { animatedScroll } from "../../../utils/animatedScroll";
import cn from "classnames";
import styles from "./Hero.module.css";

const HeroSection = ({ t }) => {
  const scrollToWibp = () => animatedScroll("wibp");
  return (

    <div className="w-[80%] mx-auto flex flex-col py-10 ">
      <div className="flex flex-row flex-wrap">
        <div className={cn("w-[50%]", styles.text_container)}>
          <div className="flex flex-col items-center justify-center ">
            <h1 className={styles.title}>{t("homePage.heroSection.title")}</h1>
            <p className={styles.paragraph}>
              {t("homePage.heroSection.p1")}
              <p className={styles.only_on_small_devices}>
                {t("homePage.heroSection.p2")}
              </p>
            </p>          
          </div>

          <div className="flex xsm:justify-center sm:justify-center md:justify-start xsm:flex-col sm:flex-col md:flex-col gap-5 ">

            <div className="self-center">
              <Link className="App-button" to="/video">
                {t("homePage.heroSection.button1")}
              </Link>
            </div>
            <div className="self-center">
              <button
                onClick={scrollToWibp}
                className={cn("App-button stroke", styles.custom_button)}
              >
                {t("homePage.heroSection.button2")}
              </button>
            </div>
          </div>
        </div>

        <div className={cn("w-[40%] flex", styles.picture_container)}>
          <img src={heroHomePicture} alt="heroHomePicture" />
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
