import React from 'react'
import { useNavigate } from "react-router-dom"
import Frame4 from "../../Assets/img1.svg";
import Frame5 from "../../Assets/img_2.svg";
import Frame6 from "../../Assets/img3.svg";
import { PopupButton } from 'react-calendly';



function AttConvFidel() {

    const navigate = useNavigate()

    return (

            <div className='py-20 bg-[#F6F6F4]' >
                <div className='w-[80%] lg:w-[98%]  px-[40px] sm:px-2 sm:w-full sm:flex-col gap-y-6 md:w-full md:px-6 md:flex-col    xsm:flex-col  mx-auto flex flex-row gap-x-9'>

                    <div className='w-1/3 md:w-full sm:w-full xmd:w-full xsm:w-full  mx-auto flex flex-col gap-y-5 items-center'>

                        <div className=' w-full flex items-center justify-center  p-6'>
                            <img src={Frame6} className='xsm:w-1/2 sm:w-1/2  md:w-1/2 w-full h-full' alt="" />
                        </div>
                        <p className='Brevia700 not-italic font-bold  text-5xl sm:text-3xl text-center text-[#114653] '>Attrai</p>
                        <p className='pop400    not-italic font-normal text-xl sm:text-lg text-center text-black  mx-auto'>Cattura l’attenzione su internet e fai conoscere la tua attività</p>

                    </div>

                    <div className=' w-1/3 md:w-full sm:w-full xmd:w-full xsm:w-full  mx-auto flex flex-col gap-y-5 '>
                        <div className=' w-full  flex items-center justify-center  p-6'>
                            <img src={Frame5} className='xsm:w-1/2 sm:w-1/2  md:w-1/2 w-full h-full' alt="" />
                        </div>
                        <p className='Brevia700 not-italic font-bold  text-5xl sm:text-3xl  text-center text-[#114653]   '>Converti </p>
                        <p className='pop400 not-italic font-normal  text-xl sm:text-lg text-center text-black  mx-auto'>Porta nuovi clienti al tuo ristorante e aumenta il tuo fatturato</p>

                    </div>

                    <div className=' w-1/3 md:w-full sm:w-full xmd:w-full xsm:w-full mx-auto flex flex-col gap-y-5 '>
                        <div className='w-full flex items-center justify-center  p-6'>
                            <img src={Frame4} className='xsm:w-1/2 sm:w-1/2  md:w-1/2 w-full h-full' alt="" />
                        </div>
                        <p className='Brevia700  not-italic font-bold  text-5xl sm:text-3xl  text-center text-[#114653]   '>Fidelizza </p>
                        <p className='pop400     not-italic font-normal text-xl sm:text-lg text-center text-black mx-auto'>Crea una base clienti fedele e affezionata con cui crescere</p>

                    </div>

                </div>
                <div className=' my-10'>
                    <div className=' mx-auto my-5 w-72 h-12 border-[3px] bg-[#DCFFCF] border-black items-center flex justify-center pop600 text-lg text-black'>

                        <PopupButton
                            url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club"
                            rootElement={document.getElementById("root")}
                            text="Fai un Tour"
                        />

                    </div>

                    <div className='mx-auto  w-max'>

                       
                    <button
              className='mx-auto pop600 text-lg border-b-2 border-black text-black sm:text-sm xmd:text-sm w-max'
              onClick={() => navigate("/contatti")}
            >
              Oppure ricevi un consiglio da un nostro esperto
            </button>


                    </div>
                </div>
            </div>



    )
}

export default AttConvFidel