import * as React from "react";
const ArrowIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={77}
    height={77}
    fill="none"
    {...props}
  >
    <g clipPath="url(#a)">
      <path
        stroke="#114653"
        strokeWidth={3}
        d="m24.833 26.498-1.06-1.059-1.06 1.06-4.524 4.524-1.06 1.06 1.06 1.061 19.25 19.25 1.061 1.06 1.06-1.06 19.25-19.25 1.061-1.06-1.06-1.061-4.524-4.524-1.06-1.06-1.06 1.059L38.5 40.135 24.833 26.498Z"
      />
    </g>
    <path stroke="#114653" strokeWidth={3} d="M1.5 1.5h74v74h-74z" />
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h77v77H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default ArrowIcon;
