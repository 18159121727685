import AppAccordion from "../../../Components/Accordion";

const FAQSection = ({ t }) => {
  const FAQ = [
    {
      title: t("homePage.faqSection.q1.title"),
      tableData: [
        {
          module: t("homePage.faqSection.q1.tableData.row1.module"),
          advantages: t("homePage.faqSection.q1.tableData.row1.advantages"),
          instruments: t("homePage.faqSection.q1.tableData.row1.instruments"),
        },
        {
          module: t("homePage.faqSection.q1.tableData.row2.module"),
          advantages: t("homePage.faqSection.q1.tableData.row2.advantages"),
          instruments: t("homePage.faqSection.q1.tableData.row2.instruments"),
        },
        {
          module: t("homePage.faqSection.q1.tableData.row3.module"),
          advantages: t("homePage.faqSection.q1.tableData.row3.advantages"),
          instruments: t("homePage.faqSection.q1.tableData.row3.instruments"),
        },
      ],
    },
    {
      title: t("homePage.faqSection.q2.title"),
      tableData: [
        {
          module: t("homePage.faqSection.q2.tableData.row1.module"),
          advantages: t("homePage.faqSection.q2.tableData.row1.advantages"),
          instruments: t("homePage.faqSection.q2.tableData.row1.instruments"),
        },
        {
          module: t("homePage.faqSection.q2.tableData.row2.module"),
          advantages: t("homePage.faqSection.q2.tableData.row2.advantages"),
          instruments: t("homePage.faqSection.q2.tableData.row2.instruments"),
        },
        {
          module: t("homePage.faqSection.q2.tableData.row3.module"),
          advantages: t("homePage.faqSection.q2.tableData.row3.advantages"),
          instruments: t("homePage.faqSection.q2.tableData.row3.instruments"),
        },
      ],
    },
    {
      title: t("homePage.faqSection.q3.title"),
      tableData: [
        {
          module: t("homePage.faqSection.q3.tableData.row1.module"),
          advantages: t("homePage.faqSection.q3.tableData.row1.advantages"),
          instruments: t("homePage.faqSection.q3.tableData.row1.instruments"),
        },
        {
          module: t("homePage.faqSection.q3.tableData.row2.module"),
          advantages: t("homePage.faqSection.q3.tableData.row2.advantages"),
          instruments: t("homePage.faqSection.q3.tableData.row2.instruments"),
        },
        {
          module: t("homePage.faqSection.q3.tableData.row3.module"),
          advantages: t("homePage.faqSection.q3.tableData.row3.advantages"),
          instruments: t("homePage.faqSection.q3.tableData.row3.instruments"),
        },
      ],
    },
    {
      title: t("homePage.faqSection.q4.title"),
      tableData: [
        {
          module: t("homePage.faqSection.q4.tableData.row1.module"),
          advantages: t("homePage.faqSection.q4.tableData.row1.advantages"),
          instruments: t("homePage.faqSection.q4.tableData.row1.instruments"),
        },
        {
          module: t("homePage.faqSection.q4.tableData.row2.module"),
          advantages: t("homePage.faqSection.q4.tableData.row2.advantages"),
          instruments: t("homePage.faqSection.q4.tableData.row2.instruments"),
        },
        {
          module: t("homePage.faqSection.q4.tableData.row3.module"),
          advantages: t("homePage.faqSection.q4.tableData.row3.advantages"),
          instruments: t("homePage.faqSection.q4.tableData.row3.instruments"),
        },
        {
          module: t("homePage.faqSection.q4.tableData.row4.module"),
          advantages: t("homePage.faqSection.q4.tableData.row4.advantages"),
          instruments: t("homePage.faqSection.q4.tableData.row4.instruments"),
        },
        {
          module: t("homePage.faqSection.q4.tableData.row5.module"),
          advantages: t("homePage.faqSection.q4.tableData.row5.advantages"),
          instruments: t("homePage.faqSection.q4.tableData.row5.instruments"),
        },
        {
          module: t("homePage.faqSection.q4.tableData.row6.module"),
          advantages: t("homePage.faqSection.q4.tableData.row6.advantages"),
          instruments: t("homePage.faqSection.q4.tableData.row6.instruments"),
        },
        {
          module: t("homePage.faqSection.q4.tableData.row7.module"),
          advantages: t("homePage.faqSection.q4.tableData.row7.advantages"),
          instruments: t("homePage.faqSection.q4.tableData.row7.instruments"),
        },
      ],
    },
  ];
  return (
    <div
      id="wibp"
      className="w-[80%] mx-auto flex flex-col my-5 h-fit relative"
    >
      <h3 className="Brevia700 italic text-[#114653] text-center">
        {t("homePage.faqSection.title")}
      </h3>
      <div
        id="accorditionid"
        className="mt-10 w-full mx-auto flex flex-col gap-y-4"
      >
        {FAQ.map((qst) => (
          <AppAccordion {...qst} t={t} />
        ))}
      </div>
    </div>
  );
};
export default FAQSection;
