import XImage from "../../Assets/blob.svg";
import { Backdrop, Box, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "80vh",
  bgcolor: "background.paper",
  border: "10px solid #000",
  boxShadow: 24,
  p: 4,
  padding: "8%",
  display: "flex",
  flexDirection: "column",
  gap: "8rem",
  placeContent: "center",
};

export default function ConfirmModal({
  isOpen,
  onClose,
  onConfirm,
}) {

  return (
    <Modal
    aria-labelledby="confirm-modal-title"
    aria-describedby="confirm-modal-description"
    open={isOpen}
    onClose={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500,
    }}
  >
    <Box sx={style}>
      <div className="w-full flex place-content-end">
        <img
          src={XImage}
          alt=""
          className="w-8 md:w-6 sm:w-6 cursor-pointer"
          onClick={onClose}
        />
      </div>

      <div className="w-[80%] md:w-full sm:w-full self-center">
        <p className="text-[#00B27A] Brevia850 text-5xl md:text-3xl sm:text-3xl text-center">
          Ti abbiamo inviato una mail per la modifica della tua password
        </p>
      </div>

      <button
        onClick={onConfirm}
        className="w-[40%] xmd:w-[75%] sm:w-[75%] md:w-full h-12 sm:h-24 border-[3px] bg-[#DCFFCF] border-black text-center self-center"
      >
        <p className="pop500 text-black text-bold">
          Torna alla schermata di accesso
        </p>
      </button>
    </Box>
  </Modal>
  )
}