import styles from "../Elements.module.css";
import cn from "classnames";
import ProductTourButton from "../../ProductTourButon";
import Tag from "../../Tag";
const Attractive = ({ t }) => {
  const tags = [
    t("homePage.attractiveSection.attractive.tags.1"),
    t("homePage.attractiveSection.attractive.tags.2"),
    t("homePage.attractiveSection.attractive.tags.3"),
  ];
  return (
    <div
      className={cn(
        "py-40",
        styles.no_padding_in_mobile,
        styles.scrolled_section
      )}
    >
      <h3 className="Brevia700 text-black xsm:text-center sm:text-center md:text-center">
        {t("homePage.attractiveSection.attractive.title")}
      </h3>
      <div className="flex flex-col gap-x-4 w-full my-4">
        <p className="pop400 text-base text-black w-full xsm:text-center sm:text-center md:text-center">
          {t("homePage.attractiveSection.attractive.descriptionPart1")}
          <span className="pop700">
            {t("homePage.attractiveSection.attractive.bold1")}
          </span>{" "}
          {t("homePage.attractiveSection.attractive.descriptionPart2")}
          <span className="pop700">
            {t("homePage.attractiveSection.attractive.bold2")}
          </span>
          {t("homePage.attractiveSection.attractive.descriptionPart3")}
          <span className="pop700">
            {t("homePage.attractiveSection.attractive.bold3")}
          </span>
          {t("homePage.attractiveSection.attractive.descriptionPart4")}
        </p>
      </div>
      <div className="pb-10 md:text-center sm:text-center">
        <h5 className="pop600 py-2">
          {t("homePage.attractiveSection.doItWith")}
        </h5>
        <div className="flex flex-row w-full gap-2 flex-wrap md:justify-center sm:justify-center">
          {tags.map((tag) => (
            <Tag title={tag} color="[#114653]" textColor="white" />
          ))}
        </div>
      </div>
      <ProductTourButton fill="black" t={t} />
    </div>
  );
};
export default Attractive;
