import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { logger } from '../../Services/Logger';

function HubspotContactForm() {

  return (

    <HubspotForm
      portalId='26462757'
      formId='867310e0-4a57-46dc-9fd8-309adf1ba4d9'
      onSubmit={() => logger.info('Contact submit!')}
      onReady={(form) => logger.debug('Contact form ready!')}
      loading={<div>Loading...</div>}
    />

  )
}

export default HubspotContactForm