import React from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import cn from "classnames";
import planImg from "../img/img1.png";
import styles from "./PlanSectionInfo.module.css";


const PlanSectionInfo = ({ t }) => {
  const packCards = [
    {
      title: "functionPage.functionSection.packs.pack1.title",
      items: [
        {
          text: "functionPage.functionSection.packs.pack1.items.1",
        },
        {
          text: "functionPage.functionSection.packs.pack1.items.2",
        },
        {
          text: "functionPage.functionSection.packs.pack1.items.3",
        },
      ],

      icon: CheckRoundedIcon,
      bgcolor: "rcchromaticgreen",
    },
  ];
  return (
    <div className={cn("w-[80%] m-auto flex flex-wrap", styles.container)}>
      <div className={cn("w-[50%] m-auto", styles.text_container)}>
        <div className="">
          <h4 className={styles.title}>
            {t("functionPage.designYourPlanSection.title")}
          </h4>
        </div>
        <p className={styles.text}>
          {t("functionPage.designYourPlanSection.description")}
        </p>
        {packCards.map((item, index) => (
          <>
            <div className={cn(" mt-12", styles.text_container)}>
              {item.items.map(({ text }, index) => (
                <div className=" flex" key={index}>
                  <div className="w-[25px] h-[25px]  text-rcgreen rounded-full inline-block mr-3">
                    <item.icon />
                  </div>
                  <div className={styles.item_text}>{t(text)}</div>
                </div>
              ))}
            </div>
          </>
        ))}
      </div>
      <div className={cn("w-[50%] flex justify-end", styles.img_container)}>
        <img src={planImg} alt="planImage"></img>
      </div>
    </div>
  );
};

export default PlanSectionInfo;
