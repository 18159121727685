import React from "react";
import { Routes, Route } from "react-router";
import Home from "../Pages/Home";
import Register from "../Components/Register/Register";
import Login from "../Components/Login/Login";

import Espresso from "../Components/Espresso/Espresso";
import Tavola from "../Components/Tavola/Tavola";
import Viandante from "../Components/Viandante/Viandante";

import Privacypolicy from "../Components/Privacy-Policy/Privacy-Policy-Page";
import Cookiepolicy from "../Components/Cookie-Policy/Cookie-Policy-Page";
import Termsandconditions from "../Components/Terms & Conditions/Terms-and-Conditions";

import Errornotfound from "../Components/Errors/404";
import ThankYou from "../Components/Response/ThankYou";
import Error from "../Components/Response/Error";

import Bidding from "../Components/Bidding/Bidding";
import Thefork from "../Components/Thefork/Thefork";

//import Beta from '../Components/Beta/Beta';
//import Beta2 from '../Components/Beta/Beta2';
//import Funzioni from '../Components/Functions/Functions'

import Funzioni2 from "../Pages/Functions";
import Test from "../Components/Test/Test";
import Contact from "../Pages/Contact";
import Demo from "../Containers/Demo/Demo"

//import Partner from '../Components/Partner/Partner';

import ForgotPassword from "../Components/Forgot-Password/ForgotPassword";
import PasswordChange from "./../Components/Password-Change/PasswordChange";
//import Preventivi from '../Components/Pricing/Preventivo';

import Quiz from "../Components/Preventivo/preventivo";
import Quiz1 from "../Components/Preventivo/quiz1";

import LssA from "../Components/DocumentsPDF/allA-Licenza.js";
import LssB from "../Components/DocumentsPDF/allB-Licenza.js";
import LssC from "../Components/DocumentsPDF/allC-Licenza.js";
import ContrattoConsulenza from "../Components/DocumentsPDF/ContrattoConsulenza.js";

import TheFork from "../Components/RCvs/TheFork";
import Plateform from "../Components/RCvs/Plateform";
import PienissimoPro from "../Components/RCvs/PienissimoPro";
import JustEat from "../Components/RCvs/JustEat";
import Deliveroo from "../Components/RCvs/Deliveroo";

import DiscoverCustomers from "../Components/DiscoverCustomers/DiscoverCustomers";
//import Supportopremium from '../Components/Supporto-premium/Supporto-premium';

import Sigep from "../Components/Sigep/Sigep.js";
import Beerfood from "../Components/Beer&Food/Beer&Food.js";
import ContattiFiera from "../Components/Fiere/ContattiFiera";
import PrivacyContratto from "../Components/Privacy-Policy/Privacy-Contratto";
import PrivacyFoodies from "../Components/Privacy-Policy/Privacy-Foodies";
import AccordoTitolarieta from "../Components/Terms & Conditions/Accordo-Titolarieta";

import Main from "../Components/Main/Main";
import SectionBase from "../Reusable/SectionBase/SectionBase";
// import Pricing from "../Components/Pricing/Pricing new";
import PricesPage from "../Pages/Pricing";

function Routing() {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route exact path="/prezzi" element={<PricesPage />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/espresso" element={<Espresso />} />
      <Route exact path="/tavola" element={<Tavola />} />
      <Route exact path="/viandante" element={<Viandante />} />
      <Route exact path="/privacypolicy" element={<Privacypolicy />} />
      <Route exact path="/cookiepolicy" element={<Cookiepolicy />} />
      <Route
        exact
        path="/termsandconditions"
        element={<Termsandconditions />}
      />
      <Route exact path="/thank-you" element={<ThankYou />} />
      <Route exact path="/error" element={<Error />} />
      <Route exact path="/privacy-contratto" element={<PrivacyContratto />} />
      <Route exact path="/privacy-foodies" element={<PrivacyFoodies />} />
      <Route
        exact
        path="/accordo-titolarieta"
        element={<AccordoTitolarieta />}
      />

      <Route exact path="/basta-commissioni" element={<Bidding />} />
      <Route exact path="/basta-sconti" element={<Thefork />} />

      {/*<Route exact path='/accessoanticipato' element={<Beta />} />*/}
      {/*<Route exact path='/accessoanticipato2' element={<Beta2 />} />*/}

      <Route exact path="/funzioni" element={<Funzioni2 />} />

      <Route exact path="/demo" element={<Test />} />
      <Route exact path="/video" element={<Demo />} />

      <Route exact path="/contatti" element={<Contact />} />

      <Route path="*" element={<Errornotfound />} />

      {/*<Route exact path='/funzioni2' element={<Funzioni />} />*/}

      <Route exact path="/forgot-password" element={<ForgotPassword />} />
      <Route exact path="/password" element={<PasswordChange />} />

      <Route
        exact
        path="/scopri-i-tuoi-clienti"
        element={<DiscoverCustomers />}
      />
      {/*<Route exact path='/preventivi' element={<Preventivi />} />*/}

      <Route exact path="/quiz" element={<Quiz />} />
      <Route exact path="/quiz1" element={<Quiz1 />} />

      <Route exact path="/allAcontrattoLSS" element={<LssA />} />
      <Route exact path="/allBcontrattoLSS" element={<LssB />} />
      <Route
        exact
        path="/allAcontrattoConsulenza"
        element={<ContrattoConsulenza />}
      />
      <Route exact path="/allccontrattoLSS" element={<LssC />} />

      <Route exact path="/RestaurantsClub-vs-TheFork" element={<TheFork />} />
      <Route
        exact
        path="/RestaurantsClub-vs-Plateform"
        element={<Plateform />}
      />
      <Route
        exact
        path="/RestaurantsClub-vs-PienissimoPro"
        element={<PienissimoPro />}
      />
      <Route exact path="/RestaurantsClub-vs-JustEat" element={<JustEat />} />
      <Route
        exact
        path="/RestaurantsClub-vs-Deliveroo"
        element={<Deliveroo />}
      />

      {/*<Route exact path='/partner' element={<Partner />} /> */}
      {/*<Route exact path='/supporto-premium' element={<Supportopremium />} /> */}

      <Route exact path="/fiera-sigep" element={<Sigep />} />
      <Route exact path="/beerfood" element={<Beerfood />} />
      <Route exact path="/fiera-contatti" element={<ContattiFiera />} />
    </Routes>
  );
}

export default Routing;
