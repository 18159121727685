import React from "react";
import ScrollToTop from '../../Reusable/ScrollToTop'
import HubspotFiereForm from '../Fiere/FiereForm';


function ContattiFiera() {

  return (
    <>

      <ScrollToTop />

      <div className="">

        <div className="w-[80%] pt-10 xmd:pt-5 sm:pt-5 xsm:pt-5 mx-auto grid grid-cols-2 xsm:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 xmd:grid-cols-1 mb-20 gap-4">

          <div className="w-[80%] sm:text-center xsm:text-center md:text-center xmd:text-center sm:w-full xsm:w-full xsm:mx-auto sm:mx-auto md:mx-auto xmd:mx-auto">

            <h3 className="Brevia700 italic text-[#00B27A] ">

            Non perdiamoci di vista! Inserisci qui i tuoi dati per restare in contatto con noi

            </h3>

          </div>
            
          <div className="w-full  sm:text-center xsm:text-center border-[10px]  md:border-[5px] sm:border-[5px] xsm:border-[5px] border-black">

            <div className="m-4">
            
            <HubspotFiereForm />

            </div>

          </div>

        </div>

      </div>

    </>
  )
}

export default ContattiFiera