import RestaurantsIcon2 from "../../Assets/icon2.png";
import { FaPhoneAlt } from "react-icons/fa";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import styles from "./Footer.module.css";
import cn from "classnames";
import SocialLink from "./SocialLink";
import Block from "./Block";
import { useFooter } from "./useFooter";
import React from "react";

const Footer = ({ landingFooter }) => {
  const { blocks, mobileSocialLinks, t } = useFooter(landingFooter);
  return (
    <div className="flex flex-col items-center py-14 bg-[#00B27A] h-fit relative">
      <div
        className={cn("flex flex-row gap-8 mt-3 mb-10", styles.mobile_socials)}
      >
        {mobileSocialLinks.map((link) => (
          <SocialLink {...link} />
        ))}
      </div>

      <div
        className={cn(
          `flex gap-5 ${landingFooter ? "w-[80%]" : "w-[90%]"}`,
          styles.blocks_container
        )}
      >
        <div
          className={cn(
            "flex flex-col gap-3",
            landingFooter ? styles.landing_block : styles.block
          )}
        >
          <div className="w-[80%] md:w-full">
            <img src={RestaurantsIcon2} alt="logo" />
          </div>
          {!landingFooter && (
            <React.Fragment>
              <h3 className="pop700 flex flex-row items-center gap-x-2 text-white ">
                <FaPhoneAlt color="white" size={20} />{" "}
                <a href="tel:0500985159">050 0985159</a>
              </h3>
              <h3 className="pop700 flex flex-row items-center gap-x-2 text-white ">
                <WhatsAppIcon color="white" size={20} />{" "}
                <a href="https://wa.me/+393312910408" className="">
                  331 291 0408
                </a>
              </h3>
              <p className="text-white pop500 text-sm md:text-sm">
                {t("footer.contact.links.1")}
              </p>
              <p className="text-white pop500 text-sm md:text-sm">
                {t("footer.contact.links.2")}
              </p>
              <a href="mailto: info@restaurants.club">
                <p className="text-white pop500 text-sm md:text-sm">
                  {t("footer.contact.links.3")}
                </p>
              </a>
            </React.Fragment>
          )}
        </div>
        {blocks.map((block) => (
          <Block {...block} />
        ))}
      </div>

      <p className="text-white w-[80%] text-center pop500 text-[12px] my-2 sm:my-3 sm:text-sm sm:p-4 xsm:my-3 xsm:text-sm xsm:p-4 mt-24">
        {t("footer.p1")}
      </p>

      <p className="text-white w-[80%] text-center text-[12px] pop500 sm:my-3 sm:text-sm sm:p-4 xsm:my-3 xsm:text-sm xsm:p-4">
        {t("footer.p2")}
      </p>
    </div>
  );
};
export default Footer;
