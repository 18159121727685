import React from "react";
import i18n from "../locales";

export const LanguageContext = React.createContext({
  language: "fr",
});

const LanguageContextProvider = ({ children }) => {
  const [currentLanguage, setCurrentLanguage] = React.useState("it");

  React.useEffect(() => {
    const result = window.localStorage.getItem("selectedLanguage");
    if (!result) {
      i18n.changeLanguage("it");
      setCurrentLanguage("it");

      return;
    }

    setCurrentLanguage(result?.substring(0, 2));
    i18n.changeLanguage(result?.substring(0, 2));
  }, [currentLanguage]);

  const changeLanguage = React.useCallback((code) => {
    i18n.changeLanguage(code?.substring(0, 2));
    setCurrentLanguage(code?.substring(0, 2));
    window.localStorage.setItem("selectedLanguage", code?.substring(0, 2));
  }, []);

  return (
    <LanguageContext.Provider
      value={{
        language: currentLanguage,
        changeLanguage,
      }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

export default LanguageContextProvider;
