import React from "react";

import ObjectSvg from "./icons/ObjectSvg";
import CheckRoundedIcon from "./icons/PenSvg";
import ComputerOutlinedIcon from "./icons/LapTopSvg";
import EmojiObjectsOutlinedIcon from "./icons/LampSvg";
import Groups2OutlinedIcon from "./icons/UsersSvg";
import MailOutlineRoundedIcon from "./icons/EnvelopeSvg";
import MenuBookTwoToneIcon from "./icons/BookSvg";
import PercentOutlinedIcon from "./icons/PercentSvg";
import iconListImage from "../img/Frame.png";
import styles from "./Packages.module.css";
import cn from "classnames";

const PackagesSection = ({ t }) => {
  const packages = [
    {
      icon: ComputerOutlinedIcon,
      title: "pricesPage.packagesSection.packages.1",
    },
    {
      icon: MenuBookTwoToneIcon,
      title: "pricesPage.packagesSection.packages.2",
    },
    {
      icon: Groups2OutlinedIcon,
      title: "pricesPage.packagesSection.packages.3",
    },
    {
      icon: MailOutlineRoundedIcon,
      title: "pricesPage.packagesSection.packages.4",
    },
    {
      icon: ObjectSvg,
      title: "pricesPage.packagesSection.packages.5",
    },
    {
      icon: CheckRoundedIcon,
      title: "pricesPage.packagesSection.packages.6",
    },
    {
      icon: EmojiObjectsOutlinedIcon,
      title: "pricesPage.packagesSection.packages.7",
    },
    {
      icon: PercentOutlinedIcon,
      title: "pricesPage.packagesSection.packages.8",
    },
  ];

  return (
    <div
      id="basic_package_section"
      className={cn(
        "w-[70%] flex mx-auto py-12 justify-between",
        styles.container
      )}
    >
      <div className={cn("flex", styles.img_container)}>
        <img src={iconListImage} alt=""></img>
      </div>
      <div className={cn("w-[50%]", styles.text_container)}>
        <h4 className={styles.title}>
          {t("pricesPage.packagesSection.title")}
        </h4>

        <div
          className={cn("flex flex-col flex-wrap", styles.packages_container)}
        >
          {packages.map((item, index) => (
            <div className={`flex items-center mt-5 text-[#667085]`}>
              <span
                key={index}
                className={`flex items-center justify-center inline-block h-[45px] w-[45px] bg-[#f6f6f4] rounded-md`}
              >
                <item.icon />
              </span>

              <span className={styles.item_text}>{t(item.title)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PackagesSection;
