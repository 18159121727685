const CheckSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
    >
      <circle cx="20" cy="20.4963" r="20" fill="#114653" />
      <mask
        id="mask0_0_7671"
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="25"
      >
        <rect x="8" y="8.49634" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_0_7671)">
        <path
          d="M17.55 26.4963L11.85 20.7963L13.275 19.3713L17.55 23.6463L26.725 14.4713L28.15 15.8963L17.55 26.4963Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
export default CheckSvg;
