import React, { useRef } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import "./Form.css";
import { FormProvider, useForm } from "react-hook-form";

import ReCAPTCHA from "react-google-recaptcha";
import AppInput from "../../../Components/AppInput";
import AppCheckbox from "../../../Components/AppCheckbox";
import { useReachYourCustomers } from "./useReachYourCustomer";

const ReachYourCustomersSection = ({ t }) => {
  const formMethods = useForm();
  const recaptchaRef = useRef();
  const {
    Loading,
    Message,
    Obiettivi,
    SendEmail,
    formCheckboxes,
    formInputs,
    handleChangeObiettivi,
    handleRecaptchaChange,
  } = useReachYourCustomers(t);

  return (
    <div className="w-full relative bg-gradient-to-r from-[#85E1AE] to-[#00B27A]">
      <div className="w-[78.5%] lg:w-[90%] xmd:w-[95%] md:w-[95%] px-[40px] flex flex-row xsm:w-full xsm:flex-col sm:w-full sm:flex-col md:flex-col xmd:flex-col gap-y-6 gap-x-2  mx-auto py-14 relative xsm:mx-0 sm:mx-0">
        <div className="w-1/3 xsm:w-full sm:w-full md:w-full  xmd:w-full flex flex-col  ">
          <p className="text-white Brevia850 not-italic font-black text-5xl sm:text-3xl leading-[65px] ">
            {t("homePage.ReachYourCustomersSection.title")}
          </p>
        </div>
        <FormProvider {...formMethods}>
          <form
            className=" w-2/3 xsm:w-full sm:w-full md:w-full xmd:w-full gap-x-4 "
            onSubmit={formMethods.handleSubmit((data) => SendEmail(data))}
          >
            <div className=" w-full grid grid-cols-2 xsm:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 gap-x-4">
              {formInputs.map((inputProps) => (
                <AppInput {...inputProps} />
              ))}

              <div className="flex flex-col gap-y-4 pb-9 ">
                <p className="Brevia700 text-xl md:text-lg text-white">
                  {t("homePage.ReachYourCustomersSection.qstion")}
                </p>

                <div className="flex flex-col justify-start items-start">
                  {formCheckboxes.map(
                    (checkbox, index) =>
                      index < 3 && (
                        <AppCheckbox
                          {...checkbox}
                          onChange={(e) => handleChangeObiettivi(e)}
                          value={Obiettivi}
                        />
                      )
                  )}
                  <div className="flex flex-col py-10  xsm:w-[90%] sm:w-[90%]">
                    <div className="flex flex-row">
                      <AppCheckbox
                        {...formCheckboxes[3]}
                        onChange={(e) => handleChangeObiettivi(e)}
                        value={Obiettivi}
                      />
                    </div>

                    <div className="flex flex-row w-[95%] mt-5 mx-auto  my-3 items-center justify-center ">
                      <ReCAPTCHA
                        sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                        ref={recaptchaRef}
                        onChange={handleRecaptchaChange}
                        hl="it"
                        size="normal"
                      />
                    </div>
                    <button
                      type="submit"
                      disabled={Loading}
                      className="pop600 text-xl text-black mx-auto my-10 mb-5 px-10 w-96 xsm:w-full sm:w-full xsm:px-5 sm:px-5 h-16 border-[3px] bg-[#DCFFCF] border-black items-center flex justify-center "
                    >
                      {t("homePage.ReachYourCustomersSection.button")}
                      {Loading && (
                        <CircularProgress
                          size={25}
                          color="success"
                          className="absolute right-0 mr-8"
                        />
                      )}
                    </button>

                    {Message && (
                      <div className=" w-[80%] flex flex-col  ">
                        <p
                          className={`${
                            Message?.type === "Erorr"
                              ? "text-[#FC6371]"
                              : "text-white"
                          } mt-5 pop700 text-xl flex items-center gap-x-2 `}
                        >
                          {" "}
                          {Message?.type === "Erorr" ? (
                            <RiErrorWarningFill size={25} />
                          ) : (
                            <BsFillCheckCircleFill size={25} />
                          )}{" "}
                          {Message?.message}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ReachYourCustomersSection;
