import { useTranslation } from "react-i18next";
import Contact from "../Containers/Contact/Contact";
import HubspotContactForm from "../Containers/Contact/HubspotContactForm";
import ContactNumberBanner from "../Containers/Contact/ContactNumberBanner";
import ScrollToTop from "../Reusable/ScrollToTop";

const PricesPage = () => {
  const { t } = useTranslation();

  return (
    <>
      <ScrollToTop />
      <ContactNumberBanner t={t} />
      <div className="flex flex-row xsm:flex-col sm:flex-col  md:flex-col  xmd:flex-col w-[80%] xmd:w-full mx-auto">
        <Contact t={t} />
        <div className='px-20 py-10 xsm:w-full sm:w-full md:w-full xmd:w-full  w-1/2'>
          <div className='px-8 py-6 border-[10px] border-black '>
            <HubspotContactForm />
          </div>
        </div>
      </div>
    </>
  );
};
export default PricesPage;
