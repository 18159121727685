import React from "react";

import crypto from "../../Assets/Rcvs/Plateform/crypto.svg";
import prenotazionithefork from "../../Assets/Rcvs/TheFork/prenotazionithefork.svg"
import cibothefork from "../..//Assets/Rcvs/TheFork/cibothefork.svg"
import cell from "../../Assets/Rcvs/TheFork/cellthefork.png"
import promo from "../../Assets/Rcvs/TheFork/promoprenotazionithefork.png"
import chefsalta from "../../Assets/Rcvs/TheFork/chefsalta.svg"
import ads from "../../Assets/Rcvs/TheFork/adsthefork.png"


import quattrox from "../../Assets/Rcvs/Plateform/4x.png"
import pack from "../../Assets/Rcvs/Plateform/pack.png"
import { useNavigate } from "react-router-dom";
import SecondButton from "../../Reusable/LandingButton/SecondButton";
import CalendlyButtonGreen from "../../Reusable/LandingButton/CalendlyButton";





const TheFork = () => {

  const navigate = useNavigate()


  return (
    <div className="">
      {/*---------------------------------------------------- Container ---------------------------------------------------*/}



      <div className="w-[80%] mx-auto  flex flex-col  xsm:w-[90%] ">
        
        
        
        {/*-------------------------------------------------- Main Heading ----------------------------------------------- */}

        <div className='flex flex-col items-center justify-center gap-y-10 mt-10 '>
        <h1 className="Brevia850 text-center text-[#114653]">
        Non fare sconti per portare utenti a TheFork. <br/>
        Crea la tua base clienti con Restaurants Club.         </h1>
        <p className="pop500 text-center xl:w-[70%] lg:w-[70%]">
        Niente più cene al 50% per utenti occasionali. Fai affezionare i tuoi clienti ai tuoi prodotti con campagne marketing pensate per loro.
        </p>

        <div className="pb-10">
          <CalendlyButtonGreen url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club" text="Prenota una chiamata"/>
        </div>

        </div>



        {/* Section 2 */}
        <div className='flex flex-col items-center justify-center gap-y-8 '>

          <h3 className="Brevia700 italic	text-[#114653] text-center ">
           Perché scegliere Restaurants Club
            <br className="md:hidden sm:hidden xsm:hidden" /> e non TheFork?
         </h3>

        </div>

        <div className="row flex flex-row xsm:flex-col sm:flex-col  mt-10  justify-center gap-8 md:gap-5">
          <div className="w-1/3 mx-auto md:w-[100%] sm:w-[100%] xsm:w-[90%]  ">
          <div className="w-[60%]  mx-auto flex justify-center ">
            <img src={crypto} alt="" />
            </div>
            <div className="text-center mt-10">
              <h5 className="pop700 mb-2">
              Non pagare tu per il loro successo
              </h5>
              <p className="pop500">
              Niente più commissioni o sconti folli: con il budget marketing incluso attira  più clienti con pubblicità social efficaci
              </p>
            </div>
          </div>

          <div className="w-1/3 mx-auto md:w-[100%] sm:w-[100%] xsm:w-[90%]  ">
          <div className="w-[60%] mx-auto flex justify-center ">
            <img src={prenotazionithefork} alt="-" />
            </div>
            <div className="text-center mt-10">
              <h5 className="pop700 mb-2">
              Ricevi prenotazioni dal tuo sito e app
              </h5>
              <p className="pop500">
              Ricevi prenotazioni tavolo dal sito senza commissioni. Con l’Agenda digitale e la gestione sala hai tutto sotto controllo!              </p>
            </div>
          </div>

          <div className="w-1/3 mx-auto md:w-[100%] sm:w-[100%] xsm:w-[90%]  ">
          <div className="w-[60%] mx-auto flex justify-center ">
            <img src={cibothefork} alt="-" /></div>
            <div className="text-center mt-10">
              <h5 className="pop700 mb-2">
              Falli tornare da te, non sulla loro app
             </h5>
              <p className="pop500">
              Raccogli in automatico informazioni di contatto dei clienti quando prenotano e invitali a ritornare con le tue Newsletter
              </p>
            </div>
          </div>
        </div>

        {/* Section 3 row */}
        <div className="flex mt-32 flex-row gap-6 md:flex-col-reverse sm:flex-col-reverse xsm:flex-col-reverse ">
          <div className="w-6/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center">

            <h3 className="Brevia700 italic mb-5">
            “Ma con TheFork ho molta visibilità: mi serve per farmi conoscere”
            </h3>

            <p className="pop500  mt-5 ">
            La visibilità che ottieni non è tua ma della piattaforma. TheFork guadagna in base a quanti utenti prenotano, <span className='pop700'>indipendentemente </span>dal ristorante: per questo ti spinge a fare sconti folli: ti porta qualche cliente iniziale, ma tu non ci guadagni nulla. <span className='pop700'>E se togli gli sconti? </span>Pochissimi ritornano.
            </p>

            <p className="pop500  mt-5 mb-10  ">
            I clienti non si ricordano di te ma di TheFork. Fai in modo che i clienti ti scelgano e vengano da te perché riconoscono il valore del tuo locale e dei tuoi piatti. <span className='pop700'>Diventa il primo nelle ricerche</span>.            
            </p>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center ">
              <SecondButton onClick={() => navigate("/scopri-i-tuoi-clienti")} text="Raggiungi i tuoi clienti" />
            </div>

          </div>

          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full  content-center flex mx-auto  ">
            <img src={cell} alt="" className=" mx-auto xmd:h-[50%] self-center flex " />
          </div>
        </div>

        {/* Section 4 Row Reverse*/}
        <div className="flex mt-32 flex-row gap-6 md:flex-col sm:flex-col xsm:flex-col ">
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full content-center grid ">
            <img src={promo} alt="-" className=" mx-auto  self-center flex "/>
          </div>
          <div className="w-6/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center  xsm:text-center sm:text-center xmd:ml-8  lg:ml-8 xl:ml-14">
            <h3 className="Brevia700 italic mb-5 ">
            “Tanto alla fine la gente prenota online ma non viene” 
            </h3>
            <p className="pop500  mt-5   ">
            Il <span className='pop700'>No-Show </span>è uno tra i più grandi problemi che ogni ristoratore riscontra. Con il modulo Prenotazioni di Restaurants Club hai Mail automatiche per ricordare ai tuoi clienti le loro prenotazioni a poche ore dall’appuntamento in modo che non se ne scordino.             </p>

            <p className="pop500 mt-4 mb-4 ">
            Inoltre, con la <span className='pop700'>comanda anticipata </span>i tuoi clienti fedeli possono scegliere e pagare in anticipo il loro piatto preferito, evitando lunghe attese. Rimanere a contatto con i tuoi clienti, ancora prima che arrivino nel tuo locale: non è mai stato così facile.            </p>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center ">

              <SecondButton onClick={() => navigate("/tavola")} text="Scopri di più"/>

            </div>

          </div>
        </div>
      </div>

      <div className="px-20 mt-10 md:p-0 sm:p-0 xsm:p-0  bg-[#E5FFDD]">
        <div className="container m-auto p-20 md:w-[100%] md:p-12 md:max-w-none md:text-center xsm:w-[100%] xsm:p-12 xsm:max-w-none xsm:text-center sm:w-[100%] sm:p-12 sm:max-w-none sm:text-center">
          <h6 className="text-[#00B17A] Brevia850">
            “Ora è molto più semplice prenotare per i clienti. Le persone riconoscono il mio locale, vedono l'insegna e chiedono subito un tavolo.”
          </h6>
          <p className="pop500 text-lg mt-5">Daniele, titolare del Ristorante “Il Canguro” a Pisa</p>
        </div>
      </div>

      {/* Container 2 */}
      <div className="w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] mx-auto  flex flex-col ">
        {/* Section 4 row */}
        <div className="flex mt-20 flex-row gap-10 md:flex-col-reverse sm:flex-col-reverse xsm:flex-col-reverse ">
          <div className="w-6/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center">

            <h3 className="Brevia700 italic  mb-5 ">
            “Devo togliere The Fork per fare Restaurants Club? Voi cosa mi garantite?”
            </h3>

            <p className="pop500 my-3 ">
            No! Puoi attivare Restaurants Club mantenendo il tuo profilo su TheFork. Noi ti garantiamo risultati veri e misurabili nell’incremento di visibilità e clienti verso il tuo locale. 
            </p>

            <p className="pop500   my-3 ">
            Sappiamo che con Restaurants Club avrai una crescita forte e stabile. Sarai poi tu, in autonomia, a confrontare e decidere se è conveniente mantenere un abbonamento ad una piattaforma che dimezza il valore del tuo lavoro.
            </p>


            <div className="flex xsm:justify-center sm:justify-center md:justify-center py-10">
              <SecondButton onClick={() => navigate("/register")} text="Attivalo ora" />
            </div>

          </div>
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full  content-center flex">
            <img src={chefsalta} alt="" className=" mx-auto self-center flex " />
          </div>
        </div>

        {/* Section 5 Row Reverse*/}
        <div className="flex mt-20 flex-row gap-6 md:flex-col sm:flex-col   xsm:flex-col ">
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full content-center flex ">
            <img src={quattrox} alt="-" className=" mx-auto self-center flex " />
          </div>
          <div className="w-6/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center ">
            <h3 className="Brevia700  mb-5 ">
            “A cosa mi servono i contatti dei clienti?” 
            </h3>
            <p className="pop500 ">
            I contatti dei tuoi clienti sono lo strumento fondamentale per far si che <span className='pop700 '>clienti occasionali</span> si trasformino in<span className='pop700 '> clienti fedeli</span>. Coccolali con promozioni <span className='pop700 '>speciali </span>a loro dedicati e tienili aggiornati sulle tue ultime novità via <span className='pop700 '>mail</span>.</p>
            <p className="pop500 ">
            Con Restaurans Club, inoltre, ti aiutiamo a scoprire quali sono i tuoi “<span className='pop700 '>clienti-tipo</span>” e i tuoi “<span className='pop700 '>clienti potenziali</span>” e creare campagne marketing indirizzate a loro.
            </p>
          

            <div className="flex xsm:justify-center sm:justify-center md:justify-center  my-5">
              <SecondButton onClick={() => navigate("/scopri-i-tuoi-clienti")} text="Scopri di più sulla funzione “Cliente-tipo”" />
            </div>

          </div>
        </div>

                {/* Section 6 row */}
                <div className="flex mt-20 flex-row gap-6 md:flex-col-reverse sm:flex-col-reverse  xsm:flex-col-reverse  ">
          <div className="w-6/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center ">
            <h3 className="Brevia700 mb-5 ">            
            “Ma i clienti mi dicono che anche The Fork fa pubblicità sui social in cui c’è il mio ristorante”
            </h3>
            <p className="pop500 ">
            Fare pubblicità per il tuo ristorante attraverso i tuoi profili social, invece di affidarsi a quelli di piattaforme intermediarie come The Fork, ti permette di <span className='pop700'>catturare l’attenzione </span>dei tuoi clienti-tipo con le tue pubblicità e:
            </p>
            <ul className="pop500 ">
                <li>
                spingerli a visitare i tuoi profili social e non a quelli di The Fork, così che più potenziali clienti <span className='pop700'>si ricordino di te</span>
                </li>
                <li>
                indirizzarli a compiere l’azione che TU vuoi: vedere il tuo menù, prenotare un tavolo oppure fare asporto o delivery
                </li>
            </ul>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center  pt-10">
              <SecondButton onClick={() => navigate("/funzioni")} text="Scopri di più" />
            </div>

          </div>
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full  content-center flex">
            <img src={ads} alt="" className=" mx-auto self-center flex " />
          </div>
        </div>
      </div>

      <div className="px-10 mt-10 md:p-0 sm:p-0 xsm:p-0  bg-[#E5FFDD]">
        <div className="container m-auto p-20 md:w-[100%] md:p-12 md:max-w-none md:text-center sm:w-[100%] sm:p-12 sm:max-w-none sm:text-center xsm:w-[100%] xsm:p-12 xsm:max-w-none xsm:text-center">
          <h6 className="text-[#00B17A] Brevia850">
          “Siamo cresciuti con i like sulle pagine social e ci stanno contattando davvero tante persone. 
          Dire che sono soddisfatto è poco.”
          </h6>
          <p className="pop500  mt-5">Francesco, titolare del Ristorante “Il Piatto Pronto” di Bari</p>
        </div>
      </div>

      {/* Container 3 */}
      <div class="w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] mx-auto  flex flex-col ">
        <div className="py-10 md:py-24 sm:py-20 xsm:py-20">
          <h3 className="Brevia850 text-center 	text-[#114653] mb-4 ">
            Hai ancora dei dubbi su Restaurants Club?
          </h3>
          <p className="pop400 pb-8 text-center  ">
            Prenota una chiamata con un nostro esperto per scoprire come
            Restaurants Club<br className="md:hidden sm:hidden xsm:hidden" /> può aiutare il tuo ristorante. Saremo felici di
            rispondere alle tue domande.
          </p>
          <div className='flex flex-col items-center justify-center gap-y-20'>
          <CalendlyButtonGreen url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club" text="Prenota una chiamata"/>
            </div>
        </div>
      </div>
      
    </div>
  );
};


export default TheFork;
