import React from "react";
// import imgLayout from "./images/img-layout.png";
import clientitipo from "../../Assets/clientitipo.png"
import clientitipomobile from "../../Assets/clientitipomobile.png"
import searchcli from "../../Assets/searchcli.png"
import pizzeriatoni from "../../Assets/pizzeriatoni.png"
import piadineria from "../../Assets/piadineria.png"
import trattoria from "../../Assets/trattoria.png"
import blobmarta from "../../Assets/blobmarta.png"
import vign1 from "../../Assets/vign1.png"
import vign2 from "../../Assets/vign2.png"
import vign3 from "../../Assets/vign3.png"
import vign4 from "../../Assets/vign4.png"
import vign5 from "../../Assets/vign5.png"
import vign6 from "../../Assets/vign6.png"
import bottone from "../../Assets/bottone.png"
import ThirdButton from "../../Reusable/LandingButton/ThirdButton"
import pricing from "../../Assets/PricingImage.png"
import { useNavigate } from "react-router-dom";
import CalendlyButtonGreen from "../../Reusable/LandingButton/CalendlyButton";





const Scopriituoiclienti = () => {

  const navigate = useNavigate()


  return (
    <div className="bg-[#F6F6F4]">

     {/*Hero section*/}
      <div className="flex flex-col items-center justify-center gap-y-10 pt-20 w-[80%]  mx-auto">
        <h1 className="Brevia700 italic text-center text-[#114653]">
          Chi è il Cliente Tipo?
        </h1>

        <p className="pop500 text-center  xmd:w-[60%] lg:w-[60%] xl:w-[60%]">
          Imparare a riconoscere il proprio Cliente Tipo, o Buyer Persona in inglese, è uno strumento fondamentale per impostare le tue campagne marketing in modo efficace. 
          Ti aiuterà a capire come sponsorizzare e raccontare il tuo ristorante in modo vincente.    
        </p>

        <img  src={clientitipo} alt="_" className="xsm:hidden sm:hidden"/>
        <img  src={clientitipomobile} alt="_" className="md:hidden xmd:hidden lg:hidden xl:hidden"/>
      </div>

      {/*Cos'è*/}
      <div className="flex pb-10 mt-20 flex-row gap-6 md:flex-col xsm:flex-col sm:flex-col w-[80%] items-center justify-center mx-auto ">
        <div className="w-7/12 xsm:w-full sm:w-full md:w-full xmd:w-full md:text-center xsm:text-center sm:text-center">

         <h3 className="Brevia700 italic mb-5">
           Cos’è un Cliente Tipo?
        </h3>

         <p className="pop500 pr-40 mt-5 md:pr-0 sm:pr-0 xsm:pr-0  sm:text-base">
           È la rappresentazione di un acquirente-tipo del tuo ristorante: rappresenta quella categoria di persone attratte dalla tua offerta, 
            che meglio si allineano con la tua proposta non solo a livello di menù ma anche di ambiente del locale. 
        </p>

         <p className="pop500 pr-40 mt-5 mb-10 md:pr-0 sm:pr-0 xsm:pr-0 sm:text-base">
          In pratica, avrai come un grande “megafono digitale” con cui <span className='pop700'>farti conoscere </span>da tutti: e non serve essre un esperto! 
           Il modulo <span className='pop700'>pubblicità </span> è facile, intuitivo e non ci sono costi aggiuntivi nella pubblicità perché il credito pubblicitario è incluso nel tuo abbonamento!
         </p>
         </div>
          <div className=" flex flex-col items-center justify-center w-3/12 xsm:w-[80%] sm:w-[80%]  md:w-[80%] ">
            <img src={searchcli} alt=""/>
          </div>
      </div>

      {/*Qualche esempio - full*/}
      <div className="flex items-center justify-center bg-white ">
     
       <div className="w-[80%]">
          {/*Heading + Paragrafo*/}
          <div className="flex flex-col items-center justify-center gap-y-10 pt-10">
            <h3 className="Brevia700 italic text-center text-[#114653]">
              Vediamo qualche esempio
            </h3>
            <p className="pop500 text-center  xmd:w-[60%] lg:w-[60%] xl:w-[60%]">
            Ecco alcuni esempi che ti possono chiarire praticamente il concetto di Cliente Tipo: 
            il cliente tipo è influenzato da fattori come la posizione, lo scontrino medio e la tipologia di Menù
            </p>
          </div>

          {/*3 block full box*/}
        <div className="row flex xsm:flex-wrap sm:flex-wrap md:flex-wrap pt-10 pb-20 ">
            
          {/*1/3 block*/}
          <div className="w-1/3 mx-5 md:w-[100%] xsm:w-[100%] sm:w-[100%] md:mt-20 md:mx-0 sm:mt-10 xsm:mt-10 sm:mx-0 xsm:mx-0">
            <img src={pizzeriatoni} alt="-" />

            <div className="text-center mt-10">
              <h5 className="pop700 mb-2">
              Pizzeria Toni
              </h5>

              <p className="pop500">
                Il cliente tipo della Pizzeria Toni, che si trova sotto il Colosseo a Roma, è <span className='pop700'>IL TURISTA</span>
              </p>
              </div>
          </div>

          {/*2/3 block*/}
          <div className="w-1/3 mx-5 md:w-[100%] sm:w-[100%] xsm:w-[100%] md:mt-20 md:mx-0 xsm:mt-10 xsm:mx-0 sm:mt-10 sm:mx-0 ">
            <img src={piadineria} alt="-" />

            <div className="text-center mt-10">
              <h5 className="pop700 mb-2">
                La Piadineria
              </h5>

              <p className="pop500">
                Il cliente tipo della Piadineria, che si trova di fronte all’università, è <span className='pop700'>LO STUDENTE</span>
             </p>
            </div>
          </div>

          {/*3/3 block*/}
          <div className="w-1/3 mx-5 md:w-[100%] xsm:w-[100%] sm:w-[100%] md:mt-20 md:mx-0 xsm:mt-10 xsm:mx-0 sm:mt-10 sm:mx-0 ">
            <img src={trattoria} alt="-" />

            <div className="text-center mt-10">
              <h5 className="pop700 mb-2">
                La Trattoria
              </h5>

              <p className="pop500">
                Il cliente tipo della Trattoria nelle campagne dell’astigiano è <span className='pop700'>LA FAMIGLIA</span> con bambini
              </p>
              </div>
          </div>
        </div>

        </div>
       </div>
          
       {/*La storia di Marta*/}
       <div className="flex items-center justify-center py-5 ">
          <div className="flex w-[80%] pt-15 flex-row gap-6 md:flex-col sm:flex-col xsm:flex-col ">
            <div className="flex w-6/12 xsm:w-full sm:w-full justify-center  ">
             <img src={blobmarta} alt="-" className="w-[50%]" />
            </div>

            <div className="w-6/12 pt-5 xsm:w-full sm:w-full md:w-full xmd:w-full md:text-center sm:text-center xsm:text-center" >
              <h3 className="Brevia700 italic">
                Perché è importante?
              </h3>

              <h3 className="pop700 pb-5">
                La storia di Marta
              </h3>

              <p>
                Marta è la nostra Consulente Marketing ed ci racconta di quella volta che ha provato a spiegare a suo padre, 
                titolare di una storica Paninoteca, cosa sia il Cliente tipo e perchè possa essere utile all’attività
              </p>
            </div>
           </div>
        </div>

        {/*Vignette*/}
        <div className="flex items-center justify-center py-5 bg-white ">
          <div className="flex w-[80%] pt-15 flex-row gap-6 md:flex-col sm:flex-col xsm:flex-col ">
           <div className=" justify-center w-5/12 xsm:w-full sm:w-full md:w-full grid ">
            <img src={vign1} alt="-" className="w-[196] h-[157px]"/>
           </div>

          <div className="w-5/12 pt-5 xsm:w-full sm:w-full md:w-full xmd:w-full md:text-center sm:text-center xsm:text-center" >
           <p>
             Papà, qui la cosa è semplice: immagina di voler far sapere a tutti quelli che si trovano nella Piazza Grande che da domani hai deciso di fare una nuova promozione per gli studenti, ad esempio panino e bibita a 6€.
            </p>
           </div>
          </div>
        </div>

        <div className="flex items-center justify-center py-5 bg-[#F6F6F4] ">
          <div className="flex w-[80%] pt-15 flex-row gap-6 md:flex-col sm:flex-col xsm:flex-col ">
           <div className="justify-center w-5/12 xsm:w-full sm:w-full xsm:w-full md:w-full content-center grid ">
            <img src={vign2} alt="-" className="w-[196] h-[157px]"/>
           </div>

          <div className="w-5/12 pt-5 xsm:w-full sm:w-full md:w-full xmd:w-full md:text-center sm:text-center xsm:text-center" >
           <p>
             Potremmo cominciare con una foto sui social e investire un po’ di credito pubblicitario per sponsorizzarlo e far sapere a tutti gli studenti della tua promozione.
           </p>
           </div>
          </div>
        </div>

        <div className="flex items-center justify-center py-5 bg-white">
          <div className="flex w-[80%] pt-15 flex-row gap-6 md:flex-col xsm:flex-col sm:flex-col  ">
           <div className="justify-center w-5/12 xsm:w-full sm:w-full md:w-full content-center grid ">
            <img src={vign3} alt="-" className="w-[196] h-[157px]"/>
           </div>

          <div className="w-5/12 pt-5 xsm:w-full sm:w-full md:w-full xmd:w-full md:text-center xsm:text-center sm:text-center" >
           <p>
             Naturalmente però, a te non interessa che le famiglie o i lavoratori siano informati su questa promozione, perché appunto è destinata agli studenti.
          </p>
           </div>
          </div>
        </div>

        <div className="flex items-center justify-center py-5 bg-[#F6F6F4] ">
          <div className="flex w-[80%] pt-15 flex-row gap-6 md:flex-col sm:flex-col xsm:flex-col ">
           <div className="justify-center w-5/12 xsm:w-full sm:w-full md:w-full content-center grid ">
            <img src={vign4} alt="-" className="w-[196] h-[157px]"/>
           </div>

          <div className="w-5/12 pt-5 xsm:w-full sm:w-full md:w-full xmd:w-full md:text-center sm:text-center xsm:text-center" >
           <p>
            Ora nella piazza ci sono 100 persone, ma solo 50 di queste sono degli studenti. Gli altri sono famiglie, lavoratori, pensionati, ecc. 
           </p>
           </div>
          </div>
        </div>

        <div className="flex items-center justify-center py-5 bg-white">
          <div className="flex w-[80%] pt-15 flex-row gap-6 md:flex-col xsm:flex-col sm:flex-col ">
           <div className="justify-center w-5/12 xsm:w-full sm:w-full md:w-full content-center grid ">
            <img src={vign5} alt="-" className="w-[196] h-[157px]"/>
           </div>

          <div className="w-5/12 pt-5 xsm:w-full sm:w-full md:w-full xmd:w-full md:text-center xsm:text-center sm:text-center" >
           <p>
            Quando crei una pubblicità con Restaurants Club, ti viene impostato in automatico il tuo Cliente Tipo ideale, in modo da comunicare al meglio quello che vuoi dire (la promozione) solo a chi lo vuoi dire (gli studenti).
           </p>
           </div>
          </div>
        </div>

        <div className="flex items-center justify-center py-5 bg-[#F6F6F4] ">
          <div className="flex w-[80%] pt-15 flex-row gap-6 md:flex-col sm:flex-col xsm:flex-col">
           <div className="justify-center w-5/12 xsm:w-full sm:w-full md:w-full content-center grid ">
            <img src={vign6} alt="-" className="w-[196] h-[157px]"/>
           </div>

          <div className="w-5/12 pt-5 xsm:w-full sm:w-full md:w-full xmd:w-full md:text-center sm:text-center xsm:text-center" >
           <p>
            Questo vuol dire che il credito pubblicitario che sceglierai di investire in questa pubblicità sarà destinato soltanto a far vedere la promozione a quei 50 studenti presenti nella piazza e non a tutti e 100 i presenti: non viene sprecato neanche un centesimo! 
           </p>
           </div>
          </div>
        </div>

        {/*Dove trovarlo*/}
       <div className="flex items-center justify-center py-20 bg-white  ">
          <div className="flex w-[80%] flex-row gap-6 md:flex-col sm:flex-col xsm:flex-col">
            <div className="flex w-6/12 xsm:w-full sm:w-full md:w-full justify-center items-center ">
             <img src={bottone} alt="-" className="w-[50%] h-[50%] xmd:w-[75%] xsm:w-full xsm:h-full sm:w-full sm:h-full" />
            </div>

            <div className="w-6/12 pt-5 xsm:w-full sm:w-full md:w-full xmd:w-full md:text-center sm:text-center xsm:text-center" >
              <h3 className="Brevia700 italic">
                Dove lo posso trovare?
              </h3>

              <p>
                Potrai sempre visualizzare il tuo cliente tipo Primario e Secondario nel modulo “Cliente Tipo” 
                della nostra piattaforma e li ritroverai anche nel processo di creazione della campagna pubblicitaria.
              </p>
            </div>
           </div>
        </div>


        <div className='flex flex-row sm:flex-col xsm:flex-col w-[78.5%] lg:w-[95%] px-[40px] sm:w-full xsm:w-full xmd:w-[98%] md:w-[98%] mx-auto my-10 py-10'>
          <div className='w-1/2 md:w-2/3 xsm:w-full sm:w-full text-left flex flex-col sm:p-5 xsm:p-5 py-20 xmd:py-10 md:py-5 gap-y-6'>
            <h3 className="Brevia700 italic text-[#00B27A] ">
            Hai bisogno di un consiglio? <br className="md:hidden sm:hidden" />Parla con un nostro esperto
            </h3>
            <h4 className="Brevia700 italic">
            Il nostro supporto clienti
            </h4>
            <div className='flex flex-row gap-x-4 w-full'>
            <div className='w-5 h-5 md:h-4 md:w-4  md:mt-2 xsm:mt-2 xsm:h-3 xsm:w-3 sm:mt-2 sm:h-3 sm:w-3 rounded-full bg-[#FC6371]' />
               <p className='pop500 text-black w-[90%]'><span className='pop700'>Non sai da dove cominciare? </span>
                  Niente paura! Un nostro esperto di marketing studierà per te un piano efficace con cui raggiungere i tuoi obiettivi.
               </p>
             </div>
             <div className='flex flex-row gap-x-4 w-full'>
              <div className='w-5 h-5 md:h-4 md:w-4  md:mt-2 sm:mt-2 sm:h-3 sm:w-3 xsm:mt-2 xsm:h-3 xsm:w-3 rounded-full bg-[#FC6371]' />
                <p className='pop500  text-black w-[90%]'>
                <span className='pop700'>Siamo sempre con te. </span> Il nostro team di assistenza è a tua disposizione 
                per assicurarti di raggiungere i tuoi obiettivi con la piattaforma Restaurants Club.
                </p>
                </div>
               <div className="pb-10 ">
                <CalendlyButtonGreen url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club" text="Chiama il team di assistenza"/>
                <ThirdButton onClick={() => navigate("/contatti")} text="Oppure ricevi un consiglio da un nostro esperto"/>
               </div>
          </div>
          <div className='w-1/2 md:w-1/3 sm:w-full xsm:w-full flex items-center justify-end'>
                    <img src={pricing} alt="hero" className='h-[450px] md:h-[250px] sm:h-[250px] xmd:h-[350px] xsm:h-[250px]' />
                </div>
        </div>

    </div>
  );
};


export default Scopriituoiclienti;
