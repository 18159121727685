import React from "react";
import planForYouImg from "../img/Group 1000002147.png";
import CheckRoundedIcon from "./icons/CheckSvg";
import EuroSymbolRoundedIcon from "./icons/EuroSvg";
import PanToolAltOutlinedIcon from "./icons/FingerSvg";
import styles from "./PlanForYou.module.css";
import cn from "classnames";

const PlanForYou = ({ t }) => {
  const icons = [
    {
      icon: PanToolAltOutlinedIcon,
      text: t("pricesPage.planForYou.items.firstText"),
    },
    {
      icon: EuroSymbolRoundedIcon,
      text: t("pricesPage.planForYou.items.secondText"),
    },
    {
      icon: CheckRoundedIcon,
      text: t("pricesPage.planForYou.items.thirdText"),
    },
  ];
  return (
    <div
      className={cn("flex flex-wrap w-[70%] mx-auto pl-12", styles.container)}
    >
      <div className={cn("w-[60%] pt-12", styles.text_container)}>
        <div>
          <h3 className={cn("Brevia700 text-rcgreen", styles.title)}>
            {t("pricesPage.planForYou.title")}
          </h3>
          <p className="font-light">{t("pricesPage.planForYou.des1")}</p>
          <p className="font-light">{t("pricesPage.planForYou.des2")}</p>
        </div>

        <div className={styles.icons_section}>
          {icons.map((item, index) => (
            <div key={index} className="mt-3 flex flex-row items-center">
              <span className="bg-rcblue w-[30px] h-[30px] rounded-full inline-block text-white mr-4 flex items-center justify-center">
                <item.icon />
              </span>
              <span className="pop600 text-rcblue">{t(item.text)}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={cn("w-[40%] flex justify-center", styles.img_container)}>
        <img src={planForYouImg} alt=""></img>
      </div>
    </div>
  );
};

export default PlanForYou;
