import * as Sentry from "@sentry/browser";
import { Dedupe as DedupeIntegration } from "@sentry/integrations";

class LoggerService {
    // This prop should be readonly
    appName = "[rc-landing-front]";
    /**
     * Initialize the object and also freeze it in order to do not re instanciate it on each
     * import.
     *
     * The following booleans must be removed once we move everything to sentry. From now on 
     * the use of console is deprecated use logger instead. 
     *  onerror: true,
     *  onunhandledrejection: true,
     *  console: true,
     *  fetch: true,
     *  xhr: true,
     * 
     * @see https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/default
     *
     * @returns void
     */
    init() {
        const { appName } = this;
        const { REACT_APP_LOGGER_URL } = process.env; 
        Sentry.configureScope(function (scope) {
            scope.setTag("app", appName);
        });
        Sentry.init({
            dsn:REACT_APP_LOGGER_URL,
            // Find a way to do not harcode the tag version
            release: `${appName}@v0.5.9`,
            integrations: [
                new Sentry.Replay(),
                new DedupeIntegration()
            ],
            // Performance Monitoring
            tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
            // Session Replay
            replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });

        // The following line is the one that make the object a single instance
        Object.freeze(logger);
    }

    info(message, additionalData) {
        if ("test" === process.env.REACT_APP_NODE_ENV) {
            console.info(message, {
                level: "info",
                extra: { "additional-data": additionalData },
            });
        }
        Sentry.captureMessage(message, {
            level: "info",
            extra: { "additional-data": additionalData },
        });
    }
    error(message, additionalData) {
        if ("test" === process.env.REACT_APP_NODE_ENV) {
            console.error(message, {
                level: "error",
                extra: { "additional-data": additionalData },
            });

            return;
        }
        Sentry.captureMessage(message, {
            level: "error",
            extra: { "additional-data": additionalData },
        });
    }
    debug(message, additionalData) {
        if ("prod" === process.env.REACT_APP_NODE_ENV) return;
        if ("test" === process.env.REACT_APP_NODE_ENV) {
            console.debug(message, {
                level: "error",
                extra: { "additional-data": additionalData },
            });

            return;
        }
        Sentry.captureMessage(message, {
            level: "debug",
            extra: { "additional-data": additionalData },
        });
    }
    warn(message, additionalData) {
        if ("test" === process.env.REACT_APP_NODE_ENV) {
            console.warn(message, {
                level: "error",
                extra: { "additional-data": additionalData },
            });

            return;
        }
        Sentry.captureMessage(message, {
            level: "warning",
            extra: { "additional-data": additionalData },
        });
    }
}

/**
 * I instanciete the logger as a singleton in order to do not recreate
 * the object each time see the init method
 */
export const logger = new LoggerService();
