import React from "react";
import BackArrow from '../../Assets/BackArrow.svg';
import './Button.css';

const Button = ( { onClick } ) => {

  return (

    <div className="flex w-full pb-5 pl-5">

      <div className="flex flex-row w-28 mt-4">

        <button onClick={onClick} className="backBtn bg-[#00B27A] hover:bg-[#000000] w-full"><img src={BackArrow} alt="Back Arrow" className="mr-4"/> Indietro </button>

      </div>
            
    </div>

  )
}

export default Button