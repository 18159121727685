import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { logger } from '../../Services/Logger';

function HubspotContactForm() {
  
    return (

      <HubspotForm

        portalId='26462757'
        formId='355e6bb0-b3af-4054-b54e-067259ae17af'
        onSubmit={() => logger.info('Submit landing hubspot form!')}
        onReady={(form) => logger.debug('Form landing hubspot ready!')}
        loading={<div>Loading...</div>}

      />
        
    )
}

export default HubspotContactForm