import React from 'react';
import HubspotForm from 'react-hubspot-form';
import { logger } from '../../Services/Logger';

function HubspotFiereForm() {

  return (
    <HubspotForm
      portalId='26462757'
      formId='98e6de34-21b5-44a6-a336-9b650bd260c3'
      onSubmit={() => logger.info('Fiere form submit!')}
      onReady={(form) => logger.debug('Fiere form ready!')}
      loading={<div>Caricamento...</div>}
    />
  )
}

export default HubspotFiereForm