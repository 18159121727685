const RestaurantStoryElement = ({ img, title, description }) => (
  <div className="w-1/3 xsm:w-full sm:w-full md:w-full xmd:w-full flex flex-col items-center gap-y-2 ">
    <div className=" w-full flex items-center justify-center">
      <img src={img} className="pt-20 pb-10" alt="" />
    </div>
    <p className="text-[#00B27A] Brevia850 text-6xl md:text-3xl">{title}</p>
    <p className="text-black pop500 text-base  text-center mt-5 w-[83%] md:w-[90%]">
      {description}
    </p>
  </div>
);
export default RestaurantStoryElement;
