import React from "react";

import { ScrollToAnimation } from "../ScrollToAnimation";

const FirstButtonScrollTo = ({ toId, toRef, duration, text }) => {
  const handleClick = () => ScrollToAnimation({ id: toId, ref: toRef, duration });

  return (
  <button onClick={handleClick} className="w-fit h-max px-12 py-3 xsm:px-6 xsm:py-4 sm:px-6 sm:py:4 pop600 bg-[#DCFFCF] border-[3px] border-black"> {text} </button>
  )
};

export default FirstButtonScrollTo;


