import React from "react";

const ThirdButton = ( { onClick, text } ) => {

  return (

    <div className="flex w-fit h-max xsm:w-full">

      <button onClick={onClick} className="w-fit h-max  py-5  xsm:py-4  sm:py:4 pop600 underline"> {text} </button>
            
    </div>

  )
}

export default ThirdButton



