const PenSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <mask
        id="mask0_0_7633"
        maskUnits="userSpaceOnUse"
        x="-1"
        y="0"
        width="25"
        height="25"
      >
        <rect
          x="-0.00012207"
          y="0.629639"
          width="24"
          height="24"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_0_7633)">
        <path
          d="M12.4749 17.4546L20.8499 9.07964L19.5499 7.77964L11.1749 16.1546L12.4749 17.4546ZM2.99988 15.9046C2.99988 16.388 3.16654 16.763 3.49988 17.0296C3.83321 17.2963 4.38321 17.4713 5.14988 17.5546C5.41654 17.588 5.62904 17.7088 5.78738 17.9171C5.94571 18.1255 6.01654 18.363 5.99988 18.6296C5.98321 18.913 5.88321 19.1463 5.69988 19.3296C5.51654 19.513 5.29154 19.588 5.02488 19.5546C3.67488 19.388 2.66654 19.0005 1.99988 18.3921C1.33321 17.7838 0.999878 16.9546 0.999878 15.9046C0.999878 14.8213 1.44571 13.9421 2.33738 13.2671C3.22904 12.5921 4.46654 12.188 6.04988 12.0546C6.69988 12.0046 7.18738 11.9005 7.51238 11.7421C7.83738 11.5838 7.99988 11.363 7.99988 11.0796C7.99988 10.713 7.82488 10.4255 7.47488 10.2171C7.12488 10.0088 6.54988 9.84631 5.74988 9.72964C5.48321 9.69631 5.27071 9.57131 5.11238 9.35464C4.95404 9.13797 4.89154 8.89631 4.92488 8.62964C4.95821 8.34631 5.07488 8.11714 5.27488 7.94214C5.47488 7.76714 5.70821 7.69631 5.97488 7.72964C7.35821 7.92964 8.37488 8.30047 9.02488 8.84214C9.67488 9.38381 9.99988 10.1296 9.99988 11.0796C9.99988 11.963 9.67904 12.6546 9.03738 13.1546C8.39571 13.6546 7.44988 13.9546 6.19988 14.0546C5.13321 14.138 4.33321 14.3338 3.79988 14.6421C3.26654 14.9505 2.99988 15.3713 2.99988 15.9046ZM12.9499 19.8046L8.82488 15.6796L18.3749 6.12964C18.7082 5.79631 19.104 5.62964 19.5624 5.62964C20.0207 5.62964 20.4165 5.79631 20.7499 6.12964L22.4999 7.87964C22.8332 8.21297 22.9999 8.60881 22.9999 9.06714C22.9999 9.52547 22.8332 9.92131 22.4999 10.2546L12.9499 19.8046ZM8.97488 20.6296C8.69154 20.6963 8.44154 20.6213 8.22488 20.4046C8.00821 20.188 7.93321 19.938 7.99988 19.6546L8.82488 15.6796L12.9499 19.8046L8.97488 20.6296Z"
          fill="#475467"
        />
      </g>
    </svg>
  );
};
export default PenSvg;
