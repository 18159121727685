import cn from "classnames";
import { PopupButton } from "react-calendly";
import { Link } from "react-router-dom";
const Block = ({ title, links, className, calendlyItems }) => (
  <div className={cn("flex flex-col gap-3", className)}>
    <p className="text-white pop700 text-2xl md:text-xl">{title}</p>
    {links.map((link) =>
      link?.external ? (
        <a
          href={link?.url}
          target="_blank"
          rel="noreferrer"
          className={link.className || "text-white pop500 text-sm flex"}
        >
          <span>{link?.icon}</span>
          <span style={{ paddingLeft: link?.icon ? 10 : 0 }}>{link.title}</span>
        </a>
      ) : (
        <Link
          className="text-white pop500 text-sm md:text-sm text-left"
          to={link.url}
        >
          {link.title}
        </Link>
      )
    )}

    {calendlyItems?.map((item) => (
      <PopupButton
        url={process.env.REACT_APP_CALENDLY_ENDPOINT}
        rootElement={document.getElementById("root")}
        text={item}
        className="text-white pop500 text-sm md:text-sm text-left"
      />
    ))}
  </div>
);
export default Block;
