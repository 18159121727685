import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import LocalDiningOutlinedIcon from "@mui/icons-material/LocalDiningOutlined";
const products = [
  {
    title: "nav.product.comparePlans.title",
    description: "nav.product.comparePlans.description",
    icon: <GridViewOutlinedIcon />,
    bgColor: "rcgray",
    iconColor: "rcblue",
    link: "/funzioni",
  },
  {
    title: "nav.product.espressoPackage.title",
    description: "nav.product.espressoPackage.description",
    icon: <CampaignOutlinedIcon />,
    bgColor: "rcgreen",
    iconColor: "white",
    link: "/espresso",
  },
  {
    title: "nav.product.wayfarerPackage.title",
    description: "nav.product.wayfarerPackage.description",
    icon: <DeliveryDiningOutlinedIcon />,
    bgColor: "rcchromaticgreen",
    iconColor: "rcblue",
    link: "/viandante",
  },
  {
    title: "nav.product.packageAtTheTable.title",
    description: "nav.product.packageAtTheTable.description",
    icon: <LocalDiningOutlinedIcon />,
    bgColor: "rcchromaticblue",
    iconColor: "white",
    link: "/tavola",
  },
];
export default products;
