import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import ErrorBG from "../../Assets/ErrorBG.svg";
import ConfirmModal from "./ConfirmModal";
import ScrollToTop from "../../Reusable/ScrollToTop";

import axios from "axios";
import { baseUrl } from "../../utils/config";
import { logger } from "../../Services/Logger";

const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

function ForgotPassword() {
  const navigate = useNavigate();

  // form states
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // flag to disable submit button if we are loading or if email field is empty
  const disableSubmit = isLoading || !email;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email.match(emailRegex)) return setError("Email invalido.");

    setError("");
    setIsLoading(true);

    try {
      const data = { email };

      const result = await axios.patch(
        `https://${process.env.REACT_APP_AUTH_PREFIX}${baseUrl()}${
          process.env.REACT_APP_AUTH_API_PATH
        }/user/passwordRecovery`,
        data
      );

      if (result.status !== 200) throw new Error();

      openConfirmModal();
      setEmail("");
    } catch (error) {
      logger.error("Error on forgot password",error);

      if (error.response.status === 404 && error.response.data === "")
        setError("Email non trovato.");
      else
        setError(
          error?.response?.data?.Error ||
            "C'è stato un errore. Riprova o contatta l'assistenza."
        );
    }

    setIsLoading(false);
  };

  // when user presses enter key inside field, submit the form
  const handleEnterPress = (event) => {
    if (event.key === "Enter") handleSubmit(event);
  };

  const handleEmailChange = (e) => setEmail(e.target.value);

  // confirm modal state and operations
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const openConfirmModal = () => setConfirmModalOpen(true);
  const closeConfirmModal = () => setConfirmModalOpen(false);
  const goBackToLogin = () => navigate("/login");

  return (
    <>
      <ConfirmModal
        isOpen={confirmModalOpen}
        onClose={closeConfirmModal}
        onConfirm={goBackToLogin}
      />

      <div className="w-[95%] mx-auto flex flex-row sm:flex-col my-8 ">
        <ScrollToTop />

        <div className="w-[30%] sm:w-full sm:mb-5 md:w-[40%] xsm:hidden sm:hidden md:hidden">
          <img src={ErrorBG} alt="" className="sm:w-1/2  sm:m-auto md:my-16" />
        </div>

        <div className="w-[70%] sm:w-full flex flex-col gap-y-8 ">
          <p className="text-[#00B27A] Brevia850 text-4xl sm:text-center">
            Serve una mano?
          </p>
          <p className="text-[#00B27A] Brevia850 text-4xl sm:text-center">
            Recupera la tua password!
          </p>

          <p className="Brevia700 text-2xl italic sm:text-center ml-1">
            Ti invieremo una mail per recuperare le tue credenziali
          </p>

          <form onSubmit={(e) => handleSubmit(e)} className="w-full">
            <div className="ml-1 w-[50%] md:w-full sm:w-full">
              <p className="Brevia700 text-xl">Email</p>
              <input
                id="email-address"
                name="email"
                type="email"
                autoComplete="email"
                placeholder="Scrivi l'email qui..."
                required
                value={email}
                onChange={handleEmailChange}
                disabled={isLoading}
                className="w-full h-16 border-4 border-black px-5 text-xl pop500"
                onKeyPress={handleEnterPress}
              />
            </div>
          </form>

          {error && (
            <p className="text-red-600 text-2xl sm:text-center ml-10">
              {error}
            </p>
          )}

          <div className="w-full">
            <button
              type="submit"
              onClick={(e) => handleSubmit(e)}
              disabled={disableSubmit}
              className="w-96 h-12  border-[3px] bg-[#DCFFCF] border-black items-center flex justify-center sm:mx-auto xsm:mx-auto  md:mx-auto sm:w-[80%] xsm:w-[80%] md:w-[80%]"
            >
              {isLoading ? (
                <CircularProgress color="success" />
              ) : (
                <p className="pop700 text-black text-xl">Recupera password</p>
              )}
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
