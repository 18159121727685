import React from "react";
import PlanButtons from "../../../Components/Pricing/PlanButton";
import { planData } from "./plans";
import PlanCard from "../../../Components/Pricing/PlanCard";
import styles from "./Plan.module.css";
import cn from "classnames";

const PlansSection = ({ t }) => {
  const [plan, setPlan] = React.useState(2);

  return (
    <div className={cn("w-[90%] mx-auto px-12 mb-12", styles.container)}>
      <PlanButtons t={t} plan={plan} setPlan={setPlan} />
      <div className="w-full flex-wrap flex-row flex justify-center items-center mt-12">
        {planData(t).map((item, index) => (
          <PlanCard
            key={index}
            {...item}
            index={index}
            plan={plan}
            price={plan === 1 ? item.monthly : item.annual}
            t={t}
          />
        ))}
      </div>
    </div>
  );
};
export default PlansSection;
