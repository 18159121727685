import React from "react";
import ScrollToTop from "../../Reusable/ScrollToTop";

function Demo() {
  return (
    <div className=" w-[80%] xsm:w-[90%] sm:w-[90%] flex flex-col items-center justify-center  py-10   mx-auto">
      <ScrollToTop />
      <h1 className="text-[#114653] p-5">
        Clicca per scoprire Restaurants Club
      </h1>
      <div style={{position: 'relative', width: '100%', paddingTop: '56.25%', overflow: 'hidden'}}>
      <iframe id="__contrast_embed_983637e3-1268-45e7-8991-a2fd65b6f6b1" src="https://embed.getcontrast.io/watch?u=7fcb937d-2f08-49d7-b714-7ae6944ffa6b" style={{position: 'absolute', top: 0, right: 0, bottom: 0, left: 0, width: '100%', height: '100%'}} title="Contrast video player" frameborder="0" allow="encrypted-media; picture-in-picture; web-share" allowfullscreen></iframe>
    </div>
    </div>
  );
}

export default Demo;
