const LapTopSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <mask
        id="mask0_0_7602"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.259399" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_0_7602)">
        <path
          d="M2 21.2594C1.71667 21.2594 1.47917 21.1636 1.2875 20.9719C1.09583 20.7802 1 20.5427 1 20.2594C1 19.9761 1.09583 19.7386 1.2875 19.5469C1.47917 19.3552 1.71667 19.2594 2 19.2594H22C22.2833 19.2594 22.5208 19.3552 22.7125 19.5469C22.9042 19.7386 23 19.9761 23 20.2594C23 20.5427 22.9042 20.7802 22.7125 20.9719C22.5208 21.1636 22.2833 21.2594 22 21.2594H2ZM4 18.2594C3.45 18.2594 2.97917 18.0636 2.5875 17.6719C2.19583 17.2802 2 16.8094 2 16.2594V5.2594C2 4.7094 2.19583 4.23857 2.5875 3.8469C2.97917 3.45523 3.45 3.2594 4 3.2594H20C20.55 3.2594 21.0208 3.45523 21.4125 3.8469C21.8042 4.23857 22 4.7094 22 5.2594V16.2594C22 16.8094 21.8042 17.2802 21.4125 17.6719C21.0208 18.0636 20.55 18.2594 20 18.2594H4ZM4 16.2594H20V5.2594H4V16.2594Z"
          fill="#475467"
        />
      </g>
    </svg>
  );
};
export default LapTopSvg;
