import React from "react";

const SecondButton = ( { onClick, text } ) => {

  return (

    <div className="flex w-fit h-max xsm:w-full">

      <button onClick={onClick} className="w-fit h-max px-12 py-3 xsm:px-6 xsm:py-4 sm:px-6 sm:py:4 flex xsm:content-center xsm:m-auto  sm:content-center md:content-center pop600 border-[3px] bg-transparent border-black"> {text} </button>
            
    </div>

  )
}

export default SecondButton



