import React from "react";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import styles from "./Packs.module.css";
import cn from "classnames";

const PacksSection = ({ t }) => {
  const packCards = [
    {
      title: "pricesPage.packsSection.packs.pack1.title",
      items: [
        {
          text: "pricesPage.packsSection.packs.pack1.items.1",
        },
        {
          text: "pricesPage.packsSection.packs.pack1.items.2",
        },
        {
          text: "pricesPage.packsSection.packs.pack1.items.3",
        },
      ],
      extraInfo: [
        {
          text: "pricesPage.packsSection.packs.pack1.extraInfo.1",
        },
        {
          text: "pricesPage.packsSection.packs.pack1.extraInfo.2",
        },
      ],
      icon: CheckRoundedIcon,
      bgcolor: "rcchromaticgreen",
    },
    {
      title: "pricesPage.packsSection.packs.pack2.title",
      items: [
        {
          text: "pricesPage.packsSection.packs.pack2.items.1",
        },
        {
          text: "pricesPage.packsSection.packs.pack2.items.2",
        },
        {
          text: "pricesPage.packsSection.packs.pack2.items.3",
        },
      ],
      extraInfo: [
        {
          text: "pricesPage.packsSection.packs.pack2.extraInfo.1",
        },
        {
          text: "pricesPage.packsSection.packs.pack2.extraInfo.2",
        },
      ],
      icon: CheckRoundedIcon,
      bgcolor: "rcchromaticblue",
    },
  ];
  return (
    <div
      id="recommended_package_section"
      className={cn("w-[80%] mx-auto py-12", styles.main_container)}
    >
      <h3 className={styles.title}>{t("pricesPage.packsSection.title")}</h3>

      <div
        className={cn(
          "w-[80%] mx-auto flex flex-wrap justify-center gap-x-24",
          styles.container
        )}
      >
        {packCards.map((item, index) => (
          <div
            key={index}
            className={cn(
              "border-[3px] border-rcblue w-[40%] py-6 mt-12",
              styles.card_container
            )}
          >
            <h5 className={styles.card_title}>{t(item.title)}</h5>

            <div className={`w-full py-2 mt-6 bg-${item.bgcolor}`}></div>

            <div className={cn("px-20 mt-12", styles.text_container)}>
              {item.items.map(({ text }, index) => (
                <div className="mb-5 flex" key={index}>
                  <div className="w-[25px] h-[25px]  text-rcgreen rounded-full inline-block mr-3">
                    <item.icon />
                  </div>

                  <div className={styles.item_text}>{t(text)}</div>
                </div>
              ))}
            </div>
            <div className={cn("px-20 mt-12", styles.extra_text_container)}>
              {item.extraInfo.map(({ text }, index) => (
                <div className="mb-5 flex" key={index}>
                  <div className={styles.left_bar} />

                  <div className={styles.extra_text}>{t(text)}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PacksSection;
