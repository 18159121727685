const PlanButtons = ({ setPlan, plan, t }) => {
  return (
    <div className="w-full px-6 flex">
      <div className="mx-auto border border-[#98a2b3] rounded-md p-1 flex justify-between gap-x-2">
        <button
          className={`${
            plan === 1
              ? "text-white bg-rcblue p-2 rounded-md transition-colors duration-300 px-3"
              : "text-black px-3"
          } pop400`}
          onClick={() => setPlan(1)}
        >
          {t("pricesPage.plansSection.monthly")}
        </button>

        <button
          className={`${
            plan === 2
              ? "text-white bg-rcblue p-2 rounded-md transition-colors duration-300 px-3"
              : "text-black px-3"
          } pop400`}
          onClick={() => setPlan(2)}
        >
          {t("pricesPage.plansSection.annually")}
        </button>
      </div>
    </div>
  );
};

export default PlanButtons;
