import { LanguageSelector } from "../../../Components/LanguageSelector";
import NavBarLink from "./NavLink";
import AppLinks from "../AppLinks";
import { useNavBar } from "../useNavBar";
import cn from "classnames";
import styles from "./WebVersion.module.css";

const WebVersion = ({ t, RestaurantsIcon }) => {
  const { navigate, renderNavButton } = useNavBar(t);

  return (
    <div
      className={cn(
        "w-full sticky z-20 top-0 bg-white border-b-[1px] border-rcdarkgray py-2",
        styles.web_nav
      )}
    >
      <div className="flex w-4/5 mx-auto">
        <div className="flex">
          <img
            style={{ maxWidth: 200 }}
            onClick={() => navigate("/")}
            src={RestaurantsIcon}
            alt="logo"
            className="cursor-pointer"
          />
        </div>

        <div className="flex pop400  justify-start space-x-4 w-[60%] pl-5">
          {AppLinks.map((appLink) => (
            <NavBarLink
              key={appLink.title}
              link={appLink?.link}
              title={appLink.title}
              shouldExpand={appLink?.shouldExpand}
              t={t}
            >
              {appLink?.children}
            </NavBarLink>
          ))}
        </div>

        <div className="flex pop400 justify-end items-center space-x-4 ">
          <button
            style={{ marginRight: 10 }}
            onClick={() => {
              navigate("/login");
            }}
            className="w-[80px] xmd:w-[60px] h-10  items-center flex justify-center "
          >
            <p
              style={{ margin: 0 }}
              className="pop500 text-sm xmd:text-xs hover:underline hover:underline-offset-4"
            >
              {t("nav.login")}
            </p>
          </button>
          {renderNavButton()}
          <LanguageSelector />
        </div>
      </div>
    </div>
  );
};
export default WebVersion;
