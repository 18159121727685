import { Link } from "react-router-dom";
import CalendlyButton from "./CalendlyButton";

const LastSection = ({ t }) => {
  const { REACT_APP_CALENDLY_ENDPOINT } = process.env;
  return <div className=" bg-[#FC6371] py-12 ">
    <div className="flex flex-col items-center justify-center gap-y-3 px-3">
      <h3 className="Brevia850  text-white  text-center">
        {t("homePage.lastSection.title")}
      </h3>

      <div className="flex justify-center pt-5 ">
        <CalendlyButton
          url={REACT_APP_CALENDLY_ENDPOINT}
          text={t("homePage.lastSection.button")}
        />
      </div>

      <Link
        className="w-fit h-max py-5 xsm:py-4 sm:py:4 pop600 underline text-white"
        to="/contatti"
      >
        {t("homePage.lastSection.link")}
      </Link>
    </div>
  </div>
};
export default LastSection;
