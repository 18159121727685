import React from 'react';
import '../../App.css';
import ScrollToTop from '../../Reusable/ScrollToTop';

const Cookiepolicy = () => {

  return (

    <>

      <ScrollToTop />

      <div className="flex flex-col w-[80%] text-left justify-center items-center mx-auto">

        <h1 className="my-8 Brevia700 text-5xl text-[#00B27A]">COOKIE POLICY</h1>

        <p>

          Tramite questa pagina AiGot. S.r.l. fornisce all’Utente informazioni su tipologie, finalità e
          modalità di utilizzo e gestione dei cookie presenti sul sito internet https://www.restaurants.club e
          sul sito internet <span className="font-bold">htttps://www.aigot.com</span> (d’ora innnanzi “<span className="font-bold">Siti</span>”) nel rispetto della normativa in
          vigore (D.Lgs. 196/2003 e Reg. UE 679/2016 – GDPR).



        </p>

        <br />




        <div className="flex flex-col ">

          <ol className="list-decimal">

            <div className="flex flex-row ml-4 mb-2">

              <p className="mr-2 font-bold">COSA SONO I COOKIE?</p>

            </div>

            <p className="ml-10 mb-2">I cookie sono porzioni di informazioni che i Siti inseriscono nel dispositivo di navigazione
              dell’Utente (computer, tablet, smartphone) quando visita una pagina. Possono comportare la
              trasmissione di informazioni tra i Siti ed il dispositivo, e tra quest’ultimo e altri siti che operano
              per conto di AiGot S.r.l. o in privato.
              Sono utilizzati, ad esempio, per consentire o migliorare la navigazione tra le pagine dei Siti,
              registrando e ricordando le preferenze dell’Utente. Inoltre, solo previo consenso dell’Utente, i Siti
              potrebbero utilizzare i cookie per mostrare, sia dai Siti stessi o da siti web di terzi, annunci
              pubblicitari basati sull'analisi delle abitudini di navigazione dell'Utente.</p>

          </ol>

        </div>



        <br />

        <div className="flex flex-col" >

          <ol className="list-decimal">

            <div className="flex flex-row ml-4 mb-2">

              <p className="mr-2 font-bold">TIPOLOGIE DI COOKIE UTILIZZATI</p>

            </div>

            <p className="ml-10 mb-2">I Siti potrebbero utilizzare differenti tipologie di cookie, anche in dipendenza delle preferenze e
              del consenso fornito dall’Utente:</p>

            <p className="ml-10 mt-2 font-bold">Cookie Tecnici</p>
            <p className="ml-10 mb-2">I cookie tecnici possono essere suddivisi in cookie di navigazione (o permanenti) o cookie di
              performance.
            </p>

            <ul className="flex flex-col list-disc gap-y-4 " id="cookie">


              <li className="flex flex-row ml-10"><p className="mr-4 font-bold">1)</p><p><span className="font-bold">Di navigazione / permanenti</span> <br /> I cookie di navigazione e permanenti sono essenziali per sfruttare appieno le caratteristiche
                dei Siti ad avere accesso ai servizi (ad esempio autenticare l’Utente al momento
                dell’accesso, se si tratta di un Utente precedentemente registrato).
                I cookie di navigazione sono attivi soltanto per la durata di una sessione di navigazione,
                mentre i cookie permanenti rimangono attivi per più visite e vengono utilizzati per
                ricordare le preferenze dell’Utente e memorizzare le azioni compiute durante le visite sui
                Siti.
                Questi cookie consentono ai Siti di memorizzare le scelte fatte dall’Utente (nome, lingua o
                luogo di provenienza) e forniscono funzionalità avanzate personalizzate. Questi cookie
                possono essere utilizzati anche per ricordare le modifiche apportate alla dimensione del
                testo, font ed altre parti di pagine web che è possibile personalizzare.</p></li>

              <li className="flex flex-row ml-10"><p className="mr-4 font-bold">2)</p><p><span className="font-bold">Di performance</span> <br /> Tali cookie raccolgono informazioni riguardo alle modalità di utilizzo dei Siti da parte
                degli utenti al fine di migliorare la qualità degli stessi e dei servizi offerti, tenendo traccia
                delle pagine visitate con maggiore frequenza e delle attività sui Siti. I cookie di
                performance consentono a AiGot S.r.l. di applicare le scelte effettuate dall’Utente e di
                ottimizzare il funzionamento dei Siti, permettendo anche l’immediata identificazione e
                risoluzione di tutte le problematiche tecniche che si potrebbero incontrare durante la
                navigazione all’interno del sito. Tutte le informazioni che questi cookie conservano sono
                aggregate e anonime e non vengono utilizzate per raccogliere dati personali a scopi
                pubblicitari.</p></li>



            </ul>

            <p className="ml-10 mt-2 font-bold">Cookie di profilazione</p>
            <p className="ml-10 mb-2">Oltre ai cookie tecnici, i siti potrebbero utilizzare anche i cookie di profilazione.

              <br />
              Mediante tali cookie, i Siti possono creare un profilo di ciascun Utente, basato sulle preferenze ed i
              gusti manifestati durante la navigazione nel web, anche al fine di inviare messaggi coerenti con il
              profilo dell’Utente.
              Come previsto dalla normativa vigente, i Siti utilizzano i cookie di profilazione solo previo
              consenso dell’Utente, espresso mediante il banner presente al primo accesso; inoltre, ciascun
              Utente è libero di bloccare l’installazione dei cookie di profilazione in qualsiasi momento, senza
              che la possibilità di visitare i Siti e fruire dei suoi contenuti sia in qualsiasi modo compromessa.
              Le informazioni raccolte sono a beneficio esclusivo di AiGot S.r.l. e verranno condivise con
              soggetti terzi solo per le finalità di elaborazione di tali informazioni per conto della Società stessa.
              In determinate circostanze, AiGot S.r.l. potrebbe incrociare i dati raccolti tramite cookie con i dati
              personali dell’Utente raccolti per le finalità indicate nella Privacy Policy pubblicata sui medesimi
              Siti.</p>


            <p className="ml-10 mt-2 font-bold">Cookie propri o di terze parti</p>
            <p className="ml-10 mb-2">I cookie possono essere suddivisi in cookie propri o cookie di terze parti:.</p>

            <ul className="flex flex-col list-disc gap-y-4 " id="cookie">


              <li className="flex flex-row ml-10"><p className="mr-4 font-bold">1)</p><p> I <span className="font-bold">cookie propri</span>  vengono inviati direttamente dai Siti e possono essere letti esclusivamente
                da questi. Si tratta, ad esempio, dei cookie che ricordano quali articoli sono stati inseriti nel
                carrello di un Utente mentre l'Utente prosegue nella navigazione sui Siti.</p></li>

              <li className="flex flex-row ml-10"><p className="mr-4 font-bold">2)</p><p> I <span className="font-bold"> cookie di terze parti </span>  sono cookie predisposti e gestiti da siti diversi da quelli della
                Società ed installati per il tramite dei Siti.
                Alcuni hanno lo scopo di ottenere i dati in modo anonimo, ad esempio le statistiche di
                navigazione.
                Altri cookie di terze parti, invece, potrebbero tenere traccia della risposta fornita dai
                visitatori alle attività dei siti di AiGot S.r.l., eventualmente valutando le visite da parte
                dell’Utente a tali Siti o ai siti di terze parti su cui si trovano annunci pubblicitari, per
                visualizzare annunci mirati o individuare quali banner pubblicitari sono in grado di attirare
                utenti.</p></li>



            </ul>


          </ol>

        </div>

        <br />


        <br />

        <div className="flex flex-col">

          <ol className="list-decimal">

            <div className="flex flex-row ml-4 mb-2">

              <p className="mr-2 font-bold">GESTIONE DELLE PREFERENZE RIGUARDO AI COOKIE</p>

            </div>

            <p className="ml-10 mb-2">Ciascun Utente ha la possibilità di scegliere in qualsiasi momento di limitare, eliminare, bloccare o
              limitare parzialmente l’uso dei cookie modificando le impostazioni del proprio browser di
              navigazione.
              La configurazione dei cookie si trova normalmente nel menu "Preferenze" o "Strumenti".
              Tramite i seguenti collegamenti è possibile personalizzare la gestione dei cookie dei browser più
              diffusi: </p>

          </ol>

          <ul className="flex flex-col list-disc">

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> Chrome</p></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> Firefox</p></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> Internet Explorer 7 en 8</p></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> Internet Explorer 9</p></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> Safari</p></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> Opera</p></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> Facebook</p></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p>Instagram</p></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> Whatsapp</p></li>

            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> Linkedin</p></li>

            <p className="ml-10 my-2">Si ricorda che la completa disabilitazione dei cookie potrebbe compromettere la corretta
              funzionalità di alcune parti dei Siti (navigazione tra le pagine del sito, la possibilità di fare acquisti,
              perdita delle funzioni di personalizzazione).</p>

          </ul>

        </div>

        <br />

        <div className="flex flex-col self-start">

          <ol className="list-decimal">

            <div className="flex flex-row ml-4 mb-2 ">

              <p className="mr-2 font-bold">PRIVACY POLICY</p>

            </div>

            <p className="ml-10 mb-2">Per maggiori informazioni in merito al trattamento dei dati personali svolto dai siti internet della
              Società, si rimanda alla <a href="/privacypolicy" className="text-[#00B27A] underline">Privacy Policy</a> </p>

          </ol>

        </div>

        <br />

        <p className="flex self-start mb-8"> Ultimo aggiornamento: 13/05/2022 </p>

      </div>

    </>

  )
}

export default Cookiepolicy;
