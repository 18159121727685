import { useNavigate } from "react-router-dom";
import React from "react";

export const useNavBar = (t) => {
  const navigate = useNavigate();
  const [scrolledDown, setScrolledDown] = React.useState(false);
  React.useEffect(() => {
    document.addEventListener("scroll", () => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 20) setScrolledDown(true);
      else setScrolledDown(false);
    });
  }, []);
  const renderNavButton = React.useCallback(
    () => (
      <button
        style={{ padding: "0 10px", width: "max-content" }}
        onClick={() => navigate(scrolledDown ? "/video" : "/register")}
        className="h-10 border-[3px] bg-rcgreen border-rcsecondaryblack items-center flex justify-center"
      >
        <span className="pop500 text-white text-sm xmd:text-xs hover:underline hover:underline-offset-4">
          {scrolledDown ? t("nav.demo") : t("nav.join")}
        </span>
      </button>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scrolledDown]
  );
  return {
    renderNavButton,
    navigate,
    t,
  };
};
