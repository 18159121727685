import React from 'react'


import ScrollToTop from '../../Reusable/ScrollToTop'
//import SecondButton from "../../Reusable/LandingButton/SecondButton";
import SecondButtonScrollTo from "../../Reusable/LandingButton/SecondButtonScrollTo";
import FirstButtonScrollTo from "../../Reusable/LandingButton/FirstButtonScrollTo"

import chefgelato from "../../Assets/chefgelato.png";
//import ads from "../../Assets/ads.png";
import promo from "../../Assets/promo.png";
//import image2 from "../../Assets/Rcvs/Plateform/image2.png";
import cupcake from "../../Assets/cupcacke.png";
import menu from "../../Assets/menu.png";
import asporto_delivery from "../../Assets/asporto_delivery.png";
import notorieta from "../../Assets/notorietà.png";
import rcalsigep from "../../Assets/rcalsigep.png";
import suppporto from "../../Assets/supporto.png";
import euro from "../../Assets/euro.png";

import adsgel from "../../Assets/adsgel.png"
import clientetipo from "../../Assets/clientetipo.png"

import Frame1 from "../../Components/Home/Img/Frame1.svg";
import Frame2 from "../../Components/Home/Img/Frame2.svg";
import Frame3 from "../../Components/Home/Img/Frame3.svg";



import HubspotSigepForm from './SigepForm';


//import good from "../../Assets/Rcvs/Plateform/good.svg";
//import statics from "../../Assets/Rcvs/Plateform/statics.svg";
//import crypto from "../../Assets/Rcvs/Plateform/crypto.svg";


//import { useNavigate } from "react-router-dom"



const SigepFiera = () => {

    //const navigate = useNavigate()
  
  
    return (
        <div className="">
            <ScrollToTop/>
            {/*Vieni a trovarci al Sigep*/}
            <div className=' bg-[#114653] p-10 xsm:px-4 sm:px-5' >
                    <h3 className='Brevia700 italic  text-white text-center pb-2 bg-[#114653]'>
                        Vieni a trovarci!
                    </h3>
                 <div className="xmd::w-[800px] lg:w-[850px] xl:w-[850px] flex flex-row  md:flex-col sm:flex-col   xsm:flex-col mx-auto">
                        <div className="w-1/2 xsm:w-full sm:w-full  md:w-full   m-auto">
                            <img
                            src={rcalsigep}
                            alt="-"
                            className=" m-auto self-center flex "
                            />
                        </div>
                        <div className="w-1/2 xsm:w-full sm:w-full  md:w-full   m-auto py-3 ">


                            <h3 className='Brevia700 italic  text-white text-center  '>
                            Padiglione <span className='Brevia850 '>A7</span>, Stand <span className='Brevia850'>200-7</span>
                            </h3>
          
                        </div>

                    </div>
                 </div>

            <div className="w-[80%]  mx-auto  flex flex-col  ">

               
            <div>
                {/*------------------------------------------------------ (1) ----------------------------------------------------------------*/}

                <div className="flex flex-row sm:flex-col xsm:flex-col  p-5 gap-3 xl:w-[90%] lg:w-[90%] mx-auto">
                   

                    <div className=" w-[60%] xsm:w-full sm:w-full  md:w-full m-auto ">

                        <div className="flex flex-col gap-5 m-5  xsm:m-0 sm:m-0 xsm:my-2 sm:my-2">

                            <h1 className="Brevia850 text-[#00B27A] xsm:text-center sm:text-center  ">
                            Rendi dolcissima la tua pubblicità!
                            </h1>

                            <p className=" pop500 xsm:text-center sm:text-center ">
                            Raggiungi i tuoi clienti. <br className="xmd:hidden lg:hidden xl:hidden" />Attraine di nuovi. <br/>
                            Restaurants Club è la prima piattaforma di marketing studiata per gelaterie, pasticcerie e ristoranti, per creare e gestire una solida base clienti fedele.
                            </p>
                        <div className=" flex xsm:justify-center sm:justify-center ">
                        <FirstButtonScrollTo toId="funzioni" text="Scopri di più" />
                        </div>
                        </div>
                        

                    </div>

                    <div className=" w-[50%] xsm:w-full sm:w-full  md:w-full ">
                        <img src={chefgelato} alt=""  className="m-auto" />
                    </div>

                </div>
                
                {/*------------------------------------------------------ (2) ----------------------------------------------------------------*/}
                <div className="flex  pt-10  ">
                <h3 className="Brevia700 italic	text-[#114653] text-center mx-auto">
                   Cosa fa Restaurants Club?
                </h3>
                </div>

                <div className="row flex flex-row xsm:flex-col sm:flex-col  mt-10  justify-center gap-8 md:gap-5 xsm:mt-5 sm:mt-5 ">
                <div className="w-1/3 mx-auto md:w-full sm:w-full xsm:w-[90%] px-5 ">
                    <div className="w-[60%]  mx-auto flex justify-center xsm:w-full sm:w-full md:w-full xmd:w-full">
                    <img  src={menu} className=""  alt="" />
                    </div>
                    <div className="text-center mt-10">
                    <h5 className="pop700 mb-2">Sito web con menù digitale</h5>
                    <p className="pop500">
                    Sito  e menù digitale con QR code, facili da aggiornare e personalizzare.
                    </p>
                    </div>
                </div>

                <div className="w-1/3 mx-auto md:w-full sm:w-full xsm:w-[90%] px-5 ">
                    <div className="w-[60%] mx-auto flex justify-center xsm:w-full sm:w-full md:w-full xmd:w-full">
                    <img src={asporto_delivery} alt="-" />
                    </div>
                    <div className="text-center mt-10">
                    <h5 className="pop700 mb-2">
                    Gestione asporto e delivery
                    </h5>
                    <p className="pop500">
                    I tuoi clienti ordinano a domicilio o prenotano l’asporto direttamente dal tuo sito, senza commissioni.
                    </p>
                    </div>
                </div>

                <div className="w-1/3 mx-auto md:w-full sm:w-full xsm:w-[90%] px-5 ">
                    <div className="w-[60%] mx-auto flex justify-center xsm:w-full sm:w-full md:w-full xmd:w-full">
                    <img src={notorieta} alt="-" />
                    </div>
                    <div className="text-center mt-10">
                    <h5 className="pop700 mb-2">
                    Fatti conoscere online
                    </h5>
                    <p className="pop500">
                    La dolcezza conquisterà il web: fatti conoscere online con campagne pubblicitarie e newsletter.
                    </p>
                    </div>
                </div>
                </div>

                <div className="flex justify-center xsm:justify-center sm:justify-center md:justify-center  mt-10 mb-5">

                <SecondButtonScrollTo toId="euroancor" text="Prova il servizio a 1€" />

                </div>
                

                {/* Container 3 */}
                <div  id="funzioni"  className="mx-auto my-10 mt-24 flex flex-col justify-center items-center gap-16  ">

                        {/* Banner 1 */}
                        <div className="flex w-full  flex-row  sm:flex-col-reverse xsm:flex-col-reverse gap-10">

                            <div  className=" w-[60%] lg:w-[50%] xl:w-[50%] xsm:w-full sm:w-full  sm:text-center xsm:text-center my-auto flex flex-col gap-8 ">

                                <h3 className="Brevia700 italic  ">
                                Fare pubblicità sui social adesso è più facile!
                                </h3>

                                <p className="pop500  ">
                                Non devi essere un professionista del marketing per fare pubblicità sui social! Con Restaurants Club crei e programmi le tue campagne pubblicitarie per Facebook e Instagram, direttamente dalla piattaforma.                                </p>
                            </div>
                            <div className=" w-[40%] flex mx-auto ">
                                <img
                                src={adsgel}
                                alt=""
                                className=" mx-auto self-center flex  w-40%  "
                                />
                            </div>
                        </div>

                        {/* Banner 2*/}
                        <div className="flex  flex-row  sm:flex-col  xsm:flex-col  gap-10 ">
                        <div className="w-[40%]  mx-auto  flex  ">
                            <img
                            src={promo}
                            alt="-"
                            className=" mx-auto self-center flex w-40% "
                            />
                        </div>
                        <div className="w-[60%] lg:w-[50%] xl:w-[50%] xsm:w-full sm:w-full  sm:text-center xsm:text-center my-auto flex flex-col gap-8 ">
                            <h3 className="Brevia700 italic ">
                            Crea pubblicità efficaci in meno di 10 minuti
                            </h3>
                            <p className="pop500 ">
                            Scegli tra i tantissimi modelli grafici personalizzabili che abbiamo creato appositamente per te! 
                            Inserisci testo e immagini e posta sui social con un solo click.
                            </p>

                           
                        </div>
                        </div>

                        {/* Banner 3 */}
                        <div className="flex  flex-row  sm:flex-col-reverse xsm:flex-col-reverse  gap-10">
                        <div className="w-[60%] lg:w-[50%] xl:w-[50%] xsm:w-full sm:w-full  sm:text-center xsm:text-center my-auto flex flex-col gap-8">
                            <h3 className="Brevia700 italic  mb-5 ">
                            Conosci sempre meglio i tuoi clienti!
                            </h3>

                            <p className="pop500  ">
                            Grazie al modulo “Cliente-Tipo” potrai scoprire gusti, preferenze ed abitudini del tuo cliente ideale,  permettendoti così di sfruttare al meglio ogni centesimo investito in pubblicità.                            </p>
                        </div>
                        <div className=" w-[40%] mx-auto  flex  ">
                            <img
                            src={clientetipo}
                            alt=""
                            className=" mx-auto self-center flex w-40% "
                            />
                        </div>
                        </div>

                    </div>
            </div>
            </div>
            <div>
                {/* Banner giallo */}
                <div className=" bg-[#FFCB63]" >
                    <div className="w-[80%] mx-auto flex  ">

                    <div className="flex flex-row xsm:flex-col-reverse sm:flex-col-reverse p-5 w-[70%] md:w-[90%] xmd:w-[80%]  xsm:w-full sm:w-full mx-auto gap-10">
                    

                        <div className="w-[60%] xmd:w-[70%] xsm:w-full sm:w-full lg:w-[70%] flex flex-col gap-10  p-2 xsm:items-center sm:items-center my-auto ">
                        <div className="  ">
                            <h3 className="Brevia850  xsm:text-center sm:text-center  text-[#114653]">Per te fino a 1.400€ inclusi da usare per la tua pubblicità!
                            </h3>
                         </div>
                        <div className=" ">
                        <SecondButtonScrollTo toId="euroancor" text="Prova il servizio a 1€" />
                        </div>

                        </div>

                        <div className="w-[40%] xmd:w-[30%] lg:w-[30%] xsm:w-full sm:w-full p-2 flex items-center ">
                        <div className="  "><img src={cupcake} alt=""/></div>
                        
                        </div>
                        
                    </div>

                    </div>
                    </div>
                </div>
            {/*Vieni a trovarci al Sigep*/}
            <div className=' bg-[#114653] p-10 xsm:px-4 sm:px-5' >
                    
                 <div className="xmd::w-[800px] lg:w-[850px] xl:w-[850px] flex flex-row  md:flex-col sm:flex-col   xsm:flex-col mx-auto">
                        <div className="w-1/2 xsm:w-full sm:w-full  md:w-full   m-auto">
                            <img
                            src={rcalsigep}
                            alt="-"
                            className=" m-auto self-center flex "
                            />
                        </div>
                        <div className="w-1/2 xsm:w-full sm:w-full  md:w-full   m-auto py-3 ">


                            <h3 className='Brevia700 italic  text-white text-center  '>
                            Padiglione <span className='Brevia850 '>A7</span>, Stand <span className='Brevia850'>200-7</span>
                    </h3>
          
                        </div>
                    </div>
                 </div>


            <div className="w-[80%] mx-auto flex flex-col ">
                <div className=" mx-auto  flex flex-col ">

                    {/* Banner 1 */}
                    <div className="flex mt-16 flex-row gap-10  sm:flex-col-reverse xsm:flex-col-reverse ">
                        <div className="w-1/2 xsm:w-full sm:w-full  md:w-full lg:w-[40%]  sm:text-center xsm:text-center my-auto">
                            <h3 className="Brevia700 italic  mb-5 ">
                            Fai crescere la tua attività con un piano su misura per te
                            </h3>

                            <p className="pop500 my-3 ">
                            Raggiungi nuove persone sui social e trasforma i tuoi “mi piace” e commenti in clienti di valore che riempiono i tuoi tavoli. 
                    
                            Premia i più fedeli e crea una lista contatti da utilizzare per le tue newsletter
                            </p>
                            <div className="flex xsm:justify-center sm:justify-center  pt-5 ">
                                <FirstButtonScrollTo toId="euroancor" text="Prova il servizio a 1€" />
                            </div>

                        </div>
                        <div  className="w-1/2 xsm:w-full sm:w-full md:w-full content-center flex m-auto">
                            <img
                            src={suppporto}
                            alt=""
                            className=" mx-auto self-center flex "
                            />
                        </div>
                    </div>
                </div>
            </div>



                {/*------------------------------------------------------ Pacchetti ----------------------------------------------------------------*/}
                <div  className='w-[80%]  mx-auto  flex flex-col '>
                <div className="flex flex-col items-center justify-center py-14  ">
                    <h3 className="Brevia700 italic	text-[#114653] text-center ">
                    Un piano costruito in base ai tuoi obiettivi
                    </h3>
                </div>

                <div className="row flex flex-row xsm:flex-col sm:flex-col  pb-10  justify-center gap-8 md:gap-5 xsm:mt-5 sm:mt-5">
                <div className="w-1/3 mx-auto md:w-full sm:w-full xsm:w-[90%] px-5 ">
                    <div className="w-[85%]  mx-auto flex justify-center xsm:w-full sm:w-full md:w-full xmd:w-full">
                    <img  src={Frame1} className=""  alt="" />
                    </div>
                    <div className="text-center mt-10">
                    <h5 className="pop700 mb-2">Gestisci TU delivery e asporto</h5>
                    <p className="pop500">
                    Ricevi ordini attraverso il tuo nuovo sito web e app senza pagare commissioni! Il delivery adesso nelle tue mani
                    </p>
                    </div>
                </div>

                <div className="w-1/3 mx-auto md:w-full sm:w-full xsm:w-[90%] px-5 ">
                    <div className="w-[85%] mx-auto flex justify-center xsm:w-full sm:w-full md:w-full xmd:w-full">
                    <img src={Frame3} alt="-" />
                    </div>
                    <div className="text-center mt-10">
                    <h5 className="pop700 mb-2">
                    Fatti conoscere nella tua zona
                    </h5>
                    <p className="pop500">
                    Attira turisti e locali al tuo ristorante comparendo ai primi posti nelle ricerche su Google.
                    </p>
                    </div>
                </div>

                <div className="w-1/3 mx-auto md:w-full sm:w-full xsm:w-[90%] px-5 ">
                    <div className="w-[85%] mx-auto flex justify-center xsm:w-full sm:w-full md:w-full xmd:w-full">
                    <img src={Frame2} alt="-" />
                    </div>
                    <div className="text-center mt-10">
                    <h5 className="pop700 mb-2">
                    Riempi i tuoi tavoli con clienti di valore
                    </h5>
                    <p className="pop500">
                    Riempi i tuoi tavoli con clienti di valore con il modulo prenotazioni evitando i no-show.
                    </p>
                    </div>
                </div>
                </div>
                </div>


            {/* euro */}
            <div id="euroancor"  className='bg-[#00B27A] xmd:mb-20 lg:mb-20 xl:mb-20'>
                <div className='w-[80%]  mx-auto  flex flex-col '>
                    <div className="flex my-10 flex-row gap-6 sm:flex-col   xsm:flex-col bg-[#00B27A]">
                        <div className="w-1/2 xsm:w-full sm:w-full   content-center flex ">
                                    <img
                                    src={euro}
                                    alt="-"
                                    className=" mx-auto self-center flex "
                                    />
                        </div>
                        <div className="w-1/2 xsm:w-full sm:w-full   sm:text-center xsm:text-center my-auto ">
                            <h3 className="Brevia700 italic mb-5 text-white">
                            Prova per 1 mese a 1€
                            </h3>
                            <p className="pop500 text-white">
                            Prova tutte le funzionalità di Restaurants Club <span className="pop700">a solo 1€</span> per un mese, senza alcun vincolo di rinnovo!
                            </p>      
                            <p className="pop500 text-white my-5">
                            Il tuo consulente ti aiuterà a muovere i primi passi all’interno della piattaforma e definirete assieme la strategia vincente per il tuo locale! 
                            Ti regaleremo anche <span className="pop700">70€ di budget</span> per poter creare la tua prima campagna pubblicitaria sui social.                            
                            </p>                       
                        </div>
                    </div>
                </div>
            </div>




    <div className="w-[80%] mx-auto grid grid-cols-2 xsm:grid-cols-1 sm:grid-cols-1 md:grid-cols-1 mb-20 ">


            <div className="w-[80%] sm:text-center xsm:text-center md:text-center sm:w-full xsm:w-full xsm:mx-auto sm:mx-auto md:mx-auto">
                <h3 className="Brevia850 italic text-[#00B27A] my-10">
                Contattaci e vedi <br className="md:hidden sm:hidden xsm:hidden" /> Restaurants Club in azione!
                </h3>
            </div>
            <div className="w-full sm:text-center xsm:text-center  ">
            <HubspotSigepForm />
            </div>
            <div className='bg-[#00B27A]'>
                <div className='w-[80%]'>


                </div>

            </div>
            
     </div>
    </div>
    );
};

export default SigepFiera