const EuroSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
    >
      <circle cx="20" cy="20.4963" r="20" fill="#114653" />
      <mask
        id="mask0_0_7664"
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="25"
      >
        <rect x="8" y="8.49634" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_0_7664)">
        <path
          d="M23 29.4963C21.0333 29.4963 19.2833 28.938 17.75 27.8213C16.2167 26.7047 15.1333 25.263 14.5 23.4963H11V21.4963H14.05C14 21.0963 13.9792 20.7255 13.9875 20.3838C13.9958 20.0422 14.0167 19.7463 14.05 19.4963H11V17.4963H14.5C15.1333 15.7297 16.2167 14.288 17.75 13.1713C19.2833 12.0547 21.0333 11.4963 23 11.4963C24.15 11.4963 25.2375 11.7005 26.2625 12.1088C27.2875 12.5172 28.2 13.0797 29 13.7963L27.575 15.1963C26.9583 14.663 26.2625 14.2463 25.4875 13.9463C24.7125 13.6463 23.8833 13.4963 23 13.4963C21.5833 13.4963 20.3167 13.8672 19.2 14.6088C18.0833 15.3505 17.2417 16.313 16.675 17.4963H23V19.4963H16.075C16.0083 19.9463 15.9833 20.3422 16 20.6838C16.0167 21.0255 16.0417 21.2963 16.075 21.4963H23V23.4963H16.675C17.2417 24.6797 18.0833 25.6422 19.2 26.3838C20.3167 27.1255 21.5833 27.4963 23 27.4963C23.8833 27.4963 24.7125 27.3463 25.4875 27.0463C26.2625 26.7463 26.9583 26.3297 27.575 25.7963L29 27.1963C28.2 27.913 27.2875 28.4755 26.2625 28.8838C25.2375 29.2922 24.15 29.4963 23 29.4963Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
export default EuroSvg;
