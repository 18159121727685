import { useTranslation } from "react-i18next";
import BuiltForSection from "../Containers/Pricing/BuiltForSection";
import DesignYourPlanSection from "../Containers/Pricing/DesignYourPlanSection";
import FindSolutionSection from "../Containers/Pricing/FindSolutionSection";
import PricingHero from "../Containers/Pricing/HeroSection";
import OnePlatformSection from "../Containers/Pricing/OnePlatformSection";
import PackagesSection from "../Containers/Pricing/PackagesSection";
import PacksSection from "../Containers/Pricing/PacksSection";
import PlanForYou from "../Containers/Pricing/PlanForYou";
import PlansSection from "../Containers/Pricing/PlansSection";
import ReachGoalsSection from "../Containers/Pricing/ReachGoolsSection";
import ScrollToTop from "../Reusable/ScrollToTop";

const PricesPage = () => {
  const { t } = useTranslation();
  return (
    <div className="w-full py-12">
      <ScrollToTop />
      <PricingHero t={t} />
      <PlansSection t={t} />
      <ReachGoalsSection t={t} />
      <PlanForYou t={t} />
      <PackagesSection t={t} />
      <BuiltForSection t={t} />
      <PacksSection t={t} />
      <DesignYourPlanSection t={t} />
      <OnePlatformSection t={t} />
      <FindSolutionSection t={t} />
    </div>
  );
};
export default PricesPage;
