const FingerSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
    >
      <circle cx="20" cy="20.4963" r="20" fill="#114653" />
      <mask
        id="mask0_0_7656"
        maskUnits="userSpaceOnUse"
        x="8"
        y="8"
        width="24"
        height="25"
      >
        <rect x="8" y="8.49634" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_0_7656)">
        <path
          d="M17.95 29.4963C17.5 29.4963 17.0709 29.4005 16.6625 29.2088C16.2542 29.0172 15.9084 28.7463 15.625 28.3963L9.15002 20.4213L9.80002 19.7963C10.1167 19.4797 10.4917 19.2963 10.925 19.2463C11.3584 19.1963 11.75 19.2963 12.1 19.5463L15 21.5713V11.4963C15 11.213 15.0959 10.9755 15.2875 10.7838C15.4792 10.5922 15.7167 10.4963 16 10.4963C16.2834 10.4963 16.5209 10.5922 16.7125 10.7838C16.9042 10.9755 17 11.213 17 11.4963V25.4213L14.225 23.4713L17.175 27.1213C17.275 27.238 17.3917 27.3297 17.525 27.3963C17.6584 27.463 17.8 27.4963 17.95 27.4963H25C25.55 27.4963 26.0209 27.3005 26.4125 26.9088C26.8042 26.5172 27 26.0463 27 25.4963V18.4963C27 18.213 27.0959 17.9755 27.2875 17.7838C27.4792 17.5922 27.7167 17.4963 28 17.4963C28.2834 17.4963 28.5209 17.5922 28.7125 17.7838C28.9042 17.9755 29 18.213 29 18.4963V25.4963C29 26.5963 28.6084 27.538 27.825 28.3213C27.0417 29.1047 26.1 29.4963 25 29.4963H17.95ZM19 21.4963V15.4963C19 15.213 19.0959 14.9755 19.2875 14.7838C19.4792 14.5922 19.7167 14.4963 20 14.4963C20.2834 14.4963 20.5209 14.5922 20.7125 14.7838C20.9042 14.9755 21 15.213 21 15.4963V21.4963H19ZM23 21.4963V16.4963C23 16.213 23.0959 15.9755 23.2875 15.7838C23.4792 15.5922 23.7167 15.4963 24 15.4963C24.2834 15.4963 24.5209 15.5922 24.7125 15.7838C24.9042 15.9755 25 16.213 25 16.4963V21.4963H23Z"
          fill="white"
        />
      </g>
    </svg>
  );
};
export default FingerSvg;
