import React from "react";

import crypto from "../../Assets/Rcvs/Plateform/crypto.svg";
import vign2 from "../../Assets/Rcvs/deliveroo/vign2.png"
import vign3 from "../../Assets/Rcvs/deliveroo/vign3.png"
// import chefaccording from "../../Assets/Rcvs/Plateform/chefaccording.png";
// import help from "../../Assets/Rcvs/Plateform/help.png";
import image1 from "../../Assets/Rcvs/Plateform/image1.png";
import image2 from "../../Assets/Rcvs/Plateform/image2.png";
import image3 from "../../Assets/Rcvs/Plateform/image3.svg";
import image4 from "../../Assets/Rcvs/Plateform/image4.png";
import image5 from "../../Assets/Rcvs/Plateform/image5.png";
import pack from "../../Assets/Rcvs/Plateform/pack.png";
// import pack from "../../Assets/Rcvs/Plateform/pack.png";
import { useNavigate } from "react-router-dom";
import SecondButton from "../../Reusable/LandingButton/SecondButton";
import CalendlyButtonGreen from "../../Reusable/LandingButton/CalendlyButton";
import CalendlyButtonSecondlevel from "../../Reusable/LandingButton/CalendlyButtonSecondlevel";

//import SecondButton from "../../Reusable/LandingButton/SecondButton";

const Deliveroo = () => {
  const navigate = useNavigate();

  return (
    <div className="">
      {/*---------------------------------------------------- Container ---------------------------------------------------*/}

      <div className="w-[80%] mx-auto  flex flex-col  xsm:w-[90%] ">
        {/*-------------------------------------------------- Main Heading ----------------------------------------------- */}

        <div className="flex flex-col items-center justify-center gap-y-10 mt-10 ">
          <h1 className="Brevia850 text-center text-[#114653]">
          Smetti di regalare soldi e clienti a Deliveroo. <br/>
          Fai delivery e asporto dal tuo sito con Restaurants Club.
          </h1>
          <p className="pop500 text-center xl:w-[70%] lg:w-[70%]">
          Spesso pensi: “non ci guadagno, ma almeno non ci vado in perdita”? Smettila di accontentarti! 
          Inizia a guadagnare con il delivery e l’asporto direttamente dal tuo sito. 
          </p>

          <div className="pb-10">
            <CalendlyButtonGreen
              url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club"
              text="Prenota una chiamata"
            />
          </div>
        </div>

        {/* Section 2 */}
        <div className="flex flex-col items-center justify-center gap-y-8 ">
          <h3 className="Brevia700 italic	text-[#114653] text-center ">
            Perché scegliere Restaurants Club e non i marketplace come Just Eat?
          </h3>
        </div>

        <div className="row flex flex-row xsm:flex-col sm:flex-col  mt-10  justify-center gap-8 md:gap-5">
          <div className="w-1/3 mx-auto md:w-[100%] sm:w-[100%] xsm:w-[90%]  ">
            <div className="w-[60%]  mx-auto flex justify-center ">
              <img  src={crypto} className=""  alt="" />
            </div>
            <div className="text-center mt-10">
              <h5 className="pop700 mb-2">Nessuna Commissione. Sempre.</h5>
              <p className="pop500">
                Niente più commissioni o sconti folli: Paga solo per quello che
                ti serve davvero. Con noi non avrai mai brutte sorprese.
              </p>
            </div>
          </div>

          <div className="w-1/3 mx-auto md:w-[100%] sm:w-[100%] xsm:w-[90%]  ">
            <div className="w-[60%] mx-auto flex justify-center ">
              <img src={vign2} alt="-" />
            </div>
            <div className="text-center mt-10">
              <h5 className="pop700 mb-2">
                I clienti sono tuoi, non di Deliveroo.
              </h5>
              <p className="pop500">
              Gestisci la tua base clienti autonomamente attraverso gli strumenti della nostra piattaforma
              </p>
            </div>
          </div>

          <div className="w-1/3 mx-auto md:w-[100%] sm:w-[100%] xsm:w-[90%]  ">
            <div className="w-[60%] mx-auto flex justify-center ">
              <img src={vign3} alt="-" />
            </div>
            <div className="text-center mt-10">
              <h5 className="pop700 mb-2">
                Non un marketplace. Il tuo marketing.
              </h5>
              <p className="pop500">
                Con Restaurants Club ottieni un sito che risponde alle esigenze
                del tuo ristorante
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="px-20 mt-10 md:p-0 sm:p-0 xsm:p-0  bg-[#E5FFDD]">
        <div className="container m-auto p-20 md:w-[100%] md:p-12 md:max-w-none md:text-center xsm:w-[100%] xsm:p-12 xsm:max-w-none xsm:text-center sm:w-[100%] sm:p-12 sm:max-w-none sm:text-center">
          <h6 className="text-[#00B17A] Brevia850">
            “Ora è molto più semplice prenotare per i clienti. Le persone
            riconoscono il mio locale, vedono l'insegna e chiedono subito un
            tavolo.”
          </h6>
          <p className="pop500 text-lg mt-5">
            Daniele, titolare del Ristorante “Il Canguro” a Pisa
          </p>
        </div>
      </div>

      {/* Container 2 */}
      <div className="w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] mx-auto  flex flex-col ">
        {/* Section 5 Row Reverse*/}
        <div className="flex mt-20 flex-row gap-6 md:flex-col sm:flex-col   xsm:flex-col ">
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full content-center flex ">
            <img
              src={image1}
              alt="-"
              className=" mx-auto self-center flex "
            />
          </div>
          <div className="w-6/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center ">
            <h3 className="Brevia700 italic mb-5 ">
              “Ma con Deliveroo ho molta visibilità e ricevo ordini: mi serve per
              farmi conoscere”
            </h3>
            <p className="pop500 ">
            La visibilità che ottieni non è tua ma della piattaforma. <br/>
            Deliveroo guadagna in base a quanti utenti prenotano, indipendentemente dal ristorante, 
            applicando delle commissioni su i tuoi ordini ricevuti.
            </p>
            <p className="pop500 ">
            I clienti non si ricordano di te ma di Deliveroo. <br/>
            Fai in modo che i clienti ti scelgano e vengano da te perché riconoscono il valore del 
            tuo locale e dei tuoi piatti. Diventa il primo nelle ricerche.
            </p>
            
            <div className="flex xsm:justify-center sm:justify-center md:justify-center  my-5">
              <SecondButton
                onClick={() => navigate("/espresso")}
                text="Scopri di più"
              />
            </div>
          </div>
        </div>
        {/* Section 4 row */}
        <div className="flex mt-20 flex-row gap-10 md:flex-col-reverse sm:flex-col-reverse xsm:flex-col-reverse ">
          <div className="w-6/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center">
            <h3 className="Brevia700 italic  mb-5 ">
              “Nessuna commissione? E quindi quanto mi costa?”
            </h3>

            <p className="pop500 my-3 ">
              I nostri prezzi sono fissi e in chiaro. Ti iscrivi online in un
              click, disdici facilmente senza impegno e costi di cancellazione
              Con Restaurants Club non avrai mai nessuna sorpresa! 
            </p>

            <p className="pop500 my-3 ">
              I nostri prezzi cambiano in base a quanti pacchetti decidi di acquistare e
              alla cadenza con cui vuoi effettuare i pagamenti: trimestrale o
              annuale.
            </p>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center py-10">
              <SecondButton
                onClick={() => navigate("/prezzi")}
                text="Quanto costa?"
              />
            </div>
          </div>
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full  content-center flex">
            <img
              src={pack}
              alt=""
              className=" mx-auto self-center flex "
            />
          </div>
        </div>

        {/* Section 5 Row Reverse*/}
        <div className="flex mt-20 flex-row gap-6 md:flex-col sm:flex-col   xsm:flex-col ">
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full content-center flex ">
            <img
              src={image2}
              alt="-"
              className=" mx-auto self-center flex "
            />
          </div>
          <div className="w-6/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center ">
            <h3 className="Brevia700 italic mb-5 ">
              “E con i contatti dei clienti cosa dovrei farci? A cosa mi servono
              alla fine?”
            </h3>
            <p className="pop500 ">
              I contatti dei tuoi clienti sono lo strumento fondamentale per far
              si che clienti occasionali si trasformino in clienti fedeli.
              Coccolali con promozioni speciali a loro dedicati e tienili
              aggiornati sulle tue ultime novità via mail. Con Restaurans Club,
              inoltre, ti aiutiamo a scoprire quali sono i tuoi “clienti-tipo” e
              i tuoi “clienti potenziali” e creare campagne marketing
              indirizzate a loro.
            </p>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center  my-5">
              <SecondButton
                onClick={() => navigate("/scopri-i-tuoi-clienti")}
                text="Scopri il “Cliente-tipo”"
              />
            </div>
          </div>
        </div>

        {/* Section 6 row */}
      </div>

      <div className="px-10 mt-10 md:p-0 sm:p-0 xsm:p-0  bg-[#E5FFDD]">
        <div className="container m-auto p-20 md:w-[100%] md:p-12 md:max-w-none md:text-center sm:w-[100%] sm:p-12 sm:max-w-none sm:text-center xsm:w-[100%] xsm:p-12 xsm:max-w-none xsm:text-center">
          <h6 className="text-[#00B17A] Brevia850">
            “Siamo cresciuti con i like sulle pagine social e ci stanno
            contattando davvero tante persone. Dire che sono soddisfatto è
            poco.”
          </h6>
          <p className="pop500  mt-5">
            Francesco, titolare del Ristorante “Il Piatto Pronto” di Bari
          </p>
        </div>
      </div>

      {/* Container 3 */}
      <div className="w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] mx-auto  flex flex-col ">
        {/* Section 5 Row Reverse*/}
        <div className="flex mt-20 flex-row gap-6 md:flex-col sm:flex-col   xsm:flex-col ">
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full content-center flex ">
            <img
              src={image3}
              alt="-"
              className=" mx-auto self-center flex "
            />
          </div>
          <div className="w-6/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center ">
            <h3 className="Brevia700 italic mb-5 ">
              “Quindi devo togliere Deliveroo per iscrivermi a Restaurants Club?
              Cosa mi garantite?”
            </h3>
            <p className="pop500 ">
            No! Puoi attivare Restaurants Club mantenendo il tuo profilo su JustEat. <br/>
            Noi ti garantiamo risultati veri e misurabili nell’incremento di visibilità e ordini delivery. <br/>
            Sappiamo che con Restaurants Club avrai una crescita forte e stabile. Sarai poi tu, in autonomia, 
            a confrontare e decidere se è conveniente mantenere un abbonamento ad una piattaforma che dimezza il valore del tuo lavoro.
            </p>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center  my-5">
              <SecondButton
                onClick={() => navigate("/register")}
                text="Iscriviti ora"
              />
            </div>
          </div>
        </div>
        {/* Section 4 row */}
        <div className="flex mt-20 flex-row gap-10 md:flex-col-reverse sm:flex-col-reverse xsm:flex-col-reverse ">
          <div className="w-6/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center">
            <h3 className="Brevia700 italic  mb-5 ">
              “Ma i clienti mi dicono che Deliveroo pubblicizza sui social anche
              il mio ristorante”
            </h3>

            <p className="pop500 ">
              Fare pubblicità per il tuo ristorante attraverso i tuoi profili
              social, invece di affidarsi a quelli di piattaforme intermediarie
              come JustEat, ti permette di catturare l’attenzione dei tuoi
              clienti-tipo con le tue pubblicità e:
            </p>
            <div className="m-5">
              <ul className="list-disc pop500  ">
                <li className="md:text-left sm:text-left xsm:text-left ">
                  {" "}
                  spingerli a visitare i tuoi profili social e non a quelli di
                  JustEat, così che più potenziali clienti si ricordino di te
                </li>
                <li className="md:text-left sm:text-left xsm:text-left ">
                  indirizzarli a compiere l’azione che TU vuoi: vedere il tuo
                  menù, prenotare un tavolo oppure fare asporto o delivery
                </li>
              </ul>
            </div>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center py-10">
              <CalendlyButtonSecondlevel url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club" text="Scopri di più"/>
            </div>
          </div>
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full  content-center flex">
            <img
              src={image4}
              alt=""
              className=" mx-auto self-center flex "
            />
          </div>
        </div>

        {/* Section 5 Row Reverse*/}
        <div className="flex mt-20 flex-row gap-6 md:flex-col sm:flex-col xsm:flex-col ">
          <div className="w-5/12 xsm:w-full sm:w-full md:w-full content-center flex ">
            <img
              src={image5}
              alt="-"
              className=" mx-auto self-center flex "
            />
          </div>
          <div className="w-6/12 xsm:w-full sm:w-full md:w-full xmd:w-full md:text-center sm:text-center xsm:text-center ">
            <h3 className="Brevia700 italic mb-5 ">
              “ Quindi come faccio a far ordinare la gente dal mio sito?”
            </h3>
            <p className="pop500 ">
              Il sito che riceverai grazie a Restaurants CLub sarà già pronto
              per ricevere gli ordini e prenotazioni dei tuoi clienti. Per
              aumentare gli ordini dei tuoi clienti fedeli e accrescere la tua
              base clienti potrai creare campagne pubblicitarie a loro dedicate
              su Instagram e Facebook sfruttando il budget marketing incluso nel
              tuo abbonamento.
            </p>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center  my-5">
              <SecondButton
                onClick={() => navigate("/tavola")}
                text="Ricevi prenotazioni"
              />
            </div>
          </div>
        </div>

        {/* Section 6 row */}
      </div>
      <div class="w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] mx-auto  flex flex-col ">
        <div className="py-10 md:py-24 sm:py-20 xsm:py-20">
          <h3 className="Brevia850 text-center 	text-[#114653] mb-4 ">
            Hai ancora dei dubbi su Restaurants Club?
          </h3>
          <p className="pop400 pb-8 text-center  ">
            Prenota una chiamata con un nostro esperto per scoprire come
            Restaurants Club
            <br className="md:hidden sm:hidden xsm:hidden" /> può aiutare il tuo
            ristorante. Saremo felici di rispondere alle tue domande.
          </p>
          <div className="flex flex-col items-center justify-center gap-y-20">
            <CalendlyButtonGreen
              url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club"
              text="Prenota una chiamata"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deliveroo;
