import React from "react";

export default function PdfDocuments() {

  return (

    <div className='flex flex-row w-full justify-center items-center my-12' >

      <a href={require('./ALLAalContrattodLicenzadusodelSoftwareeservizicorrelati.pdf')} target="_blank">

        <p className='text-black pop400 text-xl '>Leggi qui </p>
        <p className='text-[#00B17A] Brevia700 text-lg hover:cursor-pointer hover:underline'>ALLEGATO A del Contratto di Licenza d'uso del Software e Servizi correlati</p>
      </a>

    </div>

  );

}