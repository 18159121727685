const EnvelopeSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <mask
        id="mask0_0_7620"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="25"
        height="25"
      >
        <rect
          x="0.00012207"
          y="0.370239"
          width="24"
          height="24"
          fill="#D9D9D9"
        />
      </mask>
      <g mask="url(#mask0_0_7620)">
        <path
          d="M4.00012 20.3702C3.45012 20.3702 2.97929 20.1744 2.58762 19.7827C2.19596 19.3911 2.00012 18.9202 2.00012 18.3702V6.37024C2.00012 5.82024 2.19596 5.34941 2.58762 4.95774C2.97929 4.56607 3.45012 4.37024 4.00012 4.37024H20.0001C20.5501 4.37024 21.021 4.56607 21.4126 4.95774C21.8043 5.34941 22.0001 5.82024 22.0001 6.37024V18.3702C22.0001 18.9202 21.8043 19.3911 21.4126 19.7827C21.021 20.1744 20.5501 20.3702 20.0001 20.3702H4.00012ZM12.0001 13.3702L4.00012 8.37024V18.3702H20.0001V8.37024L12.0001 13.3702ZM12.0001 11.3702L20.0001 6.37024H4.00012L12.0001 11.3702ZM4.00012 8.37024V6.37024V18.3702V8.37024Z"
          fill="#475467"
        />
      </g>
    </svg>
  );
};
export default EnvelopeSvg;
