import React from "react";

const FirstButton = ( { onClick, text } ) => {

  return (

    <div className="">

      <button onClick={onClick} className="w-full px-12 py-3 xsm:px-6 xsm:py-4 sm:px-6 sm:py-4 md:px-4 pop600 bg-[#DCFFCF] border-[3px] border-black "> {text} </button>
            
    </div>

  )
}

export default FirstButton



