import i18n from "i18next";
import translationEN from "./en/translation.json";
import translationIT from "./it/translation.json";
import translationFR from "./fr/translation.json";
import translationES from "./es/translation.json";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translations: translationEN,
    },
    it: {
      translations: translationIT,
    },
    fr: {
      translations: translationFR,
    },
    es: {
      translations: translationES,
    },
  },
  fallbackLng: "it",
  debug: false,
  saveMissing: true,
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: ".",
  load: "languageOnly", // we only provide en, de -> no region specific locals like en-US, de-DE
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
