import React from "react";
import { PopupButton } from "react-calendly";

const CalendlyButtonGreen = ( { url, text } ) => {

  return (

    <div className="flex w-fit h-max ">

      <PopupButton
        url={url}
        rootElement={document.getElementById("root")}
        text={text}
        className="w-fit h-max px-12 py-3 xsm:px-6 xsm:py-4 sm:px-6 sm:py:4 pop600 bg-[#DCFFCF] border-[3px] border-black"
      />
            
    </div>

  )
}

export default CalendlyButtonGreen



            