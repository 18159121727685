const BicycleSvg = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={18} fill="black">
      <mask
        id="a"
        width={18}
        height={18}
        x={0}
        y={0}
        maskUnits="userSpaceOnUse"
        style={{
          maskType: "alpha",
        }}
      >
        <path fill="#D9D9D9" d="M0 0h18v18H0z" />
      </mask>
      <g mask="url(#a)">
        <path
          fill="#00B27A"
          d="M11.625 4.125c-.412 0-.766-.147-1.06-.44a1.445 1.445 0 0 1-.44-1.06c0-.413.147-.766.44-1.06.294-.293.648-.44 1.06-.44.412 0 .766.147 1.06.44.293.294.44.647.44 1.06 0 .413-.147.766-.44 1.06-.294.293-.648.44-1.06.44ZM8.1 7.875 9.75 9.6v4.65h-1.5V10.5l-2.4-2.1a1.201 1.201 0 0 1-.356-.469A1.62 1.62 0 0 1 5.4 7.35c0-.225.034-.416.103-.572.069-.156.184-.315.347-.478l2.1-2.1c.163-.163.334-.278.516-.347.18-.069.384-.103.609-.103.225 0 .428.034.61.103.18.069.352.184.515.347l1.425 1.425c.338.338.731.61 1.181.816.45.206.957.309 1.519.309v1.5c-.787 0-1.5-.14-2.137-.422A5.51 5.51 0 0 1 10.5 6.675l-.6-.6-1.8 1.8ZM3.75 9c1.063 0 1.953.36 2.672 1.078.719.719 1.078 1.61 1.078 2.672 0 1.063-.36 1.953-1.078 2.672-.719.719-1.61 1.078-2.672 1.078-1.063 0-1.953-.36-2.672-1.078C.36 14.703 0 13.812 0 12.75c0-1.063.36-1.953 1.078-2.672C1.797 9.36 2.688 9 3.75 9Zm0 6.375c.713 0 1.328-.26 1.847-.778.519-.519.778-1.134.778-1.847 0-.713-.26-1.328-.778-1.847-.519-.519-1.135-.778-1.847-.778s-1.328.26-1.847.778c-.519.519-.778 1.134-.778 1.847 0 .713.26 1.328.778 1.847.519.519 1.135.778 1.847.778ZM14.25 9c1.063 0 1.953.36 2.672 1.078.719.719 1.078 1.61 1.078 2.672 0 1.063-.36 1.953-1.078 2.672-.719.719-1.61 1.078-2.672 1.078-1.063 0-1.953-.36-2.672-1.078-.719-.719-1.078-1.61-1.078-2.672 0-1.063.36-1.953 1.078-2.672C12.297 9.36 13.188 9 14.25 9Zm0 6.375c.713 0 1.328-.26 1.847-.778.519-.519.778-1.134.778-1.847 0-.713-.26-1.328-.778-1.847-.519-.519-1.135-.778-1.847-.778-.713 0-1.328.26-1.847.778-.519.519-.778 1.134-.778 1.847 0 .713.26 1.328.778 1.847.519.519 1.134.778 1.847.778Z"
        />
      </g>
    </svg>
  );
};
export default BicycleSvg;
