import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';

import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';

import IconButton from '@mui/material/IconButton';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import TabCheck from '../../../Assets/TabCheck.svg';
import Meno from '../../../Assets/Xtab.svg';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function RecipeReviewCard() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (

<>

<div className="bg-[#E5FFDD]  flex flex-col h-10  relative">
                    <p className="text-[#114653] Brevia700 text-2xl text-center pt-1.5">L'Espresso</p>
                </div>   
<div className=" p-1 -mt-24 ">



<Card  className="border-4 border-black flex flex-col ">



      <CardContent>



      <div className=' mt-12 mb-2'>
                        <p className="text-black pop500 text-sm text-center italic" >Coinvolgi gli utenti nel Delivery</p>

                        <p className="text-black pop700 text-sm mt-3 ">Servizi specifici inclusi </p>
                    </div>

                    <div className=' flex flex-col gap-y-2 my-3'>


                        <div className='flex flex-row  '><img src={TabCheck} className='w-5 h-5 mx-2' alt="" /><p className="text-black pop400 text-sm " >Campagne Marketing per il Delivery</p></div>
                        <div className='flex flex-row '><img src={TabCheck} className='w-5 h-5 mx-2' alt="" /><p className="text-black pop400 text-sm " >Pannello gestione delivery</p></div>
                        <div className='flex flex-row '><img src={TabCheck} className='w-5 h-5 mx-2' alt="" /><p className="text-black pop400 text-sm " >E-commerce per Delivery e Asporto</p></div>
                        <div className='flex flex-row '><img src={TabCheck} className='w-5 h-5 mx-2' alt="" /> <p className="text-black pop400 text-sm " >Pannello gestione rider</p></div>
                        <div className='flex flex-row '><img src={TabCheck} className='w-5 h-5 mx-2' alt="" /><p className="text-black pop400 text-sm " >Rider App per i tuoi rider</p></div>
                        <div className='flex flex-row '><img src={TabCheck} className='w-5 h-5 mx-2' alt="" /><p className="text-black pop400 text-sm " >Template Social e Mail per delivery</p></div>
                        <div className='flex flex-row'><img src={TabCheck} className='w-5 h-5 mx-2' alt="" /> <p className="text-black pop400 text-sm " >Mail Automatiche per gli ordini</p></div>
                        <div className='flex flex-row '><img src={TabCheck} className='w-5 h-5 mx-2' alt="" /><p className="text-black pop400 text-sm " >Testi suggeriti per il delivery</p></div>
                    </div>
      </CardContent>


      <CardActions disableSpacing>
      <p className="text-xl pop700 ml-3">Altri servizi</p>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}

        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>


      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
        <div className=' flex flex-col gap-y-2 my-3'>


<div className='flex flex-row  '><img src={TabCheck} className='w-5 h-5 mx-2' alt="" /><p className="text-black pop400 text-sm " >Funzione “Scopri i tuoi Clienti”</p></div>
<div className='flex flex-row '><img src={TabCheck} className='w-5 h-5 mx-2' alt="" /><p className="text-black pop400 text-sm " >Strumenti per Social Media</p></div>
<div className='flex flex-row '><img src={Meno} className='w-5 h-5 mx-2' alt="" /><p className="text-black pop400 text-sm " >Campagne Marketing per le Prenotazioni</p></div>
<div className='flex flex-row '><img src={Meno} className='w-5 h-5 mx-2' alt="" /> <p className="text-black pop400 text-sm " >Campagne Marketing per Traffico Locale</p></div>
<div className='flex flex-row '><img src={Meno} className='w-5 h-5 mx-2' alt="" /><p className="text-black pop400 text-sm " >Ottimizzazione Scheda GMB</p></div>
<div className='flex flex-row '><img src={Meno} className='w-5 h-5 mx-2' alt="" /><p className="text-black pop400 text-sm " >Accetta Prenotazioni online</p></div>
<div className='flex flex-row'><img src={TabCheck} className='w-5 h-5 mx-2' alt="" /> <p className="text-black pop400 text-sm " >Sito e App (Dominio e Hosting)</p></div>
<div className='flex flex-row '><img src={TabCheck} className='w-5 h-5 mx-2' alt="" /><p className="text-black pop400 text-sm " >Menù Digitale con QRCode</p></div>
<div className='flex flex-row '><img src={Meno} className='w-5 h-5 mx-2' alt="" /><p className="text-black pop400 text-sm " >Grafiche per Social e Mail per le Prenotazioni </p></div>
<div className='flex flex-row'><img src={Meno} className='w-5 h-5 mx-2' alt="" /> <p className="text-black pop400 text-sm " >Grafiche per Social e Mail per il traffico locale</p></div>
<div className='flex flex-row '><img src={TabCheck} className='w-5 h-5 mx-2' alt="" /><p className="text-black pop400 text-sm " >Lista Cliente Digitale</p></div>
</div>
        </CardContent>
      </Collapse>


</Card>
</div>



             
</>

  );
}
