import React from "react";

import client from "../../Assets/Rcvs/Plateform/client.png"

import good from "../../Assets/Rcvs/Plateform/good.svg";
import statics from "../../Assets/Rcvs/Plateform/statics.svg";
import help from "../../Assets/Rcvs/Plateform/help.png";
import image1 from "../../Assets/Rcvs/Plateform/image1.png";
import image2 from "../../Assets/Rcvs/Plateform/image2.png";

import chefsalta from "../../Assets/Rcvs/TheFork/chefsalta.svg"
import classifica from "../..//Assets/Rcvs/Plateform/classifica.png"

import pack from "../../Assets/Rcvs/Plateform/pack.png";
import { useNavigate } from "react-router-dom";
import CalendlyButtonGreen from "../../Reusable/LandingButton/CalendlyButton";

import SecondButton from "../../Reusable/LandingButton/SecondButton";
import CalendlyButtonSecondlevel from "../../Reusable/LandingButton/CalendlyButtonSecondlevel";

const PienissimoPro = () => {
  const navigate = useNavigate();

  return (
    <div className="">
      {/*---------------------------------------------------- Container ---------------------------------------------------*/}

      <div className="w-[80%] mx-auto  flex flex-col  xsm:w-[90%] ">
        {/*-------------------------------------------------- Main Heading ----------------------------------------------- */}

        <div className="flex flex-col items-center justify-center gap-y-10 mt-10 ">
          <h1 className="Brevia850 text-center text-[#114653]">
          Con PienissimoPRO hai un’agenda digitale. Con Restaurants Club porta tutto il tuo Ristorante online.
          </h1>
          <p className="pop500 text-center xl:w-[70%] lg:w-[70%]">
          Pienissimo PRO ti dà un metodo per far tornare i clienti ma non ti aiuta nel portarne di nuovi. Con Restaurants Club fai campagne efficaci e ricevi prenotazioni, delivery e asporto dal tuo sito senza commissioni.
          </p>

          <div className="pb-10">
            <CalendlyButtonGreen
              url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club"
              text="Prenota una chiamata"
            />
          </div>
        </div>

        {/* Section 2 */}
        <div className="flex flex-col items-center justify-center gap-y-8 ">
          <h3 className="Brevia700 italic	text-[#114653] text-center ">
            Perché scegliere Restaurants Club e non PienissimoPro?
          </h3>
        </div>

        <div className="row flex flex-row xsm:flex-col sm:flex-col  mt-10  justify-center gap-8 md:gap-5">
          <div className="w-1/3 mx-auto md:w-[100%] sm:w-[100%] xsm:w-[90%]  ">
            <div className="w-[60%]  mx-auto flex justify-center ">
              <img  src={client} className=""  alt="" />
            </div>
            <div className="text-center mt-10">
              <h5 className="pop700 mb-2">Fai crescere la tua base clienti </h5>
              <p className="pop500">
              Fai crescere la tua base clienti con gli strumenti marketing di Restaurants CLub e trasformali in clienti fedeli innamorati della tua cucina.
              </p>
            </div>
          </div>

          <div className="w-1/3 mx-auto md:w-[100%] sm:w-[100%] xsm:w-[90%]  ">
            <div className="w-[60%] mx-auto flex justify-center ">
              <img src={statics} alt="-" />
            </div>
            <div className="text-center mt-10">
              <h5 className="pop700 mb-2">
              Marketing integrato per il ristorante
              </h5>
              <p className="pop500">
              Creane campagne pubblicitarie sui toui social  con il supporto della nostra intelligenza artificiale, gestiscile attraverso gli strumenti della nostra piattaforma, visiona poi i risultati ottenuti.
              </p>
            </div>
          </div>

          <div className="w-1/3 mx-auto md:w-[100%] sm:w-[100%] xsm:w-[90%]  ">
            <div className="w-[60%] mx-auto flex justify-center ">
              <img src={good} alt="-" />
            </div>
            <div className="text-center mt-10">
              <h5 className="pop700 mb-2">
              Cresci sui social e attira clienti
              </h5>
              <p className="pop500">
              Cresci sui social, attira clienti grazie a tempalte grafici personalizzabili per i tuoi post, converti i follower in clienti attraverso un sito web che gli permette di ordinare piatti del tuo menù o prenotare un tavolo.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="px-20 mt-10 md:p-0 sm:p-0 xsm:p-0  bg-[#E5FFDD]">
        <div className="container m-auto p-20 md:w-[100%] md:p-12 md:max-w-none md:text-center xsm:w-[100%] xsm:p-12 xsm:max-w-none xsm:text-center sm:w-[100%] sm:p-12 sm:max-w-none sm:text-center">
          <h6 className="text-[#00B17A] Brevia850">
            “Ora è molto più semplice prenotare per i clienti. Le persone
            riconoscono il mio locale, vedono l'insegna e chiedono subito un
            tavolo.”
          </h6>
          <p className="pop500 text-lg mt-5">
            Daniele, titolare del Ristorante “Il Canguro” a Pisa
          </p>
        </div>
      </div>

      {/* Container 2 */}
      <div className="w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] mx-auto  flex flex-col ">
        {/* Section 5 Row Reverse*/}
        <div className="flex mt-20 flex-row gap-6 md:flex-col sm:flex-col   xsm:flex-col ">
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full content-center flex ">
            <img
              src={image2}
              alt="-"
              className=" mx-auto self-center flex "
            />
          </div>
          <div className="w-6/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center ">
            <h3 className="Brevia700 italic mb-5 ">
            “Come mi aiuta Restaurants Club nell’avere più clienti?”
            </h3>
            <p className="pop500 ">
            Restaurants Club ti aiuta ad avere più clienti raggiungendo quelli veramente interessati a te, online e offline, con uno studio del tuo cliente-tipo. <br/>
            Inoltre, con il nostro pacchetto “Il Viandante” portare più persone al tuo locale è molto più semplice: prima ti scoprono online e poi ti vengono a cercare. <br/>
            Riconoscendoti vengono subito al tuo ristorante!
            </p>
            
            <div className="flex xsm:justify-center sm:justify-center md:justify-center  my-5">
              <SecondButton
                onClick={() => navigate("/viandante")}
                text="Scopri di più"
              />
            </div>
          </div>
        </div>
        {/* Section 4 row */}
        <div className="flex mt-20 flex-row gap-10 md:flex-col-reverse sm:flex-col-reverse xsm:flex-col-reverse ">
          <div className="w-6/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center">
            <h3 className="Brevia700 italic  mb-5 ">
            “Cosa significa Campagne Marketing sui social?”
            </h3>

            <p className="pop500 my-3 ">
            Le Campagne Marketing sono dei grandi Cartelloni Pubblicitari che parlano di te e del tuo locale: solo più efficaci, più economici e raggiungono i tuoi clienti dove spendono la maggior parte del loro tempo.
            </p>

            <p className="pop500 my-3 ">
            Con Restaurans Club, inoltre, ti aiutiamo a scoprire quali sono i tuoi “<span className='pop700'>clienti-tipo</span>” e i tuoi “<span className='pop700'>clienti potenziali</span>”  per poter creare campagne marketing indirizzate proprio a loro.
            </p>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center py-10">
              <SecondButton
                onClick={() => navigate("/scopri-i-tuoi-clienti")}
                text="Quanto costa?"
              />
            </div>
          </div>
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full  content-center flex">
            <img
              src={image1}
              alt=""
              className=" mx-auto self-center flex "
            />
          </div>
        </div>

        {/* Section 5 Row Reverse*/}
        <div className="flex mt-20 flex-row gap-6 md:flex-col sm:flex-col   xsm:flex-col ">
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full content-center flex ">
            <img
              src={help}
              alt="-"
              className=" mx-auto self-center flex "
            />
          </div>
          <div className="w-6/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center ">
            <h3 className="Brevia700 italic mb-5 ">
            “Ma io non son bravo coi Social o a fare Marketing: come faccio?” 
            </h3>
            <p className="pop500 ">
            Le campagne marketing sono già pre-ottimizzate con descrizioni suggerite da usare per i tuoi social. <span className='pop700'>Basta un click e sei online</span>. <br/>
            Sarai sempre affiancato da un nostro <span className='pop700'>Consulente di Marketing</span> che ti guiderà all’interno di Restaurants Club. <br/>
            Se sei <span className='pop700'>interessato</span> ad approfondire le tue conoscenze, segui la nostra <span className='pop700'>Academy</span>: un corso per imparare a usare Restaurants Club, composto da video di pochi minuti. È inclusa nel servizio!
            </p>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center  my-5">
            <CalendlyButtonSecondlevel url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club" text="Scopri di più"/>
            </div>
          </div>
        </div>

        {/* Section 6 row */}
      </div>

      <div className="px-10 mt-10 md:p-0 sm:p-0 xsm:p-0  bg-[#E5FFDD]">
        <div className="container m-auto p-20 md:w-[100%] md:p-12 md:max-w-none md:text-center sm:w-[100%] sm:p-12 sm:max-w-none sm:text-center xsm:w-[100%] xsm:p-12 xsm:max-w-none xsm:text-center">
          <h6 className="text-[#00B17A] Brevia850">
            “Siamo cresciuti con i like sulle pagine social e ci stanno
            contattando davvero tante persone. Dire che sono soddisfatto è
            poco.”
          </h6>
          <p className="pop500  mt-5">
            Francesco, titolare del Ristorante “Il Piatto Pronto” di Bari
          </p>
        </div>
      </div>

      {/* Container 3 */}
      <div className="w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] mx-auto  flex flex-col ">
        {/* Section 5 Row Reverse*/}
        <div className="flex mt-20 flex-row gap-6 md:flex-col sm:flex-col   xsm:flex-col ">
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full content-center flex ">
            <img
              src={chefsalta}
              alt="-"
              className=" mx-auto self-center flex "
            />
          </div>
          <div className="w-6/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center ">
            <h3 className="Brevia700 italic mb-5 ">
            “Devo disdire PienissimoPRO per iscrivermi a Restaurants Club? Cosa mi garantite?”
            </h3>
            <p className="pop500 ">
            No! Puoi attivare Restaurants Club mantenendo PienissimoPro. Noi ti garantiamo risultati veri e misurabili nell’incremento di visibilità e clienti verso il tuo locale. 
            </p>
            <p className="pop500">
            Sappiamo che con Restaurants Club avrai una crescita forte e stabile. Sarai poi tu, in autonomia, a confrontare e decidere se è conveniente mantenere l’abbonamento ai servizi di PienissimoPro.
            </p>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center  my-5">
              <SecondButton
                onClick={() => navigate("/register")}
                text="Iscriviti ora"
              />
            </div>
          </div>
        </div>
        {/* Section 4 row */}
        <div className="flex mt-20 flex-row gap-10 md:flex-col-reverse sm:flex-col-reverse xsm:flex-col-reverse ">
          <div className="w-6/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center">
            <h3 className="Brevia700 italic  mb-5 ">
            “E se non son sicuro? Devo sempre vincolarmi anche qui per un anno?
            </h3>

            <p className="pop500 ">
            Assolutamente no! Restaurants Club ti offre piani di abbonamenti <span className='pop700'>flessibili</span> che si <span className='pop700'>adattano</span> alle tue esigenze.
            </p>
            <p className="pop500 ">
            Puoi scegliere un abbonamento mensile, trimestrale o annuale e, per ringraziarti della tua fiducia, scegliendo di abbonarti per lunghi periodi potrai beneficiare di uno <span className='pop700'>sconto</span>.
            </p>
            <p className="pop500">
            Il nostro servizio si compone di tre pacchetti che puoi scegliere in base ai tuoi obiettivi, così paghi solo quello che ti serve davvero. Puoi sempre disdire quando vuoi, senza penali, e riattivare il servizio quando preferisci.
            </p>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center py-10">
              <CalendlyButtonSecondlevel url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club" text="Scopri di più"/>
            </div>
          </div>
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full  content-center flex">
            <img
              src={pack}
              alt=""
              className=" mx-auto self-center flex "
            />
          </div>
        </div>

        {/* Section 5 Row Reverse*/}
        <div className="flex mt-20 flex-row gap-6 md:flex-col sm:flex-col xsm:flex-col ">
          <div className="w-5/12 xsm:w-full sm:w-full md:w-full content-center flex ">
            <img
              src={classifica}
              alt="-"
              className=" mx-auto self-center flex "
            />
          </div>
          <div className="w-6/12 xsm:w-full sm:w-full md:w-full xmd:w-full md:text-center sm:text-center xsm:text-center ">
            <h3 className="Brevia700 italic mb-5 ">
            “ Quindi in breve perchè mi conviene Restaurants Club?”            
            </h3>
            <p className="pop500 ">
            Con Restaurants Club hai una piattaforma che gestisce a 360 gradi la presenza digitale del tuo ristorante e ti aiuta a creare e gestire una solida base clienti online. 
            </p>
            <p className="pop500">
            Grazie a Restaurants CLub attrai potenziali clienti interessati al tuo ristorante,  converti i visitatori online in clienti e fidelizzi i tuoi clienti.
            </p>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center  my-5">
              <SecondButton
                onClick={() => navigate("/register")}
                text="Ricevi prenotazioni"
              />
            </div>
          </div>
        </div>

        {/* Section 6 row */}
      </div>
      <div class="w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] mx-auto  flex flex-col ">
        <div className="py-10 md:py-24 sm:py-20 xsm:py-20">
          <h3 className="Brevia850 text-center 	text-[#114653] mb-4 ">
            Hai ancora dei dubbi su Restaurants Club?
          </h3>
          <p className="pop400 pb-8 text-center  ">
            Prenota una chiamata con un nostro esperto per scoprire come
            Restaurants Club
            <br className="md:hidden sm:hidden xsm:hidden" /> può aiutare il tuo
            ristorante. Saremo felici di rispondere alle tue domande.
          </p>
          <div className="flex flex-col items-center justify-center gap-y-20">
            <CalendlyButtonGreen
              url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club"
              text="Prenota una chiamata"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PienissimoPro;
