import { useTranslation } from "react-i18next";
import HeroSection from "../Containers/Home/HeroSection";
import AdvantagesSection from "../Containers/Home/AdvantagesSection";
import React from "react";
import AttractiveSection from "../Containers/Home/AttractiveSection";
import AttractiveMobile from "../Containers/Home/AttractiveSection/AttraciveMobile";
import WebsiteAPPDomainSection from "../Containers/Home/WebsiteAPPDomainSection";
import FAQSection from "../Containers/Home/FAQSection";
import RestaurantHistorySection from "../Containers/Home/RestaurantHistorySection";
import ReachYourCustomersSection from "../Containers/Home/ReachYourCustomersSection";
import PackagesSection from "../Containers/Home/PackagesSection";
import LastSection from "../Containers/Home/LastSection";
import ScrollToTop from "../Reusable/ScrollToTop";

const Home = () => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <ScrollToTop />
      <HeroSection t={t} />
      <AdvantagesSection t={t} />
      <AttractiveSection t={t} />
      <AttractiveMobile t={t} />
      <FAQSection t={t} />
      <RestaurantHistorySection t={t} />
      <ReachYourCustomersSection t={t} />
      <LastSection t={t} />
    </React.Fragment>
  );
};
export default Home;
