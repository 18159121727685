import React from 'react';
import { FaPhoneAlt } from "react-icons/fa"
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const ContactNumberBanner = ({ t }) => {

  return (
    <>
      <div className='w-full py-10 bg-[#00B27A] flex flex-col items-center justify-evenly my-5'>
        <h3 className="Brevia850 flex flex-row items-center gap-x-2 text-white ">
          {" "}
          <WhatsAppIcon color="white" size={20} />{" "}
          <a href="https://wa.me/+393312910408" className=""> 331 291 0408 </a>{" "}
        </h3>
        <h3 className="Brevia850 flex flex-row items-center gap-x-2 text-white ">
          <FaPhoneAlt color="white" size={20} />{" "}
          <a href="tel:0500985159"> 050 0985159 </a>{" "}
        </h3>
      </div>
    </>
  )
}

export default ContactNumberBanner