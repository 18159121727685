import React, { useState, useRef } from "react";
import { useReactToPrint } from "react-to-print";

import ScrollToTop from "../../Reusable/ScrollToTop";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import Espresso from "./cardPacchetti/card";
import Viandante from "./cardPacchetti/card1";
import Tavola from "./cardPacchetti/card2";
import "./break.css";
import Gift from "../../Assets/GiftPricing.svg";
import TabCheck from "../../Assets/TabCheck.svg";
import Meno from "../../Assets/Xtab.svg";

import { useNavigate } from "react-router-dom";

import CreditCardIcon from "@mui/icons-material/CreditCard";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import Paypal from "./Creditcard.svg";
import Satispay from "./Accountbalance.svg";
import Plus from "./Plus.svg";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FirstButton from "../../Reusable/LandingButton/FirstButton";

import ScrollToButton from "../../Reusable/ScrollToButton";

import question1 from "./QuizImg/1.svg";
import question2 from "./QuizImg/2.svg";
import question3 from "./QuizImg/3.svg";
import question4 from "./QuizImg/4.svg";
import question5 from "./QuizImg/5.svg";
import question6 from "./QuizImg/6.svg";
import question7 from "./QuizImg/7.svg";

import answer1 from "./QuizImg/1.png";
import answer2 from "./QuizImg/2.png";
import answer3 from "./QuizImg/3.png";
import answer4 from "./QuizImg/4.png";
import answer5 from "./QuizImg/5.png";
import answer6 from "./QuizImg/6.png";
import answer7 from "./QuizImg/7.png";
import answer8 from "./QuizImg/8.png";
import answer9 from "./QuizImg/9.png";
import answer10 from "./QuizImg/10.png";

import answer16 from "./QuizImg/8.png";
import answer26 from "./QuizImg/2Prob.png";
import answer36 from "./QuizImg/3Prob.png";
import answer46 from "./QuizImg/4Prob.png";
import answer56 from "./QuizImg/5Prob.png";
import answer66 from "./QuizImg/6Prob.png";
import answer76 from "./QuizImg/7Prob.png";
import answer86 from "./QuizImg/8Prob.png";


import check from "./circlecheck.svg";
import indietro from "../../Assets/arrow_circle_left.png"
// import Pdf from "react-to-pdf";

const ref = React.createRef();

export default function Quiz1() {
  const navigate = useNavigate();

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "PREVENTIVO RC",
    onAfterPrint: () => {
      componentRef.current.style.width = "80%";
    },

    onBeforeGetContent: () => {
      componentRef.current.style.size = "210mm 297mm";
      componentRef.current.style.width = "100%";
    },
  });
  const [nome, setNome] = React.useState("");
  const [cognome, setCognome] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [telephone, setTelephone] = React.useState("");

  const questions = [
    {
      questionText: "Quali sono i tuoi obiettivi?",
      questionText1:
        "Seleziona ALMENO TRE obiettivi per trovare il piano su misura per te!",
      answerOptions: [
        {
          id: 1,
          answerImg: answer1,
          answerText: "Conoscere i miei clienti",
          vian: 0,
          esp: 0,
          tav: 0,
          select: 1,
        },
        {
          id: 2,   
          answerImg: answer2,
          answerText: "Attirare turisti e locali",
          vian: 1,
          esp: 0,
          tav: 0,
          select: 1,
        },
        {
          id: 3,
          
          answerImg: answer3,
          answerText: "Crescere sui social media",
          vian: 0,
          esp: 0,
          tav: 0,
          select: 1,
        },
        {
          id: 4,  
          answerImg: answer4,
          answerText: "Più notorietà  nella zona",
          vian: 1,
          esp: 0,
          tav: 0,
          select: 1,
        },
        {
          id: 5, 
          answerImg: answer5,
          answerText: "Rinnovare il mio sito",
          vian: 0,
          esp: 0,
          tav: 0,
          select: 1,
        },
        {
          id: 6,   
          answerImg: answer6,
          answerText: "Sto per aprire un locale",
          vian: 0,
          esp: 0,
          tav: 0,
          select: 1,
        },
        {
          id: 7,
          answerImg: answer7,
          answerText: "Marketing più efficace",
          vian: 0,
          esp: 0,
          tav: 0,
          select: 1,
        },
        {
          id: 8,
          answerImg: answer8,
          answerText: " Fare asporto/delivery online",
          vian: 0,
          esp: 1,
          tav: 0,
          select: 1,
        },
        {
          id: 9,
          answerImg: answer9,
          answerText: "Prenotazioni con Google",
          vian: 1,
          esp: 0,
          tav: 1,
          select: 1,
        },
        {
          id: 10,
          answerImg: answer10,
          answerText: "Delivery con Google",
          vian: 1,
          esp: 1,
          tav: 0,
          select: 1,
        },
      ],
    },
    {
      questionText: "Hai già un sito web?",
      questionImg: question2,
      answerOptions: [
        { answerText: "Sì, e ne sono contento", vian: 0, esp: 0, tav: 0 },
        { answerText: "Sì, ma NON sono contento", vian: 0, esp: 0, tav: 0 },
        { answerText: "Non ho un sito web", vian: 0, esp: 0, tav: 0 },
      ],
    },
    {
      questionText: "Hai già un’app per il tuo ristorante?",
      questionImg: question3,
      answerOptions: [
        { answerText: "Sì, e molti la usano", vian: 0, esp: 0, tav: 0 },
        { answerText: "Sì, ma pochi la usano molto", vian: 0, esp: 0, tav: 0 },
        { answerText: "Non ho un’app", vian: 0, esp: 0, tav: 0 },
      ],
    },
    {
      questionText: "Fai/vorresti fare delivery?",
      questionImg: question4,
      answerOptions: [
        {
          answerText: "Sì, da servizi come JustEat, Glovo o Deliveroo",
          vian: 0,
          esp: 1,
          tav: 0,
        },
        { answerText: "Sì, dal mio sito ", vian: 0, esp: 1, tav: 0 },
        { answerText: "Non voglio fare delivery", vian: 0, esp: 0, tav: 0 },
      ],
    },

    {
      questionText: "Ricevi/Vorresti ricevere le prenotazioni tavolo online?",
      questionImg: question5,
      answerOptions: [
        { answerText: "Sì, da servizi come TheFork ", vian: 0, esp: 0, tav: 1 },
        { answerText: "Sì, dal mio sito", vian: 0, esp: 0, tav: 1 },
        {
          answerText: "Non voglio prenotazioni online",
          vian: 0,
          esp: 0,
          tav: 0,
        },
      ],
    },

    {
      questionText: "Quali sono i tuoi problemi?",
      questionText1:
        "Selezionane ALMENO UNO in base a ciò che ritieni più rilevante.",
      answerOptions: [
        { answerText: "Commissioni Sul Delivery",  answerImg: answer16, vian: 0, esp: 0, tav: 0 },
        {
          answerText: "Tavolta ho pochi clienti, altri giorni sono troppi",  answerImg: answer26,
          vian: 0,
          esp: 0,
          tav: 0,
        },
        { answerText: "I clienti non si presentano",  answerImg: answer36, vian: 0, esp: 0, tav: 0 },
        { answerText: "Non ho base clienti fedele",  answerImg: answer46, vian: 0, esp: 0, tav: 0 },
        {
          answerText: "Costo alto delle agenzie",  answerImg: answer56,
          vian: 0,
          esp: 0,
          tav: 0,
          pp: true,
        },
        { answerText: "Commissioni Prenotazioni",  answerImg: answer66, vian: 0, esp: 0, tav: 0 },
        {
          answerText: "Non so fare Marketing",  answerImg: answer76,
          vian: 0,
          esp: 0,
          tav: 0,
          pp: true,
        },
        {
          answerText: "Non ho tempo per l’online",  answerImg: answer86,
          vian: 0,
          esp: 0,
          tav: 0,
          pp: true,
        },
      ],
    },
    // {
    //   questionText: "Vuoi salvare e/o ricevere il tuo preventivo via mail?",
    //   answerOptions: [
    //     {
    //       answerText: "Scrivi qui il tuo nome * ",
    //       placeholder: "Il tuo nome",
    //       name: "nome",
    //       type: "text",
    //       value: nome,
    //     },

    //     {
    //       answerText: "Scrivi qui il nome del tuo ristorante *",
    //       placeholder: "Il tuo cognome",
    //       name: "cognome",
    //       type: "text",
    //       value: cognome,
    //     },
    //     {
    //       answerText: "Scrivi qui il tuo telefono (opzionale)",
    //       placeholder: "Il tuo Numero di telefono",
    //       name: "telefono",
    //       type: "number",
    //       value: telephone,
    //     },
    //     {
    //       answerText: "Scrivi qui la tua mail (opzionale)",
    //       placeholder: "La tua mail",
    //       name: "mail",
    //       type: "email",
    //       value: email,
    //     },
    //   ],
    // },
  ];

  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [showScore, setShowScore] = useState(false);
  const [score, setScore] = useState(0);
  const [score1, setScore1] = useState(0);
  const [score2, setScore2] = useState(0);
  const [back, setback] = useState(false);

  const [scorepp, setScorepp] = useState(0);

  const [Annual, SetAnnual] = useState(2);

  const [theChosenAnswers, SetAnswers] = React.useState([]);

  let uniqueAnswers = [...new Set(theChosenAnswers)];

  function handleAddAnswer(answer) {
    const theAnswerIndex = theChosenAnswers.findIndex(
      (answerText) => answerText === answer
    );
    if (theAnswerIndex === -1) {
      SetAnswers((old) => old.concat(answer));
      return true;
    } else {
      const theRestOfTheAnswers = theChosenAnswers.filter(
        (answerText) => answerText !== answer
      );
      SetAnswers(theRestOfTheAnswers);
      return false;
    }
  }
  const handleAnswerOptionClick = (answerText, esp, vian, tav, pp) => {
    const answer = handleAddAnswer(answerText);
    if (answer && esp === 1) {
      setScore(score + 1);
    }

    if (!answer && esp === 1) {
      setScore(score - 1);
    }

    if (answer && vian === 1) {
      setScore1(score1 + 1);
    }
    if (!answer && vian === 1) {
      setScore1(score1 - 1);
    }

    if (answer && tav === 1) {
      setScore2(score2 + 1);
    }
    if (!answer && tav === 1) {
      setScore2(score2 - 1);
    }

    if (answer && pp === true) {
      setScorepp(scorepp + 1);
    }
    if (!answer && pp === true) {
      setScorepp(scorepp - 1);
    }
  };

  const avanti1 = () => {
    if (uniqueAnswers.length >= 3) {
      avanti();
    }
  };

  const avanti2 = () => {
    if (uniqueAnswers.length >= 8) {
      avanti();
    }
  };

  const avanti = () => {
    const nextQuestion = currentQuestion + 1;

    if (nextQuestion < questions.length) {
      setCurrentQuestion(nextQuestion);
      setback(false);
    } else {
      setShowScore(true);
    }
  };
  const Restituzione = () => {
    const prevQuestion = currentQuestion - 1;

    if (prevQuestion < questions.length) {
      setCurrentQuestion(prevQuestion);
      setback(true);
    }
  };

  function handleChange(e, index) {
    if (index === 0) setNome(e.target.value);
    if (index === 1) setCognome(e.target.value);
    if (index === 2) setTelephone(e.target.value);
    if (index === 3) setEmail(e.target.value);
  }

  return (
    <div className=" flex flex-col ">
      <ScrollToTop />
      {showScore ? (
        <div className="" ref={ref}>
          <ScrollToTop />

          <div className="w-full " ref={ref}>
            <div
              ref={componentRef}
              className="w-[80%] px-[40px] mx-auto xsm:w-full sm:w-full  md:w-full xsm:px-[20px] sm:px-[20px] xmd:w-full flex flex-col sm:mb-10  mb-[60px] gap-x-12  h-fit relative "
            >
              <div>
                <p className="Brevia700 text-4xl xsm:text-center sm:text-center font-black italic text-[#114653] p-5 text-left ">
                  In base alle tue esigenze
                </p>
              </div>

              <div className="flex flex-row gap-10 xsm:flex-col-reverse sm:flex-col-reverse md:flex-col-reverse  ">
                <div className=" w-[55%] xsm:w-full sm:w-full md:w-full xsm:-my-20 sm:-my-20 md:-my-20 xsm:mb-8 sm:mb-8 md:mb-8 flex flex-col ">
                  <p className="pop400 text-lg font-black italic  text-black p-5">
                  Ti abbiamo suggerito questo piano sulla base delle risposte che hai inserito nel quiz. <br/> 
                  Sono molto importanti le risposte che hai inserito per quanto riguarda delivery, asporto e prenotazioni. Se vuoi sapere in che modo è stato calcolato il tuo piano, contattaci!{" "}
                    <a
                      href="/contatti"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="pop700 text-lg font-black italic underline text-[#00B27A] "
                    >
                      contattaci.
                    </a>{" "}
                  </p>

                  <p className="pop600 text-lg font-black not-italic  text-black p-5">
                    I nostri piani includono sempre:
                  </p>
                  <p className="pop400 text-lg font-black not-italic  text-black p-5">
                    - Funzione     <a
                      href="/scopri-i-tuoi-clienti"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="pop700 text-lg font-black italic underline text-[#00B27A] "
                    >
                 “Scopri i tuoi clienti”
                    </a> <br></br>- Strumenti per
                    Social Media e Marketing <br></br>- Sito e app, incluso
                    dominio e hosting <br></br>- Menù Digitale con QR Code{" "}
                    <br></br>- Lista Clienti digitale <br></br>- Newsletter e
                    Mail Automatiche
                  </p>

                  <div className=" p-3">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <p className="pop700  not-italic font-extrabold leading-9 text-xl text-black ">
                          Quanto dura l’abbonamento con Restaurants Club?
                        </p>
                      </AccordionSummary>

                      <AccordionDetails>
                        <p className="pop500 not-italic font-normal leading-7 text-base  text-black ">
                          Puoi scegliere un abbonamento con rinnovo mensile,
                          trimestrale o annuale, in base a come preferisci. Puoi
                          vedere come cambia il prezzo in base alla frequenza.
                        </p>
                      </AccordionDetails>
                    </Accordion>

                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <p className="pop700  not-italic font-extrabold leading-9 text-xl text-black ">
                          Come funziona il rinnovo? E se voglio andarmene?
                        </p>
                      </AccordionSummary>

                      <AccordionDetails>
                        <p className="pop500 not-italic font-normal leading-7 text-base  text-black ">
                          L’abbonamento si rinnova in automatico così non devi
                          stare a ricordartelo ma puoi andartene quando vuoi:
                          non ci sono penali, ti chiediamo solo almeno 15 giorni
                          di preavviso per permetterci di disattivarti
                          l’abbonamento.
                        </p>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div className="break">
                    <p className="pop600 text-lg font-black not-italic text-black p-5">
                      Metodi di pagamento accettati:
                    </p>
                    <div className="flex flex-row px-5">
                      <AccountBalanceIcon />
                      <p className="pop400  not-italic  text-lg text-black pl-5">
                        Addebito su conto corrente
                      </p>
                    </div>

                    <div className="flex flex-row px-5">
                      <CreditCardIcon />
                      <p className="pop400  not-italic  text-lg text-black pl-5">
                        {" "}
                        Carta di credito o di debito
                      </p>
                    </div>

                    <p className="pop600 text-lg font-black not-italic  text-black p-5">
                      In arrivo:
                    </p>
                    <div className="flex flex-row px-5">
                      <img src={Satispay} alt="" />
                      <p className="pop400 not-italic text-lg text-[#636363] pl-5">
                        Satispay
                      </p>
                    </div>

                    <div className="flex flex-row px-5">
                      <img src={Paypal} alt="" />
                      <p className="pop400 not-italic text-lg text-[#636363] pl-5">
                        {" "}
                        Paypal
                      </p>
                    </div>
                  </div>
                </div>

                <div className=" w-[45%] xsm:w-full sm:w-full md:w-full  flex flex-col">
                  {/* ----------------------------------------------------------------------------------------------- DEFAULT -------------------------------------------------------------- */}

                  {score === 0 && score1 === 0 && score2 === 0 && (
                    <div className="w-full my-5 mx-auto flex flex-col gap-5 relative">
                      <div className=" w-full mx-auto flex flex-col  ">
                        <div className="w-full bg-[#114653]">
                          <p className="Brevia850 text-3xl font-black not-italic text-center text-white w-[55%] mx-auto py-2">
                            {" "}
                            Ti consigliamo{" "}
                          </p>
                        </div>

                        <div className="w-full bg-[#00B27A]">
                          <p className="Brevia850 text-4xl font-black not-italic text-center text-white w-[85%] mx-auto py-5">
                            {" "}
                            VIANDANTE{" "}
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-row  ">
                              <div className="mx-auto">
                                <ScrollToButton
                                  toId="wibx"
                                  className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4"
                                >
                                  <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                    Quali sono le funzioni?
                                  </p>
                                </ScrollToButton>
                              </div>
                            </div>

                      <p className="text-black pop700 text-xl my-2 text-center">
                        {" "}
                        Puoi scegliere la frequenza di rinnovo{" "}
                      </p>

                      <div className="border-4 border-black  rounded-full w-[80%] mx-auto my-2 break">
                        <button
                          onClick={() => SetAnnual(1)}
                          className={`   ${
                            Annual === 1
                              ? "bg-[#114653] text-white  "
                              : " text-black"
                          } h-10  w-1/3  pop500 rounded-full `}
                        >
                          {" "}
                          Mese{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(2)}
                          className={` ${
                            Annual === 2
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3  pop500  rounded-full `}
                        >
                          {" "}
                          Trimestre{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(3)}
                          className={`   ${
                            Annual === 3
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3   pop500  rounded-full`}
                        >
                          {" "}
                          Anno
                        </button>
                      </div>

                      <div className="w-full">
                        <div className="border-4 border-[#114653] flex flex-col  ">
                          <div className="m-10">
                            {Annual === 1 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  375,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  mese + IVA
                                </p>
                              </>
                            )}
                            {Annual === 2 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  300,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    1.125,00 €{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    900,00€ + IVA
                                  </span>
                                </p>{" "}
                              </>
                            )}

                            {Annual === 3 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  275,00 €{" "}
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center ">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    4.500 €{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    3.300,00€ + IVA
                                  </span>
                                </p>
                              </>
                            )}

                            <button
                              onClick={handlePrint}
                              className="w-[50%] xsm:w-full sm:w-full mx-auto mt-10 mb-4 px-8 xsm:px-3 sm:px-3 py-3 border-4  border-black items-center flex justify-center pop600 text-black text-lg "
                            >
                              {" "}
                              Scarica preventivo{" "}
                            </button>


                          </div>
                        </div>
                        {scorepp >= 1 && (
                          <>
                            <img src={Plus} className="m-5 mx-auto " alt="" />

                            <div className="border-4 border-[#114653] flex flex-col p-6 mx-auto">
                              <p className="Brevia850 text-3xl italic text-centern mx-auto  text-[#114653] text-center">
                                Supporto Premium
                              </p>
                              <p className="text-black pop400 text-xl m-2 text-center mx-auto">
                                {" "}
                                Servizi di assistenza delle nostre agenzie
                                partner a prezzi vantaggiosi{" "}
                              </p>
                              <div className="mx-auto">
                                <a
                                  href="/supporto-premium"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                    Scopri di più
                                  </p>{" "}
                                </a>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="flex flex-col mx-auto  gap-y-10 xmd:hidden lg:hidden xl:hidden my-14">
                        {/*------------------------------------------------------ (VIANDANTE) ----------------------------------------------------------------*/}
                        <Viandante />
                      </div>
                    </div>
                  )}
                  {/* ----------------------------------------------------------------------------------------------- ESPRESSO ------------------------------------------------------------------------------- */}
                  {score >= 1 && score1 === 0 && score2 === 0 && (
                    <div className="w-full my-5 mx-auto flex flex-col gap-5 relative">
                      <div className=" w-full mx-auto flex flex-col  ">
                        <div className="w-full bg-[#114653]">
                          <p className="Brevia850 text-3xl font-black not-italic text-center text-white w-[55%] mx-auto py-2">
                            {" "}
                            Ti consigliamo{" "}
                          </p>
                        </div>

                        <div className="w-full bg-[#00B27A]">
                          <p className="Brevia850 text-4xl font-black not-italic text-center text-white w-[85%] mx-auto py-5">
                            {" "}
                            ESPRESSO{" "}
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-row  ">
                              <div className="mx-auto">
                                <ScrollToButton
                                  toId="wibx"
                                  className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4"
                                >
                                  <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                    Quali sono le funzioni?
                                  </p>
                                </ScrollToButton>
                              </div>
                            </div>
                      <p className="text-black pop700 text-xl my-2 text-center">
                        {" "}
                        Puoi scegliere la frequenza di rinnovo{" "}
                      </p>

                      <div className="border-4 border-black  rounded-full w-[80%] mx-auto my-2 break">
                        <button
                          onClick={() => SetAnnual(1)}
                          className={`   ${
                            Annual === 1
                              ? "bg-[#114653] text-white  "
                              : " text-black"
                          } h-10  w-1/3  pop500 rounded-full `}
                        >
                          {" "}
                          Mese{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(2)}
                          className={` ${
                            Annual === 2
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3  pop500  rounded-full `}
                        >
                          {" "}
                          Trimestre{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(3)}
                          className={`   ${
                            Annual === 3
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3   pop500  rounded-full`}
                        >
                          {" "}
                          Anno
                        </button>
                      </div>

                      <div className="w-full">
                        <div className="border-4 border-[#114653] flex flex-col  ">
                          <div className="m-10">
                            {Annual === 1 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  375,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  mese + IVA
                                </p>
                              </>
                            )}
                            {Annual === 2 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  300,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    1.125,00 €{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    900,00€ + IVA
                                  </span>
                                </p>{" "}
                              </>
                            )}

                            {Annual === 3 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  275,00 €{" "}
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center ">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    4.500 €{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    3.300,00€ + IVA
                                  </span>
                                </p>
                              </>
                            )}

                            <button
                              onClick={handlePrint}
                              className="w-[50%] xsm:w-full sm:w-full mx-auto mt-10 mb-4 px-8 xsm:px-3 sm:px-3 py-3 border-4  border-black items-center flex justify-center pop600 text-black text-lg "
                            >
                              {" "}
                              Scarica preventivo{" "}
                            </button>


                          </div>
                        </div>

                        {scorepp >= 1 && (
                          <>
                            <img src={Plus} className="m-5 mx-auto " alt="" />

                            <div className="border-4 border-[#114653] flex flex-col p-6 mx-auto">
                              <p className="Brevia850 text-3xl italic text-centern mx-auto  text-[#114653] text-center">
                                Supporto Premium
                              </p>
                              <p className="text-black pop400 text-xl m-2 text-center mx-auto">
                                {" "}
                                Servizi di assistenza delle nostre agenzie
                                partner a prezzi vantaggiosi{" "}
                              </p>
                              <div className="mx-auto">
                                <a
                                  href="/supporto-premium"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                    Scopri di più
                                  </p>{" "}
                                </a>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="flex flex-col mx-auto  gap-y-10 xmd:hidden lg:hidden xl:hidden my-14">
                        {/*------------------------------------------------------ (ESPRESSO) ----------------------------------------------------------------*/}
                        <Espresso />
                      </div>
                    </div>
                  )}

                  {/* ----------------------------------------------------------------------------------------------- VIANDANTE -------------------------------------------------------------------------------- */}
                  {score1 >= 1 && score === 0 && score2 === 0 && (
                    <div className="w-full my-5 mx-auto flex flex-col gap-5 relative">
                      <div className=" w-full mx-auto flex flex-col  ">
                        <div className="w-full bg-[#114653]">
                          <p className="Brevia850 text-3xl font-black not-italic text-center text-white w-[55%] mx-auto py-2">
                            {" "}
                            Ti consigliamo{" "}
                          </p>
                        </div>

                        <div className="w-full bg-[#00B27A]">
                          <p className="Brevia850 text-4xl font-black not-italic text-center text-white w-[85%] mx-auto py-5">
                            {" "}
                            VIANDANTE{" "}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-row  ">
                              <div className="mx-auto">
                                <ScrollToButton
                                  toId="wibx"
                                  className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4"
                                >
                                  <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                    Quali sono le funzioni?
                                  </p>
                                </ScrollToButton>
                              </div>
                            </div>
                      <p className="text-black pop700 text-xl my-2 text-center">
                        {" "}
                        Puoi scegliere la frequenza di rinnovo{" "}
                      </p>

                      <div className="border-4 border-black  rounded-full w-[80%] mx-auto my-2 break">
                        <button
                          onClick={() => SetAnnual(1)}
                          className={`   ${
                            Annual === 1
                              ? "bg-[#114653] text-white  "
                              : " text-black"
                          } h-10  w-1/3  pop500 rounded-full `}
                        >
                          {" "}
                          Mese{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(2)}
                          className={` ${
                            Annual === 2
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3  pop500  rounded-full `}
                        >
                          {" "}
                          Trimestre{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(3)}
                          className={`   ${
                            Annual === 3
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3   pop500  rounded-full`}
                        >
                          {" "}
                          Anno
                        </button>
                      </div>

                      <div className="w-full">
                        <div className="border-4 border-[#114653] flex flex-col  ">
                          <div className="m-10">
                            {Annual === 1 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  375,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  mese + IVA
                                </p>
                              </>
                            )}
                            {Annual === 2 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl my-3 xsm:text-5xl sm:text-5xl text-center">
                                  {" "}
                                  300,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    1.125,00 €{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    900,00€ + IVA
                                  </span>
                                </p>{" "}
                              </>
                            )}

                            {Annual === 3 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl my-3 xsm:text-5xl sm:text-5xl text-center">
                                  {" "}
                                  275,00 €{" "}
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center ">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    4.500 €{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    3.300,00€ + IVA
                                  </span>
                                </p>
                              </>
                            )}

                            <button
                              onClick={handlePrint}
                              className="w-[50%] xsm:w-full sm:w-full mx-auto mt-10 mb-4 px-8 xsm:px-3 sm:px-3 py-3 border-4  border-black items-center flex justify-center pop600 text-black text-lg "
                            >
                              {" "}
                              Scarica preventivo{" "}
                            </button>

                          </div>
                        </div>

                        {scorepp >= 1 && (
                          <>
                            <img src={Plus} className="m-5 mx-auto " alt="" />

                            <div className="border-4 border-[#114653] flex flex-col p-6 mx-auto">
                              <p className="Brevia850 text-3xl italic text-centern mx-auto  text-[#114653] text-center">
                                Supporto Premium
                              </p>
                              <p className="text-black pop400 text-xl m-2 text-center mx-auto">
                                {" "}
                                Servizi di assistenza delle nostre agenzie
                                partner a prezzi vantaggiosi{" "}
                              </p>
                              <div className="mx-auto">
                                <a
                                  href="/supporto-premium"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                    Scopri di più
                                  </p>{" "}
                                </a>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="flex flex-col mx-auto  gap-y-10 xmd:hidden lg:hidden xl:hidden my-14">
                        {/*------------------------------------------------------ (VIANDANTE) ----------------------------------------------------------------*/}
                        <Viandante />
                      </div>
                    </div>
                  )}

                  {/* ------------------------------------------------------------------------------------------------ TAVOLA  -------------------------------------------------------------------- */}
                  {score2 >= 1 && score1 === 0 && score === 0 && (
                    <div className="w-full my-5 mx-auto flex flex-col gap-5 relative">
                      <div className=" w-full mx-auto flex flex-col  ">
                        <div className="w-full bg-[#114653]">
                          <p className="Brevia850 text-3xl font-black not-italic text-center text-white w-[55%] mx-auto py-2">
                            {" "}
                            Ti consigliamo{" "}
                          </p>
                        </div>

                        {scorepp >= 1 && (
                          <div className="border-4 border-black flex flex-col p-6 ">
                            <p className="Brevia850 text-4xl not-italic text-centern w-[60%] mx-auto  text-[#114653] text-center">
                              Supporto Premium
                            </p>
                            <a
                              href="/supporto-premium"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                Scopri di più
                              </p>{" "}
                            </a>
                          </div>
                        )}

                        <div className="w-full bg-[#00B27A]">
                          <p className="Brevia850 text-4xl font-black not-italic text-center text-white w-[85%] mx-auto py-5">
                            {" "}
                            TAVOLA{" "}
                          </p>
                        </div>
                      </div>
                      
                      <div className="flex flex-row  ">
                              <div className="mx-auto">
                                <ScrollToButton
                                  toId="wibx"
                                  className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4"
                                >
                                  <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                    Quali sono le funzioni?
                                  </p>
                                </ScrollToButton>
                              </div>
                            </div>

                      <p className="text-black pop700 text-xl my-2 text-center">
                        {" "}
                        Puoi scegliere la frequenza di rinnovo{" "}
                      </p>

                      <div className="border-4 border-black  rounded-full w-[80%] mx-auto my-2 break">
                        <button
                          onClick={() => SetAnnual(1)}
                          className={`   ${
                            Annual === 1
                              ? "bg-[#114653] text-white  "
                              : " text-black"
                          } h-10  w-1/3  pop500 rounded-full `}
                        >
                          {" "}
                          Mese{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(2)}
                          className={` ${
                            Annual === 2
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3  pop500  rounded-full `}
                        >
                          {" "}
                          Trimestre{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(3)}
                          className={`   ${
                            Annual === 3
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3   pop500  rounded-full`}
                        >
                          {" "}
                          Anno
                        </button>
                      </div>

                      <div className="w-full">
                        <div className="border-4 border-[#114653] flex flex-col  ">
                          <div className="m-10">
                            {Annual === 1 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  375,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  mese + IVA
                                </p>
                              </>
                            )}
                            {Annual === 2 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  300,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    1.125,00 €{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    900,00€ + IVA
                                  </span>
                                </p>{" "}
                              </>
                            )}

                            {Annual === 3 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  275,00 €{" "}
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center ">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    4.500 €{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    3.300,00€ + IVA
                                  </span>
                                </p>
                              </>
                            )}

                            <button
                              onClick={handlePrint}
                              className="w-[50%] xsm:w-full sm:w-full mx-auto mt-10 mb-4 px-8 xsm:px-3 sm:px-3 py-3 border-4  border-black items-center flex justify-center pop600 text-black text-lg "
                            >
                              {" "}
                              Scarica preventivo{" "}
                            </button>

 
                          </div>
                        </div>
                      </div>

                      <div className="flex flex-col mx-auto  gap-y-10 xmd:hidden lg:hidden xl:hidden my-14">
                        {/*------------------------------------------------------ (TAVOLA) ----------------------------------------------------------------*/}
                        <Tavola />
                      </div>
                    </div>
                  )}

                  {/* ------------------------------------------------------------------------------------------------ TUTTI E TRE ------------------------------------------------------------------- */}
                  {score2 >= 1 && score1 >= 1 && score >= 1 && (
                    <div className="w-full my-5 mx-auto flex flex-col gap-5 relative">
                      <div className=" w-full mx-auto flex flex-col  ">
                        <div className="w-full bg-[#114653]">
                          <p className="Brevia850 text-3xl font-black not-italic text-center text-white w-[55%] mx-auto py-2">
                            {" "}
                            Ti consigliamo{" "}
                          </p>
                        </div>

                        <div className="w-full bg-[#00B27A]">
                          <p className="Brevia850 text-4xl font-black not-italic text-center text-white w-[85%] mx-auto py-5">
                            {" "}
                            TAVOLA + VIANDANTE + ESPRESSO{" "}
                          </p>
                        </div>

                        <div className="flex flex-row  ">
                              <div className="mx-auto">
                                <ScrollToButton
                                  toId="wibx"
                                  className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4"
                                >
                                  <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                    Quali sono le funzioni?
                                  </p>
                                </ScrollToButton>
                              </div>
                            </div>
                        <div className="flex flex-row xsm:flex-col-reverse sm:flex-col-reverse px-6 py-3 mx-auto ">
                          <div className="flex w-[30%] xsm:w-full sm:w-full  mx-auto ">
                            <img src={Gift} className="mx-auto " alt="" />
                          </div>
                          <div className="flex w-[70%] xsm:w-full sm:w-full mx-auto px-6 py-3 ">
                            <p className="text-black pop400 text-xl m-2 text-center mx-auto">
                              {" "}
                              Per te in regalo il{" "}
                              <span className="text-black pop600 text-xl m-2 text-center mx-auto">
                                pulsante Delivery su Google
                              </span>{" "}
                              e{" "}
                              <span className="text-black pop600 text-xl m-2 text-center mx-auto">
                                Prenota con Google !
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <p className="text-black pop700 text-xl my-2 text-center">
                        {" "}
                        Puoi scegliere la frequenza di rinnovo{" "}
                      </p>

                      <div className="border-4 border-black  rounded-full w-[80%] mx-auto my-2 break">
                        <button
                          onClick={() => SetAnnual(1)}
                          className={`   ${
                            Annual === 1
                              ? "bg-[#114653] text-white  "
                              : " text-black"
                          } h-10  w-1/3  pop500 rounded-full `}
                        >
                          {" "}
                          Mese{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(2)}
                          className={`   ${
                            Annual === 2
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3  pop500  rounded-full `}
                        >
                          {" "}
                          Trimestre{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(3)}
                          className={`   ${
                            Annual === 3
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3   pop500  rounded-full`}
                        >
                          {" "}
                          Anno
                        </button>
                      </div>

                      <div className="w-full">
                        <div className="border-4 border-[#114653] flex flex-col  ">
                          <div className="m-10">
                            {Annual === 1 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  475,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  mese + IVA
                                </p>
                              </>
                            )}
                            {Annual === 2 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  400,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    1.425,00 €{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    1.200,00€ + IVA
                                  </span>
                                </p>{" "}
                              </>
                            )}

                            {Annual === 3 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  350,00 €{" "}
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center ">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    5.700,00€{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    4.200,00€ + IVA
                                  </span>
                                </p>
                              </>
                            )}

                            <button
                              onClick={handlePrint}
                              className="w-[50%] xsm:w-full sm:w-full mx-auto mt-10 mb-4 py-3 border-4  border-black items-center flex justify-center pop600 text-black text-lg "
                            >
                              {" "}
                              Scarica preventivo{" "}
                            </button>

                          </div>
                        </div>

                        {scorepp >= 1 && (
                          <>
                            <img src={Plus} className="m-5 mx-auto " alt="" />

                            <div className="border-4 border-[#114653] flex flex-col p-6 mx-auto">
                              <p className="Brevia850 text-3xl italic text-centern mx-auto  text-[#114653] text-center">
                                Supporto Premium
                              </p>
                              <p className="text-black pop400 text-xl m-2 text-center mx-auto">
                                {" "}
                                Servizi di assistenza delle nostre agenzie
                                partner a prezzi vantaggiosi{" "}
                              </p>
                              <div className="mx-auto">
                                <a
                                  href="/supporto-premium"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                    Scopri di più
                                  </p>{" "}
                                </a>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="flex flex-col mx-auto  gap-y-10 xmd:hidden lg:hidden xl:hidden my-14">
                        {/*------------------------------------------------------ (ESPRESSO) ----------------------------------------------------------------*/}
                        <Espresso />

                        {/*------------------------------------------------------ (VIANDANTE) ----------------------------------------------------------------*/}
                        <Viandante />

                        {/*------------------------------------------------------ (TAVOLA) ----------------------------------------------------------------*/}
                        <Tavola />
                      </div>
                    </div>
                  )}
                  {/* ------------------------------------------------------------------------------------------------ TAVOLA  + ESPRESSO -------------------------------------------------------------- */}
                  {score2 >= 1 && score1 === 0 && score >= 1 && (
                    <div className="w-full my-5 mx-auto flex flex-col gap-5 relative">
                      <div className=" w-full mx-auto flex flex-col  ">
                        <div className="w-full bg-[#114653]">
                          <p className="Brevia850 text-3xl font-black not-italic text-center text-white w-[55%] mx-auto py-2">
                            {" "}
                            Ti consigliamo{" "}
                          </p>
                        </div>

                        <div className="w-full bg-[#00B27A]">
                          <p className="Brevia850 text-4xl font-black not-italic text-center text-white w-[85%] mx-auto py-5">
                            {" "}
                            TAVOLA + ESPRESSO{" "}
                          </p>
                        </div>
                      </div>

                      <div className="flex flex-row  ">
                              <div className="mx-auto">
                                <ScrollToButton
                                  toId="wibx"
                                  className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4"
                                >
                                  <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                    Quali sono le funzioni?
                                  </p>
                                </ScrollToButton>
                              </div>
                            </div>
                      <p className="text-black pop700 text-xl my-2 text-center">
                        {" "}
                        Puoi scegliere la frequenza di rinnovo{" "}
                      </p>

                      <div className="border-4 border-black  rounded-full w-[80%] mx-auto my-2 break ">
                        <button
                          onClick={() => SetAnnual(1)}
                          className={`   ${
                            Annual === 1
                              ? "bg-[#114653] text-white  "
                              : " text-black"
                          } h-10  w-1/3  pop500 rounded-full `}
                        >
                          {" "}
                          Mese{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(2)}
                          className={` ${
                            Annual === 2
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3  pop500  rounded-full `}
                        >
                          {" "}
                          Trimestre{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(3)}
                          className={`   ${
                            Annual === 3
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3   pop500  rounded-full`}
                        >
                          {" "}
                          Anno
                        </button>
                      </div>

                      <div className="w-full">
                        <div className="border-4 border-[#114653] flex flex-col  ">
                          <div className="m-10">
                            {Annual === 1 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  425,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  mese + IVA
                                </p>
                              </>
                            )}
                            {Annual === 2 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  350,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    1.275,00 €{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    1.050,00€ + IVA
                                  </span>
                                </p>{" "}
                              </>
                            )}

                            {Annual === 3 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl  my-3 text-center">
                                  {" "}
                                  312,00 €{" "}
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center ">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    5.100,00€{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    3.750,00€ + IVA
                                  </span>
                                </p>
                              </>
                            )}

                            <button
                              onClick={handlePrint}
                              className="w-[50%] xsm:w-full sm:w-full mx-auto mt-10 mb-4 px-8 xsm:px-3 sm:px-3 py-3 border-4  border-black items-center flex justify-center pop600 text-black text-lg "
                            >
                              {" "}
                              Scarica preventivo{" "}
                            </button>

                          </div>
                        </div>

                        {scorepp >= 1 && (
                          <>
                            <img src={Plus} className="m-5 mx-auto " alt="" />

                            <div className="border-4 border-[#114653] flex flex-col p-6 mx-auto">
                              <p className="Brevia850 text-3xl italic text-centern mx-auto  text-[#114653] text-center">
                                Supporto Premium
                              </p>
                              <p className="text-black pop400 text-xl m-2 text-center mx-auto">
                                {" "}
                                Servizi di assistenza delle nostre agenzie
                                partner a prezzi vantaggiosi{" "}
                              </p>
                              <div className="mx-auto">
                                <a
                                  href="/supporto-premium"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                    Scopri di più
                                  </p>{" "}
                                </a>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="flex flex-col mx-auto  gap-y-10 xmd:hidden lg:hidden xl:hidden my-14">
                        {/*------------------------------------------------------ (ESPRESSO) ----------------------------------------------------------------*/}
                        <Espresso />

                        {/*------------------------------------------------------ (TAVOLA) ----------------------------------------------------------------*/}
                        <Tavola />
                      </div>
                    </div>
                  )}

                  {/* ------------------------------------------------------------------------------------------------- VIANDANTE  + ESPRESSO -------------------------------------------------------------- */}
                  {score2 === 0 && score1 >= 1 && score >= 1 && (
                    <div className="w-full my-5 mx-auto flex flex-col gap-5 relative">
                      <div className=" w-full mx-auto flex flex-col  ">
                        <div className="w-full bg-[#114653]">
                          <p className="Brevia850 text-3xl font-black not-italic text-center text-white w-[55%] mx-auto py-2">
                            {" "}
                            Ti consigliamo{" "}
                          </p>
                        </div>

                        <div className="w-full bg-[#00B27A]">
                          <p className="Brevia850 text-4xl font-black not-italic text-center text-white w-[85%] mx-auto py-5">
                            {" "}
                            VIANDANTE + ESPRESSO{" "}
                          </p>
                        </div>
                        <div className="flex flex-row  ">
                              <div className="mx-auto">
                                <ScrollToButton
                                  toId="wibx"
                                  className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4"
                                >
                                  <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                    Quali sono le funzioni?
                                  </p>
                                </ScrollToButton>
                              </div>
                            </div>
                        <div className="flex flex-row xsm:flex-col-reverse sm:flex-col-reverse px-6 py-3 mx-auto ">
                          <div className="flex w-[30%] xsm:w-full sm:w-full mx-auto ">
                            <img src={Gift} className="mx-auto " alt="" />
                          </div>
                          <div className="flex w-[70%] xsm:w-full sm:w-full mx-auto px-6 py-3 ">
                            <p className="text-black pop400 text-xl m-2 text-center mx-auto">
                              {" "}
                              Per te in regalo{" "}
                              <span className="text-black pop600 text-xl m-2 text-center mx-auto">
                                Prenota con Google !
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>

                      <p className="text-black pop700 text-xl my-2 text-center">
                        {" "}
                        Puoi scegliere la frequenza di rinnovo{" "}
                      </p>

                      <div className="border-4 border-black  rounded-full w-[80%] mx-auto my-2 break">
                        <button
                          onClick={() => SetAnnual(1)}
                          className={`   ${
                            Annual === 1
                              ? "bg-[#114653] text-white  "
                              : " text-black"
                          } h-10  w-1/3  pop500 rounded-full `}
                        >
                          {" "}
                          Mese{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(2)}
                          className={` ${
                            Annual === 2
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3  pop500  rounded-full `}
                        >
                          {" "}
                          Trimestre{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(3)}
                          className={`   ${
                            Annual === 3
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3   pop500  rounded-full`}
                        >
                          {" "}
                          Anno
                        </button>
                      </div>

                      <div className="w-full">
                        <div className="border-4 border-[#114653] flex flex-col  ">
                          <div className="m-10">
                            {Annual === 1 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  425,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  mese + IVA
                                </p>
                              </>
                            )}
                            {Annual === 2 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  350,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    1.275,00 €{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    1.050,00€ + IVA
                                  </span>
                                </p>{" "}
                              </>
                            )}

                            {Annual === 3 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  312,00 €{" "}
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center ">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    5.100,00€{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    3.750,00€ + IVA
                                  </span>
                                </p>
                              </>
                            )}

                            <button
                              onClick={handlePrint}
                              className="w-[50%] xsm:w-full sm:w-full mx-auto mt-10 mb-4 px-8 xsm:px-3 sm:px-3 py-3 border-4  border-black items-center flex justify-center pop600 text-black text-lg "
                            >
                              {" "}
                              Scarica preventivo{" "}
                            </button>


                          </div>
                        </div>

                        {scorepp >= 1 && (
                          <>
                            <img src={Plus} className="m-5 mx-auto " alt="" />

                            <div className="border-4 border-[#114653] flex flex-col p-6 mx-auto">
                              <p className="Brevia850 text-3xl italic text-centern mx-auto  text-[#114653] text-center">
                                Supporto Premium
                              </p>
                              <p className="text-black pop400 text-xl m-2 text-center mx-auto">
                                {" "}
                                Servizi di assistenza delle nostre agenzie
                                partner a prezzi vantaggiosi{" "}
                              </p>
                              <div className="mx-auto">
                                <a
                                  href="/supporto-premium"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                    Scopri di più
                                  </p>{" "}
                                </a>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="flex flex-col mx-auto  gap-y-10 xmd:hidden lg:hidden xl:hidden my-14">
                        {/*------------------------------------------------------ (ESPRESSO) ----------------------------------------------------------------*/}
                        <Espresso />

                        {/*------------------------------------------------------ (VIANDANTE) ----------------------------------------------------------------*/}
                        <Viandante />
                      </div>
                    </div>
                  )}
                  {/* ------------------------------------------------------------------------------------------------- TAVOLA  + VIANDANTE -------------------------------------------------------------- */}
                  {score2 >= 1 && score1 >= 1 && score === 0 && (
                    <div className="w-full my-5 mx-auto flex flex-col gap-5 relative">
                      <div className=" w-full mx-auto flex flex-col  ">
                        <div className="w-full bg-[#114653]">
                          <p className="Brevia850 text-3xl font-black not-italic text-center text-white w-[55%] mx-auto py-2">
                            {" "}
                            Ti consigliamo{" "}
                          </p>
                        </div>

                        <div className="w-full bg-[#00B27A]">
                          <p className="Brevia850 text-4xl font-black not-italic text-center text-white w-[85%] mx-auto py-5">
                            {" "}
                            TAVOLA + VIANDANTE{" "}
                          </p>
                        </div>

                        <div className="flex flex-row  ">
                              <div className="mx-auto">
                                <ScrollToButton
                                  toId="wibx"
                                  className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4"
                                >
                                  <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                    Quali sono le funzioni?
                                  </p>
                                </ScrollToButton>
                              </div>
                            </div>

                        <div className="flex flex-row xsm:flex-col-reverse sm:flex-col-reverse p-6 mx-auto ">
                          <div className="flex w-[30%] xsm:w-full sm:w-full  mx-auto ">
                            <img src={Gift} className="mx-auto " alt="" />
                          </div>
                          <div className="flex w-[70%] xsm:w-full sm:w-full mx-auto p-6 ">
                            <p className="text-black pop400 text-xl m-2 text-center mx-auto">
                              {" "}
                              Per te in regalo il{" "}
                              <span className="text-black pop600 text-xl m-2 text-center mx-auto">
                                pulsante Delivery su Google
                              </span>{" "}
                            </p>
                          </div>
                        </div>
                      </div>

                      <p className="text-black pop700 text-xl my-2 text-center">
                        {" "}
                        Puoi scegliere la frequenza di rinnovo{" "}
                      </p>

                      <div className="border-4 border-black  rounded-full w-[80%] mx-auto my-2 break">
                        <button
                          onClick={() => SetAnnual(1)}
                          className={`   ${
                            Annual === 1
                              ? "bg-[#114653] text-white  "
                              : " text-black"
                          } h-10  w-1/3  pop500 rounded-full `}
                        >
                          {" "}
                          Mese{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(2)}
                          className={` ${
                            Annual === 2
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3  pop500  rounded-full `}
                        >
                          {" "}
                          Trimestre{" "}
                        </button>
                        <button
                          onClick={() => SetAnnual(3)}
                          className={`   ${
                            Annual === 3
                              ? "bg-[#114653] text-white "
                              : " text-black"
                          } h-10  w-1/3   pop500  rounded-full`}
                        >
                          {" "}
                          Anno
                        </button>
                      </div>

                      <div className="w-full">
                        <div className="border-4 border-[#114653] flex flex-col  ">
                          <div className="m-10">
                            {Annual === 1 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  425,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  mese + IVA
                                </p>
                              </>
                            )}
                            {Annual === 2 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl  xsm:text-5xl sm:text-5xl my-3 text-center">
                                  {" "}
                                  350,00 €
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    1.275,00 €{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    1.050,00€ + IVA
                                  </span>
                                </p>{" "}
                              </>
                            )}

                            {Annual === 3 && (
                              <>
                                <p className="text-[#FC6D7A] Brevia700 text-6xl xsm:text-5xl sm:text-5xl  my-3 text-center">
                                  {" "}
                                  312,00 €{" "}
                                </p>
                                <p className="text-black pop500 text-lg mb-3 text-center ">
                                  {" "}
                                  mese + IVA{" "}
                                </p>
                                <p className="text-[#114653] pop700 text-xl text-center ">
                                  {" "}
                                  Totale:{" "}
                                  <span className="pop400  text-base line-through">
                                    {" "}
                                    5.100,00€{" "}
                                  </span>{" "}
                                  <span className="pop700  text-base">
                                    3.750,00€ + IVA
                                  </span>
                                </p>
                              </>
                            )}

                            <button
                              onClick={handlePrint}
                              className="w-[50%] xsm:w-full sm:w-full mx-auto mt-10 mb-4 px-8 xsm:px-3 sm:px-3 py-3 border-4  border-black items-center flex justify-center pop600 text-black text-lg "
                            >
                              {" "}
                              Scarica preventivo{" "}
                            </button>


                          </div>
                        </div>

                        {scorepp >= 1 && (
                          <>
                            <img src={Plus} className="m-5 mx-auto " alt="" />

                            <div className="border-4 border-[#114653] flex flex-col p-6 mx-auto">
                              <p className="Brevia850 text-3xl italic text-centern mx-auto  text-[#114653] text-center">
                                Supporto Premium
                              </p>
                              <p className="text-black pop400 text-xl m-2 text-center mx-auto">
                                {" "}
                                Servizi di assistenza delle nostre agenzie
                                partner a prezzi vantaggiosi{" "}
                              </p>
                              <div className="mx-auto">
                                <a
                                  href="/supporto-premium"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <p className="text-black pop500 text-lg text-left mx-auto underline underline-offset-4 mt-4">
                                    Scopri di più
                                  </p>{" "}
                                </a>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <div className="flex flex-col mx-auto  gap-y-10 xmd:hidden lg:hidden xl:hidden my-14">
                        {/*------------------------------------------------------ (VIANDANTE) ----------------------------------------------------------------*/}
                        <Viandante />

                        {/*------------------------------------------------------ (TAVOLA) ----------------------------------------------------------------*/}
                        <Tavola />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="w-full py-5 bg-[#00B27A] mb-5 xsm:flex xsm:flex-col xsm:items-center sm:flex sm:flex-col sm:items-center flex flex-col items-center justify-center">
              <p className="Brevia850 text-4xl font-black not-italic text-center text-white mx-auto pb-5">
                {" "}
                Sei interessato? <br></br> Parla con un nostro esperto {" "}
              </p>
              <FirstButton
                onClick={() => window.open("/contatti", "_blank")}
                
                className="w-96 px-10 py-3 border-4 bg-[#DCFFCF] border-black items-center flex justify-center pop600 text-black mx-auto m-6 text-xl"
                text="Contattaci"
              />
            </div>

            <div
              id="wibx"
              className="w-[80%] px-[40px] mx-auto xsm:w-full sm:w-full  md:w-full  xmd:w-full items-center  flex flex-col  sm:mb-10  mb-[60px] gap-x-12  h-fit relative "
            >
              <p className="Brevia700 text-4xl font-black italic text-center text-black mx-auto my-10 w-[60%] xsm:w-full sm:w-full  md:w-full xsm:hidden sm:hidden md:hidden ">
                Paga solo ciò di cui hai bisogno e scegli il piano su misura per
                te{" "}
              </p>

              <div className="flex flex-row w-full  mx-auto my-10 xsm:hidden sm:hidden md:hidden  ">
                <div id="pricing" className=" w-full ">
                  <table id="pricing" className=" w-full mt-5  ">
                    <thead>
                      <tr className=" ">
                        <th className="Brevia700 text-center text-2xl sm:text-lg text-[#114653] h-62 w-[304px] border-b-8 border-t-0 border-x-0 border-white"></th>
                        <th className="Brevia700 text-center text-2xl sm:text-lg text-[#114653] h-62 w-[304px] bg-[#E5FFDD] border-b-8 border-t-0 border-x-0 border-[#114653] ">
                          L’Espresso
                        </th>
                        <th className="Brevia700 text-center text-2xl sm:text-lg text-[#114653] h-62 w-[304px] bg-[#B1C8FF] border-b-8 border-t-0 border-x-0 border-[#114653]">
                          Il Viandante
                        </th>
                        <th className="Brevia700 text-center text-2xl sm:text-lg text-[#114653] h-62 w-[304px] bg-[#FFDDE5] border-b-8 border-t-0 border-x-0 border-[#114653]">
                          A Tavola
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className="">
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            Funzione “Scopri i tuoi Clienti”
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            Strumenti per Social Media
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            Campagne Marketing
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>

                      <tr className="">
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            - per il Delivery
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            - per le Prenotazioni
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            - per Traffico Locale
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            Ottimizzazione Scheda GMB
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            Fai Delivery dal tuo sito
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            Accetta Prenotazioni online
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>

                      <tr className="">
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            Sito e App (Dominio e Hosting)
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            Menù Digitale con QRCode
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            Grafiche per Social e Mail
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            - Template per il Delivery
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            - Template per Prenotazioni
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            - Template per Traffico Locale
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={Meno}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            Lista Cliente Digitale
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                      <tr className="">
                        <td className="h-24 border-b-2 border-transparent">
                          <p className="pop500 text-lg leading-6 text-left text-[#000000] ">
                            Newsletter e Mail Automatiche
                          </p>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="border-b-[1px] border-[#D9D9D9]">
                          <div className="">
                            <img
                              src={TabCheck}
                              className="w-6 h-6 mx-auto"
                              alt=""
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div className="w-full py-5 bg-[#00B27A] xsm:hidden sm:hidden md:hidden flex flex-col items-center justify-center mb-10">
              <p className="Brevia850 text-3xl font-black not-italic text-center text-white mx-auto">
                {" "}
                Sei interessato? <br></br> Parla con un nostro esperto {" "}
              </p>
              <FirstButton
                onClick={() => window.open("/contatti", "_blank")}
                className="w-96 px-10 py-3 border-4 bg-[#DCFFCF] border-black items-center flex justify-center pop600 text-black mx-auto m-6 text-xl"
                text="Contattaci"
              />
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className=" w-[80%] px-[40px] xsm:px-[20px] sm:px-[20px] mx-auto xsm:w-full sm:w-full  md:w-full  xmd:w-full items-center  flex flex-row sm:flex-col xsm:flex-col  sm:mb-10  mb-[60px] gap-x-12  h-fit relative ">
            <ScrollToTop />
            <div className=" w-full text-left flex flex-col sm:p-0 xmd:p-5 md:p-3 gap-5 mx-auto items-left justify-center ">
              <p className="Brevia850 text-xl font-black not-italic  sm:text-center xsm:text-center text-[#114653]">
                <span>Domanda {currentQuestion + 1}</span>/{questions.length}
              </p>

              <div className=" w-[80%] xsm:mx-auto sm:mx-auto">
                {currentQuestion === 0 && (
                  <div className="flex flex-row gap-4 h-2.5">
                    <ScrollToTop />
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#D9D9D9] w-full h-2"></div>
                    <div className="bg-[#D9D9D9] w-full h-2"></div>
                    <div className="bg-[#D9D9D9] w-full h-2"></div>
                    <div className="bg-[#D9D9D9] w-full h-2"></div>
                    <div className="bg-[#D9D9D9] w-full h-2"></div>
                    {/* <div className="bg-[#D9D9D9] w-full h-2"></div> */}
                  </div>
                )}

                {currentQuestion === 1 && (
                  <div className="flex flex-row gap-4 h-2.5">
                    <ScrollToTop />
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#D9D9D9] w-full h-2"></div>
                    <div className="bg-[#D9D9D9] w-full h-2"></div>
                    <div className="bg-[#D9D9D9] w-full h-2"></div>
                    <div className="bg-[#D9D9D9] w-full h-2"></div>
                    {/* <div className="bg-[#D9D9D9] w-full h-2"></div> */}
                  </div>
                )}

                {currentQuestion === 2 && (
                  <div className="flex flex-row gap-4 h-2.5">
                    <ScrollToTop />
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#D9D9D9] w-full h-2"></div>
                    <div className="bg-[#D9D9D9] w-full h-2"></div>
                    <div className="bg-[#D9D9D9] w-full h-2"></div>
                    {/* <div className="bg-[#D9D9D9] w-full h-2"></div> */}
                  </div>
                )}

                {currentQuestion === 3 && (
                  <div className="flex flex-row gap-4 h-2.5">
                    <ScrollToTop />
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#D9D9D9] w-full h-2"></div>
                    <div className="bg-[#D9D9D9] w-full h-2"></div>
                    {/* <div className="bg-[#D9D9D9] w-full h-2"></div> */}
                  </div>
                )}

                {currentQuestion === 4 && (
                  <div className="flex flex-row gap-4 h-2.5">
                    <ScrollToTop />
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#D9D9D9] w-full h-2"></div>
                    {/* <div className="bg-[#D9D9D9] w-full h-2"></div> */}
                  </div>
                )}

                {currentQuestion === 5 && (
                  <div className="flex flex-row gap-4 h-2.5">
                    <ScrollToTop />
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    {/* <div className="bg-[#D9D9D9] w-full h-2"></div> */}
                  </div>
                )}

                {/* {currentQuestion === 6 && (
                  <div className="flex flex-row gap-4 h-2.5">
                    <ScrollToTop />
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                    <div className="bg-[#00B27A] w-full h-2"></div>
                  </div>
                )} */}
              </div>




              <p className="Brevia850 text-3xl font-black not-italic  sm:text-center xsm:text-center text-[#114653]">
                {questions[currentQuestion].questionText}
              </p>
              <p className="pop400 text-base font-black not-italic  sm:text-center sm:px-2 xsm:text-center text-[#114653]">
                {questions[currentQuestion].questionText1}
              </p>

              {currentQuestion >= 1 && currentQuestion <= 4 && (
                <div className="w-full flex flex-row xsm:flex-col-reverse sm:flex-col-reverse md:flex-col-reverse">
                  <ScrollToTop />
                  <div className="xsm:w-full sm:w-full md:w-full w-[60%] flex flex-col gap-6">
                    {questions[currentQuestion].answerOptions.map(
                      (answerOption, index) => (
                        <div className="" key={index}>
                          {/* <button
                            style={{
                              backgroundColor:
                                theChosenAnswers.includes(
                                  answerOption.answerText
                                ) && "#114653",
                              backgroundImage:
                                theChosenAnswers.includes(
                                  answerOption.answerText
                                ) && `url(${check})`,
                              backgroundRepeat:
                                theChosenAnswers.includes(
                                  answerOption.answerText
                                ) && "no-repeat",
                              backgroundPosition:
                                theChosenAnswers.includes(
                                  answerOption.answerText
                                ) && "50% 80%",
                              color:
                                theChosenAnswers.includes(
                                  answerOption.answerText
                                ) && "white",
                            }}
                            className={`flex w-full xsm:w-[150px] sm:w-[150px]  sm:h-[200px] h-20  xsm:h-[200px] border-4 border-black  text-[#114653] md:hidden xmd:hidden lg:hidden xl:hidden `}
                            onClick={() => {
                              handleAnswerOptionClick(
                                answerOption.answerText,
                                answerOption.esp,
                                answerOption.vian,
                                answerOption.tav,
                                answerOption.pp
                              );
                            }}
                          >
                            <p className=" flex place-self-start  pop600 text-base text-left p-3 ">
                              {answerOption.answerText}{" "}
                            </p>{" "}
                          </button> */}
                          <button
                            style={{
                              backgroundColor:
                                theChosenAnswers.includes(
                                  answerOption.answerText
                                ) && "#114653",
                              backgroundImage:
                                theChosenAnswers.includes(
                                  answerOption.answerText
                                ) && `url(${check})`,
                              backgroundRepeat:
                                theChosenAnswers.includes(
                                  answerOption.answerText
                                ) && "no-repeat",
                              backgroundPosition:
                                theChosenAnswers.includes(
                                  answerOption.answerText
                                ) && "100% 50%",
                              color:
                                theChosenAnswers.includes(
                                  answerOption.answerText
                                ) && "white",
                            }}
                            className={`flex w-full border-4 border-black text-[#114653] `}
                            onClick={() => {
                              handleAnswerOptionClick(
                                answerOption.answerText,
                                answerOption.esp,
                                answerOption.vian,
                                answerOption.tav,
                                answerOption.pp
                              );
                              avanti();
                            }}
                          >
                           
                            <p className=" flex place-self-start pop600 text-base text-left px-3 py-5">
                              {answerOption.answerText }{" "} 
                            </p>{" "}
                          </button>
                        </div>
                      )
                    )}
                    <div className="flex flex-row justify-between">
                      <div className=" my-5 ">
                        <div className=" w-full flex justify-center ">
                          <button
                            className=" w-[280px] xmd:w-[250px] h-[60px] sm:w-[150px] xsm:w-[150px] items-center justify-start flex flex-row "
                            onClick={() => Restituzione()}
                          >
                            {" "}
                            <p className="pop600 text-lg text-black text-center p-3 flex flex-row items-center justify-center ">
                              <img src={indietro} alt="" className="pr-5"/> Indietro
                            </p>{" "}
                          </button>{" "}
                        </div>
                      </div>
                      {back && (
                        <div className=" my-5">
                          <div className=" w-full flex justify-center">
                            <button
                              className=" w-[280px] xmd:w-[250px] h-[60px] bg-[#00B27A] xsm:w-[150px] sm:w-[150px] hover:bg-[#00C587] border-4 border-black  "
                              onClick={() => avanti()}
                            >
                              {" "}
                              <p className="  pop600 text-lg text-white text-center p-3 ">
                                Avanti <NavigateNextIcon />{" "}
                              </p>{" "}
                            </button>{" "}
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <div className=" bg-[#114653] sticky  bottom-0 left-0 right-0 p-5 px-10  md:hidden xmd:hidden lg:hidden xl:hidden ">
                      <button
                        className=" w-full h-20 bg-white hover:bg-gray-200  border-4 border-black pop500 text-lg text-black text-left "
                        onClick={() => avanti()}
                      >
                        {" "}
                        <p className="  pop600 text-lg text-black text-center p-3 ">
                          Avanti <NavigateNextIcon />{" "}
                        </p>{" "}
                      </button>
                    </div> */}
                  </div>

                  <div className="w-[40%] flex flex-row px-5 pb-5 items-start xsm:hidden sm:hidden xsm:w-full sm:w-full md:w-full">
                    <img src={questions[currentQuestion].questionImg} alt="" />
                  </div>
                </div>
              )}

              {currentQuestion === 0 && (
                <div className="w-full flex flex-row xsm:flex-col-reverse sm:flex-col-reverse md:flex-col-reverse">
                  <ScrollToTop />

                  <div className="xsm:w-full sm:w-full md:w-full w-[60%] xsm:mr-0 sm:mr-0 mr-8 ">
                    <div className="grid gap-4 mx-auto ">
                      {questions[currentQuestion].answerOptions.map(
                        (answerOption, index) => (
                          <div key={index}>
                            {/* <button
                              style={{
                                backgroundColor:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "#114653",
                                backgroundImage:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && `url(${check})`,
                                backgroundRepeat:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "no-repeat",
                                backgroundPosition:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "50% 80%",
                                color:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "white",
                              }}
                              className={`flex w-full xsm:w-[150px] sm:w-[150px] sm:h-[200px] xsm:h-[200px] border-4 border-black text-[#114653] md:hidden xmd:hidden lg:hidden xl:hidden`}
                              onClick={() => {
                                handleAnswerOptionClick(
                                  answerOption.answerText,
                                  answerOption.esp,
                                  answerOption.vian,
                                  answerOption.tav,
                                  answerOption.pp
                                );
                              }}
                            >
                              <p className=" flex place-self-start pop600 text-base text-left px-3 py-5 ">
                                {answerOption.answerText}{" "}
                              </p>
                            </button> */}

                            <button
                              style={{
                                backgroundColor:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "#114653",
                                backgroundImage:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && `url(${check})`,
                                backgroundRepeat:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "no-repeat",
                                backgroundPosition:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "98% 50%",
                                color:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "white",
                              }}
                              className={`flex w-full border-4 border-black text-[#114653]`}
                              onClick={() => {
                                handleAnswerOptionClick(
                                  answerOption.answerText,
                                  answerOption.esp,
                                  answerOption.vian,
                                  answerOption.tav,
                                  answerOption.pp
                                );
                              }}
                            >
                            <div className=" flex flex-row px-3">
                            <div className="  flex items-center  ">
                             <img src={answerOption.answerImg} alt="" className="w-[30px] h-[30px] mx-auto   "/></div>
                             <div className=" ">
                              <p className=" flex place-self-start pop600 text-base text-left px-2 py-5 ">
                                {answerOption.answerText}{" "}
                              </p></div>
                              </div>
                            </button>
                          </div>
                        )
                      )}
                    </div>
                    <div className="flex flex-row justify-between ">
                      <div className=" my-5 ">
                        <div className=" w-full flex justify-center items-center">
                          <button
                            className="w-[280px] lg:w-[260px] h-[60px] xmd:w-[250px] xsm:w-[150px] sm:w-[150px] items-center justify-start flex flex-row  "
                            onClick={() => navigate("/quiz")}
                          >
                            {" "}
                            <p className="pop600 text-lg text-black text-center p-3 flex flex-row items-center justify-center ">
                              <img src={indietro} alt="" className="pr-5"/> Indietro
                            </p>{" "}
                          </button>{" "}
                        </div>
                      </div>
                      <div className=" my-5">
                        {uniqueAnswers.length >= 3 && (
                          <div className=" w-full flex justify-center ">
                            <button
                              className=" w-[280px] lg:w-[260px] h-[60px] bg-[#00B27A] xsm:w-[150px] sm:w-[150px] xmd:w-[250px] hover:bg-[#00C587] border-4 border-black "
                              onClick={() => avanti1()}
                            >
                              {" "}
                              <p className="  pop600 text-lg text-white text-center p-3 ">
                                Avanti <NavigateNextIcon />{" "}
                              </p>{" "}
                            </button>{" "}
                          </div>
                        )}

                        {uniqueAnswers.length <= 2 && (
                          <div className=" w-full flex justify-center ">
                            <button
                              className=" w-[280px] lg:w-[260px] h-[60px] xmd:w-[250px] xsm:w-[150px] sm:w-[150px] bg-[#E5E5E5] border-4 border-[#D6D6D6] "
                              onClick={() => avanti1()}
                            >
                              {" "}
                              <p className="  pop600 text-lg text-white text-center p-3 ">
                                Avanti <NavigateNextIcon />{" "}
                              </p>{" "}
                            </button>{" "}
                          </div>
                        )}
                      </div>
                    </div>
                    {/* <div className=" bg-[#114653] sticky  bottom-0 left-0 right-0 p-5 px-10  md:hidden xmd:hidden lg:hidden xl:hidden ">
                      <button
                        className=" w-full h-20 bg-white hover:bg-gray-200  border-4 border-black pop500 text-lg text-black text-left "
                        onClick={() => avanti()}
                      >
                        {" "}
                        <p className="  pop600 text-lg text-black text-center p-3 ">
                          Avanti <NavigateNextIcon />{" "}
                        </p>{" "}
                      </button>
                    </div> */}
                  </div>

                  <div className="w-[40%] flex flex-row px-5 pb-5 items-start xsm:hidden sm:hidden xsm:w-full sm:w-full md:w-full">
                    <img src={question1} alt="" />
                  </div>
                  {/*<p className='Brevia850 text-xl font-black not-italic  text-[#114653]'>
										espresso {score} <br></br> viandante {score1} <br></br>tavola {score2}<br></br> pp {scorepp} <br></br> selezione {uniqueAnswers.length}</p>*/}
                </div>
              )}

              {currentQuestion === 5 && (
                <div className="w-full flex flex-row xsm:flex-col-reverse sm:flex-col-reverse md:flex-col-reverse ">
                  <ScrollToTop />

                  <div className="xsm:w-full sm:w-full md:w-full w-[60%] xsm:mr-0 sm:mr-0 mr-8 ">
                    <div className="grid gap-4 mx-auto ">
                      {questions[currentQuestion].answerOptions.map(
                        (answerOption, index) => (
                          <div key={index}>
                            {/* <button
                              style={{
                                backgroundColor:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "#114653",
                                backgroundImage:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && `url(${check})`,
                                backgroundRepeat:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "no-repeat",
                                backgroundPosition:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "50% 80%",
                                color:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "white",
                              }}
                              className={`flex w-full xsm:w-[150px] sm:w-[150px]  sm:h-[200px] h-20  xsm:h-[200px] border-4 border-black  text-[#114653] md:hidden xmd:hidden lg:hidden xl:hidden `}
                              onClick={() => {
                                handleAnswerOptionClick(
                                  answerOption.answerText,
                                  answerOption.esp,
                                  answerOption.vian,
                                  answerOption.tav,
                                  answerOption.pp
                                );
                              }}
                            >
                              <p className=" flex place-self-start  pop600 text-base text-left p-3 ">
                                {answerOption.answerText}{" "}
                              </p>
                            </button> */}

                            <button
                              style={{
                                backgroundColor:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "#114653",
                                backgroundImage:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && `url(${check})`,
                                backgroundRepeat:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "no-repeat",
                                backgroundPosition:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "93% 50%",
                                color:
                                  theChosenAnswers.includes(
                                    answerOption.answerText
                                  ) && "white",
                              }}
                              className={`flex w-full border-4 border-black text-[#114653] `}
                              onClick={() => {
                                handleAnswerOptionClick(
                                  answerOption.answerText,
                                  answerOption.esp,
                                  answerOption.vian,
                                  answerOption.tav,
                                  answerOption.pp
                                );
                              }}
                            >
                                    <div className=" flex flex-row px-3">
                            <div className="  flex items-center  ">
                             <img src={answerOption.answerImg} alt="" className="w-[30px] h-[30px] mx-auto   "/></div>
                             <div className=" ">
                              <p className=" flex place-self-start pop600 text-base text-left px-2 py-5 ">
                                {answerOption.answerText}{" "}
                              </p></div>
                              </div>
                            </button>
                          </div>
                        )
                      )}

                      {/* <p className="Brevia850 text-xl font-black not-italic  text-[#114653]">
                        espresso {score} <br></br> viandante {score1} <br></br>
                        tavola {score2}
                        <br></br> pp {scorepp} <br></br> selezione{" "}
                        {uniqueAnswers.length}
                      </p> */}
                    </div>
                    {/* <div className=" bg-[#114653] sticky  bottom-0 left-0 right-0 p-5 px-10  md:hidden xmd:hidden lg:hidden xl:hidden">
                      <button
                        className=" w-full h-20 bg-white hover:bg-gray-200  border-4 border-black pop500 text-lg text-black text-left "
                        onClick={() => avanti2()}
                      >
                        {" "}
                        <p className="  pop600 text-lg text-black text-center p-3 ">
                          Avanti <NavigateNextIcon />{" "}
                        </p>{" "}
                      </button>
                    </div> */}
                    <div className="flex flex-row justify-between">
                      <div className=" my-5 ">
                        <div className=" w-full flex justify-center">
                          <button
                            className=" w-[280px] xmd:w-[250px] lg:w-[260px] h-[60px] xsm:w-[150px] sm:w-[150px] items-center justify-start flex flex-row "
                            onClick={() => Restituzione()}
                          >
                            {" "}
                            <p className="pop600 text-lg text-black text-center p-3 flex flex-row items-center justify-center ">
                              <img src={indietro} alt="" className="pr-5"/> Indietro
                            </p>{" "}
                          </button>{" "}
                        </div>
                      </div>
                      <div className=" my-5">
                        {uniqueAnswers.length >= 8 && (
                          <div className=" w-full flex justify-center ">
                            <button
                              className=" w-[280px] xmd:w-[250px] lg:w-[260px] h-[60px] xsm:w-[150px] sm:w-[150px] bg-[#00B27A] hover:bg-[#00C587] border-4 border-black "
                              onClick={() => avanti2()}
                            >
                              {" "}
                              <p className="  pop600 text-lg text-white text-center p-3 ">
                                Avanti <NavigateNextIcon />{" "}
                              </p>{" "}
                            </button>{" "}
                          </div>
                        )}

                        {uniqueAnswers.length <= 7 && (
                          <div className=" w-full flex justify-center">
                            <button
                              className="w-[280px] xmd:w-[250px] lg:w-[260px] h-[60px] xsm:w-[150px] sm:w-[150px] bg-[#E5E5E5] border-4 border-[#D6D6D6] "
                              onClick={() => avanti2()}
                            >
                              {" "}
                              <p className="  pop600 text-lg text-white text-center p-3 ">
                                Avanti <NavigateNextIcon />{" "}
                              </p>{" "}
                            </button>{" "}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="w-[40%] flex flex-row pb-5 items-start xsm:hidden sm:hidden xsm:w-full sm:w-full md:w-full">
                    <img src={question6} alt="" />
                  </div>
                </div>
              )}

              {/* {currentQuestion === 6 && (
                <div className="flex flex-col lg:flex-row xl:flex-row xmd:flex-row gap-6 p-6 xsm:flex-col-reverse sm:flex-col-reverse md:flex-col-reverse ">
                  <ScrollToTop />
                  <div className=" xsm:w-full sm:w-full md:w-full w-[60%] ">
                    {questions[currentQuestion].answerOptions.map(
                      (answerOption, index) => (
                        <div className="  " key={index}>
                          <div className="flex flex-col gap-y-4 pb-9 ">
                            <p className="pop700 text-base text-black">
                              {answerOption.answerText}
                            </p>
                            <input
                              onChange={(e) => handleChange(e, index)}
                              value={answerOption.value}
                              placeholder={answerOption.placeholder}
                              name={answerOption.name}
                              type={answerOption.type}
                              className="h-20 sm:h-16 xsm:h-16 xsm:text-base w-full border-4 border-black px-5 text-xl pop500"
                            />
                          </div>
                        </div>
                      )
                    )}
                    <div className="flex flex-row justify-between ">
                      <div className=" my-5 ">
                        <div className="w-full flex justify-center">
                          <button
                            className="w-[250px] xmd:w-[240px] h-[60px] xsm:w-[120px] sm:w-[120px] items-center justify-start flex flex-row "
                            onClick={() => Restituzione()}
                          >
                            {" "}
                            <p className="pop600 text-lg text-black text-center p-3 flex flex-row items-center justify-center ">
                              <img src={indietro} alt="" className="pr-5 xsm:pr-2 sm:pr-2"/> Indietro
                            </p>{" "}
                          </button>{" "}
                        </div>
                      </div>
                      <div className="my-5 ">
                        <div className="w-full flex justify-center ">
                          <button
                            className="w-[250px] xmd:w-[240px] xsm:w-[120px] sm:w-[120px] h-[60px] bg-[#00B27A] hover:bg-[#00C587] border-4 border-black "
                            onClick={() => avanti()}
                          >
                            {" "}
                            <p className="pop600 text-lg text-white text-center p-3 xsm:p-2 sm:p-2">
                              Avanti <NavigateNextIcon />{" "}
                            </p>{" "}
                          </button>{" "}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="w-[40%] flex flex-row px-5 pb-5 items-start xsm:hidden sm:hidden xsm:w-full sm:w-full md:w-full ">
                    <img src={question7} alt="" />
                  </div>
                </div>
              )} */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
