import ProductTourButton from "../../ProductTourButon";
import Tag from "../../Tag";
import styles from "../Elements.module.css";

const Loyalty = ({ t }) => {
  const tags = [
    t("homePage.attractiveSection.loyalty.tags.1"),
    t("homePage.attractiveSection.loyalty.tags.2"),
    t("homePage.attractiveSection.loyalty.tags.3"),
  ];
  return (
    <div className={styles.scrolled_section}>
      <h3 className="Brevia700  text-black xsm:text-center sm:text-center md:text-center">
        {t("homePage.attractiveSection.loyalty.title")}
      </h3>
      <div className="flex flex-col gap-x-4 w-full my-4">
        <p className="pop400 text-base md:text-lg sm:text-lg text-black w-full xsm:text-center sm:text-center md:text-center">
          {t("homePage.attractiveSection.loyalty.descriptionPart1")}
          <span className="pop700">
            {t("homePage.attractiveSection.loyalty.bold1")}
          </span>
          {t("homePage.attractiveSection.loyalty.descriptionPart2")}
          <span className="pop700">
            {t("homePage.attractiveSection.loyalty.bold2")}
          </span>
          {t("homePage.attractiveSection.loyalty.descriptionPart3")}
          <span className="pop700">
            {t("homePage.attractiveSection.loyalty.bold3")}
          </span>
        </p>
      </div>
      <div className="pb-10 md:text-center sm:text-center">
        <h5 className="pop600 py-2">
          {t("homePage.attractiveSection.doItWith")}
        </h5>
        <div className="flex flex-row w-full gap-2 flex-wrap md:justify-center sm:justify-center">
          {tags.map((tag) => (
            <Tag title={tag} color="[#114653]" textColor="white" />
          ))}
        </div>
      </div>
      <ProductTourButton fill="black" t={t} />
    </div>
  );
};
export default Loyalty;
