import CheckRoundedIcon from "./icons/CheckSvg";
import DirectionsBikeIcon from "./icons/BicycleSvg";
import TableRestaurantOutlinedIcon from "./icons/TableSvg.jsx";
import cockadeImg from "../img/2.png";

export const planData = (t) => [
  {
    annual: 220,
    monthly: 250,
    type: t("pricesPage.plansSection.firstPlan.type"),
    offers: [
      {
        offer: t("pricesPage.plansSection.firstPlan.offers.1"),
        icon: CheckRoundedIcon,
      },
      {
        offer: t("pricesPage.plansSection.firstPlan.offers.2"),
        icon: CheckRoundedIcon,
      },
      {
        offer: t("pricesPage.plansSection.firstPlan.offers.3"),
        icon: CheckRoundedIcon,
      },
    ],
    buttonProps: {
      text: t("pricesPage.plansSection.firstPlan.buttonText"),
      borderColor: "rcblue",
      textColor: "rcblue",
    },
    sectionId: "basic_package_section",
  },
  {
    annual: 260,
    monthly: 300,
    type: t("pricesPage.plansSection.secondPlan.type"),
    addition: t("pricesPage.plansSection.secondPlan.addition"),
    icon: cockadeImg,
    offers: [
      {
        offer: t("pricesPage.plansSection.secondPlan.offers.1"),
        icon: CheckRoundedIcon,
      },
      {
        offer: t("pricesPage.plansSection.secondPlan.offers.2"),
        icon: DirectionsBikeIcon,
      },
      {
        offer: t("pricesPage.plansSection.secondPlan.offers.3"),
        icon: TableRestaurantOutlinedIcon,
      },
    ],
    buttonProps: {
      text: t("pricesPage.plansSection.secondPlan.buttonText"),
      borderColor: "rcblue",
      textColor: "rcblue",
    },
    sectionId: "recommended_package_section",
  },
  {
    annual: 280,
    monthly: 325,
    type: t("pricesPage.plansSection.thirdPlan.type"),
    addition: t("pricesPage.plansSection.thirdPlan.addition"),
    offers: [
      {
        offer: t("pricesPage.plansSection.thirdPlan.offers.1"),
        icon: CheckRoundedIcon,
      },
      {
        offer: t("pricesPage.plansSection.thirdPlan.offers.2"),
        icon: DirectionsBikeIcon,
      },
      {
        offer: t("pricesPage.plansSection.thirdPlan.offers.3"),
        icon: TableRestaurantOutlinedIcon,
      },
    ],
    buttonProps: {
      text: t("pricesPage.plansSection.thirdPlan.buttonText"),
      borderColor: "rcblue",
      textColor: "rcblue",
    },
    sectionId: "one_platform_package_section",
  },
];
