import React from "react";
import altoparlanteImg from "../img/Opera_senza_titolo 2.png";
import binocoleImg from "../img/Opera_senza_titolo 4.png";
import personImg from "../img/Opera_senza_titolo 4 1.png";
import styles from "./OnePlatform.module.css";
import cn from "classnames";

const OnePlatformSection = ({ t }) => {
  const iconsWithText = [
    {
      img: binocoleImg,
      text: t("pricesPage.onePlatformSection.items.1"),
    },
    {
      img: altoparlanteImg,
      text: t("pricesPage.onePlatformSection.items.2"),
    },
    {
      img: personImg,
      text: t("pricesPage.onePlatformSection.items.3"),
    },
  ];
  return (
    <>
      <div
        id="one_platform_package_section"
        className="2xl:w-[80%] m-auto p-12"
      >
        <div className="w-full text-center mx-auto">
          <h3 className={styles.title}>
            {t("pricesPage.onePlatformSection.title")}
          </h3>
          <div className={cn("mt-6", styles.text_container)}>
            <p className={styles.text}>
              {t("pricesPage.onePlatformSection.des1")}
            </p>
            <p className={styles.text}>
              {t("pricesPage.onePlatformSection.des2")}
            </p>
          </div>
        </div>

        <div className="flex flex-row justify-between w-full flex-wrap">
          {iconsWithText.map((item, index) => (
            <div className={styles.img_item} key={index}>
              <img
                src={item.img}
                alt="img"
                className={cn(styles.img, {
                  [styles.more_bottom_img]: index === 2,
                })}
              />
              <h6 className={styles.img_sub_text}>{item.text}</h6>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default OnePlatformSection;
