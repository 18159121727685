import React from "react";

import SectionBaseJumbotron from "./SectionBaseComponents/SectionBaseJumbotron";
import SectionBaseBigImgTitleButtonSection from "./SectionBaseComponents/SectionBaseBigImgTitleButtonSection";
import ColoredThreeParagraphSection from "./SectionBaseComponents/ColoredThreeParagraphSection";
import LightGrayThreeImgSection from "./SectionBaseComponents/LightGrayThreeImgSection";

function SectionBase() {
    return (
        <>
            <div className="w-full">

                <SectionBaseJumbotron />

                <SectionBaseBigImgTitleButtonSection />

                <ColoredThreeParagraphSection />

                <LightGrayThreeImgSection />

            </div>
        </>
    );
}

export default SectionBase;