import * as React from "react";
import es from "./icon/ES.png";
import fr from "./icon/FR.png";
import it from "./icon/IT.png";
import { LanguageContext } from "../../context/LanguageContext";
import us from "./icon/US.png";

export const LanguageSelector = () => {
  const { language, changeLanguage } = React.useContext(LanguageContext);
  const options = [
    { label: "Italiano", value: "it", },
    { label: "English", value: "en", },
    { label: "Espanol", value: "es", },
    { label: "French", value: "fr", },
  ];
  const icons = { it, en: us, es, fr };

  return (
    <div style={{ display: "flex", alignItems: "center", marginLeft: 10 }}>
      <div className="select-option">
        <img src={icons[language]} alt="img" />
      </div>
      <select
        className="w-[80px]"
        style={{ marginLeft: 5, background: "transparent" }}
        value={language}
        id="LanguageSelector"
        onChange={(option) =>
          changeLanguage && changeLanguage(option.target.value)
        }>

        {options.map((option, index) => (
          <option key={index} value={option.value}>
            <p>{option.label}</p>
          </option>
        ))}

      </select>

    </div>
  );
};
