import { isValidNumber } from "libphonenumber-js";

import messages from "./errorMessages";
import { logger } from "../Services/Logger";

const testRegex = (value, regex) =>
  value === "" || (value && value.toString().match(regex) !== null);

const validatorsFunctions = (t) => ({
  email: (val) =>
    testRegex(val, /^[A-Z0-9.!#$%&'*+-/=?^_`{|}~]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i)
      ? undefined
      : messages(t).email,
  phone: (val) => {
    if (!val) return undefined;
    return isValidNumber(`+${val}`) ? undefined : messages(t).phone;
  },
  required: (val) => {
    logger.debug("Required field:", { val });
    if (val instanceof Array)
      return val.length !== 0 ? undefined : messages(t).required;

    return val ? undefined : messages(t).required;
  },
});

const Validators = (t, params) => {
  logger.debug("Validator:", params);
  let validators = {};

  params.forEach((o) => {
    const { validation } = o;
    logger.debug("Validation: ", { validation });
    validators = {
      ...validators,
      [validation]: validatorsFunctions(t)[validation],
    };
  });

  return { validate: validators };
};

export default Validators;
