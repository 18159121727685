import HubspotForm from "react-hubspot-form";
import styles from "./FindSolution.module.css";
import cn from "classnames";
import { logger } from "../../../Services/Logger";

const FindSolutionSection = ({ t }) => {
  return (
    <div id="contact_form" className="w-[80%] m-auto pt-10 flex flex-wrap">
      <div className={cn("w-[50%]", styles.text_container)}>
        <h1 className={styles.title}>
          {t("pricesPage.findSolutionSection.title")}
        </h1>
        <p className={styles.text}>
          {t("pricesPage.findSolutionSection.description")}
        </p>
      </div>
      <div className={cn("w-[50%]", styles.form_container)}>
        <HubspotForm
          region="eu1"
          portalId="26462757"
          formId="110f5a98-23d9-46b6-9c2d-3b530423d61b"
          onSubmit={() => logger.info("Find solution form sended")}
          onReady={(form) => logger.debug("Find solution form is ready")}
          loading={<div>Caricamento...</div>}
        />
      </div>
    </div>
  );
};
export default FindSolutionSection;
