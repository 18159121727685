import Attractive from "../../../Components/Home/HomeElements/AttractiveElements/Attractive";
import Converted from "../../../Components/Home/HomeElements/AttractiveElements/Converted";
import AttractiveFrame from "../../../Components/Home/HomeElements/AttractiveElements/ImagesContainer/icons/AttractiveFrame";
import ConvertedFrame from "../../../Components/Home/HomeElements/AttractiveElements/ImagesContainer/icons/ConvertedFrame";
import LoyaltyFrame from "../../../Components/Home/HomeElements/AttractiveElements/ImagesContainer/icons/LoyaltyFrame";
import Loyalty from "../../../Components/Home/HomeElements/AttractiveElements/Loyalty";
import cn from "classnames";
import styles from "./Attractive.module.css";
const AttractiveMobile = ({ t }) => {
  return (
    <div id="wibx" className={cn("flex flex-col", styles.mobile)}>
      <div className=" w-full flex justify-center bg-[#FFFFFF]">
        <div className="w-[80%] mx-auto">
          <div className="flex items-center justify-center">
            <AttractiveFrame />
          </div>

          <div className="w-full">
            <Attractive t={t} />
          </div>
        </div>
      </div>
      <div className=" w-full flex justify-center bg-[#114653]">
        <div className="w-[80%] mx-auto">
          <div className="flex items-center justify-center pt-10">
            <ConvertedFrame />
          </div>

          <div className="w-full">
            <Converted t={t} />
          </div>
        </div>
      </div>
      <div className=" w-full flex justify-center bg-[#F5F5F5]">
        <div className="w-[80%] mx-auto">
          <div className="flex items-center justify-center">
            <LoyaltyFrame />
          </div>

          <div className="w-full">
            <Loyalty t={t} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default AttractiveMobile;
