import React from "react";
import styles from "./ReachGoal.module.css";

const ReachGoalsSection = ({ t }) => {
  const redirectToContatti = () => {
    window.location.href = "/Contatti";
  };

  return (
    <div className={styles.container}>
      <span className={styles.title}>
        {t("pricesPage.reachGoalsSection.title")}
      </span>
      <div className={styles.button_wrapper}>
        <button onClick={redirectToContatti} className={styles.button}>
          {t("pricesPage.reachGoalsSection.button")}
        </button>
      </div>
    </div>
  );
};

export default ReachGoalsSection;
