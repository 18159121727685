import React from "react";
import bakeryImg from "../../../Navigation/img/croissant.png";
import barImg from "../../../Navigation/img/caffe.png";
import darkImg from "../../../Navigation/img/dark.png";
import newOpeningImg from "../../../Navigation/img/nuove.png";
import pizzaImg from "../../../Navigation/img/pizzerie.png";
import ristoImg from "../../../Navigation/img/ristorazione.png";
import TrialButton from "../../../Components/Pricing/TrailButton";
import pastaImg from "../img/pasta 1.png";
import styles from "./BuiltFor.module.css";
import cn from "classnames";
import { scrollToSection } from "../../../utils/scrollToSection";

const BuiltForSection = ({ t }) => {
  const builtFor = [
    {
      icon: newOpeningImg,
      title: "pricesPage.builtForSection.items.1",
    },
    {
      icon: ristoImg,
      title: "pricesPage.builtForSection.items.2",
    },
    {
      icon: darkImg,
      title: "pricesPage.builtForSection.items.3",
    },
    {
      icon: pizzaImg,
      title: "pricesPage.builtForSection.items.4",
    },
    {
      title: "pricesPage.builtForSection.items.5",
      icon: barImg,
    },
    {
      title: "pricesPage.builtForSection.items.6",
      icon: bakeryImg,
    },
  ];
  return (
    <div
      className={cn(
        "py-12 w-full bg-rcblue relative my-12",
        styles.main_container
      )}
    >
      <div className={cn("w-[70%] flex mx-auto flex-wrap", styles.container)}>
        <div className={cn("w-[45%]", styles.built_for)}>
          <h4 className={styles.title}>
            {t("pricesPage.builtForSection.title")}
          </h4>

          <div
            className={cn("flex flex-col flex-wrap", styles.items_container)}
          >
            {builtFor.map((item, index) => (
              <div
                className={cn(
                  `flex items-center mt-5 text-white`,
                  styles.item_container
                )}
              >
                <span
                  key={index}
                  className="flex items-center justify-center h-[45px] w-[45px] bg-[#f6f6f4] rounded-md"
                >
                  <img src={item.icon} alt=""></img>
                </span>

                <span className={styles.item_text}>{t(item.title)}</span>
              </div>
            ))}
          </div>
          <TrialButton
            className={styles.trail_button}
            text={t("pricesPage.builtForSection.button")}
            textColor={"white"}
            onClick={() => scrollToSection("contact_form")}
          />
        </div>
        <div className={cn("w-[50%] flex", styles.img_wrapper)}>
          <img
            src={pastaImg}
            alt=""
            className={cn("absolute", styles.img_container)}
          ></img>
        </div>
      </div>
    </div>
  );
};

export default BuiltForSection;
