import cn from "classnames";
import { get, useFormContext } from "react-hook-form";

import styles from "./Input.module.css";

const AppInput = ({
  className,
  name,
  classInput,
  classLabel,
  readOnly,
  validate,
  placeholder,
  type = "text",
  id,
  label,
  icon,
  copy,
  leftIcon,
  ...props
}) => {
  const formContext = useFormContext();

  const fieldError = get(formContext?.formState.errors, name);
  return (
    <div className="flex flex-col gap-y-4 pb-9">
      <p className="Brevia700 text-xl md:text-lg  text-white">{label}</p>
      <input
        className={cn(
          "h-20 sm:h-16 w-full border-4 border-black px-5 text-xl pop500",
          { [styles.error]: !!fieldError }
        )}
        data-testid={id}
        readOnly={readOnly}
        {...props}
        {...formContext?.register(name, validate)}
        placeholder={placeholder}
        type={type}
      />
      {fieldError && (
        <div
          data-testid="inputError"
          className={cn(
            "Brevia700 text-xl md:text-lg text-white",
            styles.errorMessage
          )}
        >
          {fieldError.message}
        </div>
      )}
    </div>
  );
};

export default AppInput;
