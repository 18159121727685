const Tag = ({ color, title, textColor }) => {
  return (
    <div className="flex">
      <p
        className={`w-fit px-5 py-2 pop500 uppercase text-${textColor} rounded-full bg-${color}`}
      >
        {title}
      </p>
    </div>
  );
};
export default Tag;
