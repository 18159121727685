const PercentSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <mask
        id="mask0_0_7645"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="25"
      >
        <rect y="0.370239" width="24" height="24" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_0_7645)">
        <path
          d="M7.5 11.3702C6.53333 11.3702 5.70833 11.0286 5.025 10.3452C4.34167 9.66191 4 8.83691 4 7.87024C4 6.90357 4.34167 6.07857 5.025 5.39524C5.70833 4.71191 6.53333 4.37024 7.5 4.37024C8.46667 4.37024 9.29167 4.71191 9.975 5.39524C10.6583 6.07857 11 6.90357 11 7.87024C11 8.83691 10.6583 9.66191 9.975 10.3452C9.29167 11.0286 8.46667 11.3702 7.5 11.3702ZM7.5 9.37024C7.91667 9.37024 8.27083 9.22441 8.5625 8.93274C8.85417 8.64107 9 8.28691 9 7.87024C9 7.45357 8.85417 7.09941 8.5625 6.80774C8.27083 6.51607 7.91667 6.37024 7.5 6.37024C7.08333 6.37024 6.72917 6.51607 6.4375 6.80774C6.14583 7.09941 6 7.45357 6 7.87024C6 8.28691 6.14583 8.64107 6.4375 8.93274C6.72917 9.22441 7.08333 9.37024 7.5 9.37024ZM16.5 20.3702C15.5333 20.3702 14.7083 20.0286 14.025 19.3452C13.3417 18.6619 13 17.8369 13 16.8702C13 15.9036 13.3417 15.0786 14.025 14.3952C14.7083 13.7119 15.5333 13.3702 16.5 13.3702C17.4667 13.3702 18.2917 13.7119 18.975 14.3952C19.6583 15.0786 20 15.9036 20 16.8702C20 17.8369 19.6583 18.6619 18.975 19.3452C18.2917 20.0286 17.4667 20.3702 16.5 20.3702ZM16.5 18.3702C16.9167 18.3702 17.2708 18.2244 17.5625 17.9327C17.8542 17.6411 18 17.2869 18 16.8702C18 16.4536 17.8542 16.0994 17.5625 15.8077C17.2708 15.5161 16.9167 15.3702 16.5 15.3702C16.0833 15.3702 15.7292 15.5161 15.4375 15.8077C15.1458 16.0994 15 16.4536 15 16.8702C15 17.2869 15.1458 17.6411 15.4375 17.9327C15.7292 18.2244 16.0833 18.3702 16.5 18.3702ZM4.7 19.6702C4.51667 19.4869 4.425 19.2536 4.425 18.9702C4.425 18.6869 4.51667 18.4536 4.7 18.2702L17.9 5.07024C18.0833 4.88691 18.3167 4.79524 18.6 4.79524C18.8833 4.79524 19.1167 4.88691 19.3 5.07024C19.4833 5.25357 19.575 5.48691 19.575 5.77024C19.575 6.05357 19.4833 6.28691 19.3 6.47024L6.1 19.6702C5.91667 19.8536 5.68333 19.9452 5.4 19.9452C5.11667 19.9452 4.88333 19.8536 4.7 19.6702Z"
          fill="#475467"
        />
      </g>
    </svg>
  );
};
export default PercentSvg;
