import React from 'react';
import '../../App.css';
import ScrollToTop from '../../Reusable/ScrollToTop';


const PrivacyFoodies = () => {

    return (

        <>

            <ScrollToTop />

            <div className="flex flex-col w-[80%] text-left justify-center items-center mx-auto">

                <h1 className="my-8 Brevia700 text-5xl text-[#00B27A]">Informativa sul trattamento dei Dati Personali</h1>

                <p>


                    Lo scopo del presente documento è di informare la persona fisica (di seguito "<span className="font-bold">Interessato</span>" ) relativamente al
                    trattamento dei suoi dati personali (di seguito “<span className="font-bold">Dati Personali</span>”) raccolti dal titolare del trattamento: <span className="font-bold">AiGot S.r.l.</span>
                    , con sede legale in Via Comelico 18, 20135 Milano (MI) e sede operativa in Pisa (PI) via
                    Santa Maria 25, cap 56126, CF/P.IVA 11498080966, indirizzo e-mail privacy@aigot.com, indirizzo
                    PEC aigot@pec.com, telefono 0500985159 (di seguito “<span className="font-bold">AiGot</span>” o “<span className="font-bold">Titolare</span>”).


                </p>

                <br />



                <br />

                <div className="flex flex-col" >

                    <ol className="list-decimal">

                        <div className="flex flex-row ml-4 mb-2">

                            <p className="mr-2 font-bold">1.Categorie di Dati Personali trattati</p>

                        </div>

                        <p className="ml-10 mb-2">Il Titolare tratta le seguenti tipologie di Dati Personali forniti volontariamente dall’Interessato:</p>

                        <ul className="flex flex-col list-disc gap-y-4" id="cookie">

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p><span className="font-bold">Dati di contatto:</span> nome, cognome, indirizzo, e-mail, recapito telefonico, codice fiscale, credenziali di
                                autenticazione al sito web e app.
                            </p></li>

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p><span className="font-bold">Dati fiscali e di pagamento:</span>  partita IVA, dati di acquisto, dati di pagamento</p></li>


                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p><span className="font-bold">Dati tecnici:</span>dati di navigazione, indirizzo IP, indicazione tipo di browser, indicazione provider internet,
                                dati di localizzazione.
                            </p></li>

                            <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p><span className="font-bold">Dati particolari:</span> di cui all’art. 9 GDPR, a titolo esemplificativo dati relativi alla salute dell’Interessato con
                                particolare riferimento ad allergie e intolleranze alimentari.
                            </p></li>



                        </ul>



                        <p className="ml-10 my-2">L’Interessato che comunichi al Titolare Dati Personali di terzi è direttamente ed esclusivamente responsabile della loro provenienza, raccolta, trattamento, comunicazione o diffusione.</p>

                    </ol>

                </div>



                <br />

                <div className="flex flex-col">

                    <ol className="list-decimal">

                        <div className="flex flex-row ml-4 mb-2">

                            <p className="mr-2 font-bold">2.Finalità del Trattamento</p>

                        </div>

                        <ol className="flex flex-col list-decimal gap-y-1">

                            <li className="flex flex-row ml-20 mt-4"><p className="mr-4 font-bold">A)</p><p><span className="font-bold">Registrazione e autenticazione dell’Interessato sui siti web del Titolare ed utilizzo del sito:</span></p></li>

                            <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> creazione di un profilo sui siti web del Titolare</p></li>

                            <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> accesso al profilo personale dell'Interessato</p></li>

                            <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> identificazione dell’Interessato, anche tramite piattaforme esterne </p></li>

                            <li className="flex flex-row ml-20"><p className="mr-4">-</p><p>acquisto di prodotti e servizi del Titolare tramite i siti web di questo</p></li>

                        </ol>


                        <p className="ml-10 mb-2 mt-1">

                            Per tutte le suddette finalità il conferimento dei dati è obbligatorio. Il rifiuto di fornire i dati non consente
                            l'esecuzione dei servizi richiesti dall’Interessato.
                        </p>



                        <ol className="flex flex-col list-decimal gap-y-1">

                            <li className="flex flex-row ml-20 mt-4"><p className="mr-4 font-bold">B)</p><p><span className="font-bold">Erogazione di servizi in favore dell’Interessato ed adempimento degli obblighi derivanti
                                dall’eventuale rapporto precontrattuale o contrattuale in essere con l’Interessato</span></p></li>

                            <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> vendita di prodotti e servizi dell’Interessato secondo quanto contrattualmente previsto;</p></li>

                            <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> contatti diretti con l’Interessato per rispondere alle richieste di questo nonché al fine di fornire
                                assistenza e supporto; </p></li>

                            <li className="flex flex-row ml-20"><p className="mr-4">-</p><p>adempimenti relativi ai pagamenti effettuati dall’Interessato.</p></li>

                        </ol>


                        <p className="ml-10 mb-2 mt-1">
                            Per tutte le suddette finalità il conferimento dei dati è obbligatorio. Il rifiuto di fornire i dati non consente
                            l'esecuzione dei servizi richiesti dall’Interessato.
                        </p>





                        <ol className="flex flex-col list-decimal gap-y-1">

                            <li className="flex flex-row ml-20 mt-4"><p className="mr-4 font-bold">C)</p><p><span className="font-bold">Finalità relative alla gestione, ottimizzazione, manutenzione dei sistemi</span></p></li>

                            <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> gestione, ottimizzazione e monitoraggio dell’infrastruttura tecnica dei siti web del Titolare;</p></li>
                            <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> gestione, ottimizzazione e monitoraggio dell’infrastruttura tecnica sottesa ai prodotti ed ai servizi
                                offerti dal Titolare;</p></li>

                            <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> identificazione e risoluzione di eventuali problemi tecnici dei sistemi informatici; </p></li>

                            <li className="flex flex-row ml-20"><p className="mr-4">-</p><p>gestione e organizzazione delle informazioni dei sistemi informatici (ad es. server, database etc.)</p></li>

                            <li className="flex flex-row ml-20"><p className="mr-4">-</p><p>miglioramento delle performance dei sistemi informatici, dei software, dei prodotti e dei servizi
                                offerti dal Titolare.</p></li>

                        </ol>


                        <p className="ml-10 mb-2 mt-1">

                            Per tutte le suddette finalità il conferimento dei dati è obbligatorio. Il rifiuto di fornire i dati non consente
                            l'esecuzione dei servizi richiesti dall’Interessato.
                        </p>

                        <ol className="flex flex-col list-decimal gap-y-1">

                            <li className="flex flex-row ml-20 mt-4"><p className="mr-4 font-bold">D)</p><p><span className="font-bold">Finalità di marketing previo consenso dell’Interessato:</span></p></li>

                            <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> invio di comunicazioni commerciali con modalità di contatto automatizzate e non (ad es. sms, e-mail,
                                notifiche push tramite app e messaggistica istantanea), tradizionali (ad es. telefonate, posta
                                ordinaria), canali online (ad. es. notifiche pop up sul sito web) o attraverso social network e altre
                                piattaforme web;</p></li>

                            <li className="flex flex-row ml-20"><p className="mr-4 ">-</p><p> elaborazione statistica per lo sviluppo di Campagne Marketing; </p></li>

                            <li className="flex flex-row ml-20"><p className="mr-4">-</p><p>raccolta automatizzata delle informazioni personali dell’Interessato volta ad analizzare, prevedere
                                e valutare le sue preferenze o comportamenti (c.d. profilazione);</p></li>

                            <li className="flex flex-row ml-20"><p className="mr-4">-</p><p>Ricerche di mercato con modalità automatizzate e tradizionali</p></li>

                        </ol>



                        <ol className="flex flex-col list-decimal gap-y-1">

                            <li className="flex flex-row ml-20 mt-4"><p className="mr-4 font-bold">E)</p><p><span className="font-bold">Adempimento di obblighi previsti dalle normative vigenti o da un’ordine dell’Autorità</span></p></li>


                        </ol>


                        <ol className="flex flex-col list-decimal gap-y-1">

                            <li className="flex flex-row ml-20 mt-4"><p className="mr-4 font-bold">F)</p><p><span className="font-bold">Finalità di prevenzione e repressione di frodi / abusi / attività fraudolente</span></p></li>


                        </ol>


                        <ol className="flex flex-col list-decimal gap-y-1">

                            <li className="flex flex-row ml-20 mt-4"><p className="mr-4 font-bold">G)</p><p><span className="font-bold">Accertamento, esercizio e difesa di diritti</span> in qualunque sede, giudiziale e stragiudiziale, ed in
                                qualunque grado sino all’esaurimento dei termini di esperibilità delle impugnazioni e delle azioni
                                previste dall'ordinamento.</p></li>



                        </ol>


                    </ol>

                </div>

                <br />

                <div className="flex flex-col">

                    <ol className="list-decimal">

                        <div className="flex flex-row ml-4 mb-2">

                            <p className="mr-2 font-bold">3. Modalità di trattamento dei Dati Personali e soggetti autorizzati</p>

                        </div>

                        <p className="ml-10 mb-2"> Il trattamento dei Dati Personali viene effettuato mediante strumenti cartacei e/o informatizzati con modalità
                            organizzative e con logiche strettamente correlate alle finalità indicate dalla presente Informativa e
                            mediante l'adozione di adeguate misure di sicurezza.
                            Tutte le operazioni di trattamento dei dati sono attuate in modo da garantire l’integrità, la riservatezza e la
                            disponibilità dei dati personali.
                        </p>

                        <p className="ml-10 mb-4"> I Dati Personali sono trattati esclusivamente da:</p>

                    </ol>

                    <ul className="flex flex-col list-disc gap-y-4">

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p>persone autorizzate dal Titolare del trattamento che si sono impegnate alla riservatezza o che
                            hanno un adeguato obbligo legale di riservatezza;</p></li>

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p>

                            <p>

                                soggetti che operano in autonomia come distinti titolari del trattamento o da soggetti designati quali
                                responsabili del trattamento dal Titolare al fine di svolgere tutte le attività di trattamento necessarie a
                                perseguire le finalità di cui alla presente Informativa (ad esempio Partner commerciali, consulenti,
                                società informatiche, fornitori di servizi, hosting provider);


                            </p>

                        </li>


                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p>

                            <p>
                                soggetti o enti a cui è necessario comunicare i Dati Personali ai sensi dell’art. 4 o per obbligo di legge o
                                per ordine dell’Autorità.


                            </p>

                        </li>
                        <p className="ml-10 my-2">I soggetti sopra elencati sono tenuti a utilizzare le dovute cautele previste dalla legge nonché tutti i
                            comportamenti che si rendano necessari nel caso concreto al fine di proteggere i Dati Personali.
                            Tali soggetti possono accedere solo a quei dati necessari per eseguire i compiti a loro assegnati</p>

                        <p className="ml-10 my-2">I Dati Personali non verranno diffusi indiscriminatamente in alcun modo.
                        </p>

                    </ul>

                </div>

                <br />

                <div className="flex flex-col">

                    <ol className="list-decimal">

                        <div className="flex flex-row ml-4 mb-2">

                            <p className="mr-2 font-bold">4.Ambito di comunicazione</p>

                        </div>

                        <p className="ml-10 mb-2">
                            I dati personali dell’Interessato potranno essere comunicati a soggetti quali, a titolo esemplificativo, autorità
                            di vigilanza e controllo, soggetti pubblici legittimati, quali l’Autorità giudiziaria e/o di pubblica sicurezza,
                            società del gruppo cui il Titolare eventualmente appartenga.
                            I dati potranno inoltre essere trattati per conto del Titolare da soggetti designati responsabili del trattamento
                            quali, ad esempio, società che erogano servizi informatici (manutenzione, hosting, mailing, invio di sms),
                            Partner commerciali, consulenti, società del gruppo cui il Titolare eventualmente appartengano per servizi
                            infragruppo, Meta e Google.

                        </p>

                    </ol>


                </div>

                <br />

                <div className="flex flex-col">

                    <ol className="list-decimal">

                        <div className="flex flex-row ml-4 mb-2">

                            <p className="mr-2 font-bold">5.Periodo di conservazione dei Dati Personali</p>

                        </div>

                        <p className="ml-10 mb-2"> I Dati Personali saranno conservati per il periodo di tempo necessario per il raggiungimento delle finalità
                            per i quali sono stati raccolti, in particolare: </p>

                    </ol>

                    <ul className="flex flex-col list-disc gap-y-4">

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p>

                            <p>

                                i Dati Personali raccolti per la registrazione e autenticazione dell’Interessato sui siti web del Titolare ed
                                utilizzo del sito (art. 3 let. A) e per l’erogazione di servizi in favore dell’Interessato ed adempimento degli
                                obblighi derivanti dal rapporto precontrattuale o contrattuale in essere con l’Interessato (art. 3 let. B)
                                saranno conservati per tutta la durata di tali rapporti e, dopo la cessazione, per il periodo di prescrizione
                                ordinario pari a 10 anni;

                            </p>

                        </li>

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> i Dati Personali raccolti per finalità relative alla gestione, ottimizzazione, manutenzione dei sistemi (art. 3
                            let. C) saranno conservati per tutta la durata del rapporto con l’Interessato e, dopo la cessazione, per il
                            periodo di prescrizione ordinario pari a 10 anni;
                        </p></li>

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> i Dati Personali raccolti per Finalità di marketing, statistica, profilazione e ricerche di mercato (art. 3 let.
                            D) saranno trattati sino alla revoca del consenso da parte dell’Interessato e comunque.
                        </p></li>

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> i Dati Personali raccolti per adempimento degli obblighi previsti dalle normative vigenti (art. 3 let. E),
                            quelli raccolti su ordine dell’Autorità (art. 3 let. E) e quelli raccolti raccolti per finalità di prevenzione e
                            repressione di frodi / abusi / attività fraudolente (art. 3 let. F) saranno conservati nei termini previsti da
                            tali normative e comunque sino al termine prescrizionale previsto dalle norme vigenti;
                        </p></li>

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> i Dati personali raccolti per accertare, esercitare difendere i diritti del Titolare (art. 3 let. G) saranno
                            conservati per tutta la durata del contenzioso, in qualunque sede, giudiziale e stragiudiziale, ed in
                            qualunque grado sino all’esaurimento dei termini di esperibilità delle impugnazioni e delle azioni previste
                            dall'ordinamento.

                        </p></li>

                        <p className="ml-10 my-2">Al termine del periodo di conservazione, tutti i Dati Personali saranno cancellati o conservati in una
                            forma che non consenta l’identificazione dell’Interessato.</p>

                    </ul>

                </div>

                <br />

                <div className="flex flex-col">

                    <ol className="list-decimal">

                        <div className="flex flex-row mb-2">

                            <p className="mr-2 font-bold">6.Diritti dell’Interessato</p>

                        </div>

                        <p className="ml-10 mb-2"> In relazione ai dati oggetto del trattamento di cui alla presente informativa, all’Interessato è riconosciuto in
                            qualsiasi momento il diritto di:
                        </p>

                    </ol>

                    <ul className="flex flex-col list-disc">

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> essere informato sul trattamento dei propri Dati Personali</p></li>

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p>accedere ai propri Dati Personali (art 15 GDPR)</p></li>

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> chiedere la rettifica dei propri Dati Personali (art. 16 GDPR)</p></li>

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p>ottenere la cancellazione dei propri Dati Personali («diritto all’oblio» - art. 17 GDPR), salvo che non
                            sussista fondamento giuridico per il trattamento</p></li>

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> limitare il trattamento dei propri Dati Personali (art. 18 GDPR)</p></li>

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> di trasferire o chiedere al Titolare la trasmissione dei propri Dati Personali ad altro titolare (art 20 GDPR)</p></li>

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> opporsi in qualsiasi momento al trattamento dei propri Dati Personali (art. 21 GDPR) salvo che non
                            sussista fondamento giuridico per il trattamento</p></li>

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> opporsi al trattamento automatizzato dei propri Dati Personali (art. 22 GDPR)
                        </p></li>

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p>revocare in qualsiasi momento il consenso prestato per finalità di marketing e profilazione
                        </p></li>

                        <li className="flex flex-row ml-10"><p className="mr-4 font-bold">•</p><p> proporre reclamo all’Autorità Garante per la Protezione dei dati personali e/o agire in sede giudiziale.</p></li>

                        <p className="ml-10 my-2">Per esercitare i propri diritti, gli Interessati possono indirizzare una richiesta al seguente indirizzo e-mail privacy@aigot.com. </p>

                    </ul>

                </div>

                <br />


                <p className="flex self-start mb-8"> Ultimo aggiornamento: 12/05/202 </p>

            </div>

        </>

    )
}

export default PrivacyFoodies;