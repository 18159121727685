import React from "react";

import cliente from "../../Assets/Rcvs/Plateform/cliente.svg"
import semplice from "../../Assets/Rcvs/Plateform/semplice.svg"
import consultant from "../../Assets/Rcvs/Plateform/consultant.svg"
import chefaccording from "../../Assets/Rcvs/Plateform/chefaccording.png"
import help from "../../Assets/Rcvs/Plateform/help.png"
import quattrocento from "../../Assets/Rcvs/Plateform/400.png"
import quattrox from "../../Assets/Rcvs/Plateform/4x.png"
import pack from "../../Assets/Rcvs/Plateform/pack.png"
import { useNavigate } from "react-router-dom";
import SecondButton from "../../Reusable/LandingButton/SecondButton";
import CalendlyButtonGreen from "../../Reusable/LandingButton/CalendlyButton";





const Plateform = () => {

  const navigate = useNavigate()


  return (
    <div className="">
      {/*---------------------------------------------------- Container ---------------------------------------------------*/}



      <div className="w-[80%] mx-auto  flex flex-col  xsm:w-[90%] ">
        
        
        
        {/*-------------------------------------------------- Main Heading ----------------------------------------------- */}

        <div className='flex flex-col items-center justify-center gap-y-10 mt-10 '>
        <h1 className="Brevia850 text-center text-[#114653]">
        La gestione digitale con Plateform è utile. <br className="md:hidden sm:hidden xsm:hidden" />Fare Marketing online con Restaurants Club è vincente.
        </h1>
        <p className="pop500 text-center xl:w-[70%] lg:w-[70%]">
         Non limitarti a “gestire” il cliente da quando è entrato nel tuo locale. <span className='pop700'>Attrai</span> nuovi interessati online, <span className='pop700'>convertili </span>
         in clienti e poi <span className='pop700'>fidelizzali</span>. 
         Tutto con gli strumenti di Marketing semplificati di Restaurants Club.
        </p>

        <div className="pb-10">
          <CalendlyButtonGreen url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club" text="Prenota una chiamata"/>
        </div>

        </div>



        {/* Section 2 */}
        <div className='flex flex-col items-center justify-center gap-y-8 '>

          <h3 className="Brevia700 italic	text-[#114653] text-center ">
           Perché scegliere Restaurants Club
            <br className="md:hidden sm:hidden xsm:hidden" /> e non Plateform?
         </h3>

        </div>

        <div className="row flex flex-row xsm:flex-col sm:flex-col  mt-10  justify-center gap-8 md:gap-5">
          <div className="w-1/3 mx-auto md:w-[100%] sm:w-[100%] xsm:w-[90%]  ">
          <div className="w-[60%]  mx-auto flex justify-center ">
            <img src={cliente} className="" />
            </div>
            <div className="text-center mt-10">
              <h5 className="pop700 mb-2">
              Da utente online a cliente fedele
              </h5>
              <p className="pop500">
              Con Restaurants Club fatti conoscere online con Gestione Social 
              e Marketing per convertire gli utenti online in clienti
              </p>
            </div>
          </div>

          <div className="w-1/3 mx-auto md:w-[100%] sm:w-[100%] xsm:w-[90%]  ">
          <div className="w-[60%] mx-auto flex justify-center ">
            <img src={semplice} alt="-" />
            </div>
            <div className="text-center mt-10">
              <h5 className="pop700 mb-2">
                Più semplice, prevedibile ed efficace
              </h5>
              <p className="pop500">
                Fare un Marketing vincente è facile con le pubblicità social pre-ottimizzate: con un click vedi i risultati previsti e vai online!
              </p>
            </div>
          </div>

          <div className="w-1/3 mx-auto md:w-[100%] sm:w-[100%] xsm:w-[90%]  ">
          <div className="w-[60%] mx-auto flex justify-center ">
            <img src={consultant} alt="-" /></div>
            <div className="text-center mt-10">
              <h5 className="pop700 mb-2">
              Non sei solo: i nostri Consulenti per te
              </h5>
              <p className="pop500">
              I nostri Consulenti di Marketing sono a tua disposizione per 
              consigliarti e supportarti nel lancio della tua strategia digital
              </p>
            </div>
          </div>
        </div>

        {/* Section 3 row */}
        <div className="flex mt-32 flex-row gap-6 md:flex-col-reverse sm:flex-col-reverse xsm:flex-col-reverse ">
          <div className="w-7/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center">

            <h3 className="Brevia700 italic mb-5">
              “Non ho capito la differenza. Cosa fa Restaurants Club in più?”
            </h3>

            <p className="pop500  mt-5 ">
            Su Restaurants Club puoi fare <span className='pop700'>pubblicità</span> sui social, con cui interecettare <span className='pop700'>potenziali clienti </span> 
            che ancora non ti conoscono.
            </p>

            <p className="pop500  mt-5 mb-10  ">
            In pratica, avrai come un grande “megafono digitale” con cui <span className='pop700'>farti conoscere </span>da tutti: e non serve essre un esperto! 
            Il modulo <span className='pop700'>pubblicità </span> è facile, intuitivo e non ci sono costi aggiuntivi nella pubblicità perché il credito pubblicitario è incluso nel tuo abbonamento!
            </p>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center ">
              <SecondButton onClick={() => navigate("/funzioni")} text="Scopri cosa Restaurants Club può fare per te" />
            </div>

          </div>

          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full  content-center flex mx-auto  ">
            <img src={chefaccording} alt="" className=" mx-auto xmd:h-[50%] self-center flex " />
          </div>
        </div>

        {/* Section 4 Row Reverse*/}
        <div className="flex mt-32 flex-row gap-6 md:flex-col sm:flex-col xsm:flex-col ">
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full content-center grid ">
            <img src={help} alt="-" className=" mx-auto  self-center flex "/>
          </div>
          <div className="w-7/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center  xsm:text-center sm:text-center xmd:ml-8  lg:ml-8 xl:ml-14">
            <h3 className="Brevia700 italic mb-5 ">
              “Ma io non son bravo coi Social o a fare Marketing: come faccio?” 
            </h3>
            <p className="pop500  mt-5   ">
            Le campagne marketing sono già pre-ottimizzate con descrizioni suggerite da usare per i tuoi social. <span className='pop700'>Basta un click e sei online.</span>            
            </p>

            <p className="pop500 mt-4 mb-4 ">
            Sarai sempre affiancato da un nostro<span className='pop700'> Consulente di Marketing </span> Consulente di Marketing che ti guiderà all’interno di Restaurants Club. 
            </p>

            <p className="pop500  mt-4 mb-4  ">
            Se sei <span className='pop700'>interessato </span> ad approfondire le tue conoscenze, segui la nostra <span className='pop700'>Academy</span>: un corso per imparare a usare Restaurants Club, composto da video di pochi minuti. È inclusa nel servizio!
            </p>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center ">

              <SecondButton onClick={() => navigate("/video")} text="Scopri tutti gli strumenti di Restaurants Club"/>

            </div>

          </div>
        </div>
      </div>

      <div className="px-20 mt-10 md:p-0 sm:p-0 xsm:p-0  bg-[#E5FFDD]">
        <div className="container m-auto p-20 md:w-[100%] md:p-12 md:max-w-none md:text-center xsm:w-[100%] xsm:p-12 xsm:max-w-none xsm:text-center sm:w-[100%] sm:p-12 sm:max-w-none sm:text-center">
          <h6 className="text-[#00B17A] Brevia850">
            “Ora è molto più semplice prenotare per i clienti. Le persone riconoscono il mio locale, vedono l'insegna e chiedono subito un tavolo.”
          </h6>
          <p className="pop500 text-lg mt-5">Daniele, titolare del Ristorante “Il Canguro” a Pisa</p>
        </div>
      </div>

      {/* Container 2 */}
      <div className="w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] mx-auto  flex flex-col ">
        {/* Section 4 row */}
        <div className="flex mt-20 flex-row gap-10 md:flex-col-reverse sm:flex-col-reverse xsm:flex-col-reverse ">
          <div className="w-7/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center">

            <h3 className="Brevia700 italic  mb-5 ">
              “Restaurants Club costa di più, è vero?”
            </h3>

            <p className="pop500 my-3 ">
              Qua dobbiamo fare un po’ di matematica. Il pacchetto intermedio di Plateform costa, con i costi di attivazione, circa 100€ 
              al mese e non ti permette di raggiungere nuovi clienti, ti aiuta solo a <span className='pop700'>gestire quelli che già hai.</span>
            </p>

            <p className="pop500   my-3 ">
              Due pacchetti di Restaurants Club sono 300€/mese, inclusi <span className='pop700'>dominio</span>, gestione sito e 90€ di <span className='pop700'>Credito Marketing</span>. 
            </p>

            <p className="pop500 my-3 ">
            Ti starai chiedendo: perchè spendere 300€ e non 100€? Perchè noi <span className='pop700'>te ne facciamo guadagnare 400€ in 10 giorni. </span>
            Guarda i risultati ottenuti da Daniele, ristoratore di Pisa che utilizza Restaurants Club.
            </p>

            <div className="flex sm:justify-center md:justify-center py-10  xsm:justify-center ">
              <SecondButton className="  " onClick={() => navigate("")} text="Scopri la sua storia" />
            </div>

          </div>
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full  content-center flex">
            <img src={quattrocento} alt="" className=" mx-auto self-center flex " />
          </div>
        </div>

        {/* Section 5 Row Reverse*/}
        <div className="flex mt-20 flex-row gap-6 md:flex-col sm:flex-col   xsm:flex-col ">
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full content-center flex ">
            <img src={quattrox} alt="-" className=" mx-auto self-center flex " />
          </div>
          <div className="w-7/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center ">
            <h3 className="Brevia700  mb-5 ">
              “Se investo in pubblicità, quindi quanto ci guadagno?” 
            </h3>
            <p className="pop500 ">
            Dai dati delle campagne pubblicitarie dei nostri ristoratori, sin dalle prime campagne puoi ottenere <span className='pop700'>questi risultati in 10 giorni*</span>:
            </p>
<div  className="m-5">
            <ul className="list-disc pop500  " >
              <li className='md:text-left sm:text-left xsm:text-left '> 15 coperti ottenuti solo tramite Restaurants Club</li>
              <li className='md:text-left sm:text-left xsm:text-left '><span className='pop700 '>Investimento quadruplicato </span></li>
              <li className='md:text-left sm:text-left xsm:text-left '><span className='pop700'>500+ </span>potenziali clienti interagiscono con i post</li>
              <li className='md:text-left sm:text-left xsm:text-left '><span className='pop700'>14 mila </span>potenziali clienti raggiunti dagli annunci</li>
            </ul>
</div>
            <p className="pop500 italic ">
            *Risultati ottenuti usando SOLO il Budget Marketing incluso nel tuo abbonamento.
            </p>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center  my-5">
              <SecondButton onClick={() => navigate("/video")} text="Scopri tutti gli strumenti di Restaurants Club" />
            </div>

          </div>
        </div>

                {/* Section 6 row */}
                <div className="flex mt-20 flex-row gap-6 md:flex-col-reverse sm:flex-col-reverse  xsm:flex-col-reverse  ">
          <div className="w-7/12 xsm:w-full sm:w-full  md:w-full  xmd:w-full  md:text-center sm:text-center xsm:text-center ">
            <h3 className="Brevia700 mb-5 ">            
            “Se non sono sicuro? <br/> Devo vincolarmi per un anno?
            </h3>
            <p className="pop500 ">
            Assolutamente no! Restaurants Club ti offre piani di abbonamenti <span className='pop700'>flessibili </span> che si <span className='pop700'>adattano </span> alle tue esigenze.
            </p>

            <p className="pop500 ">
            Puoi scegliere un abbonamento mensile, trimestrale o annuale e, per ringraziarti della tua fiducia, scegliendo 
            di abbonarti per lunghi periodi potrai beneficiare di uno <span className='pop700'>sconto</span>.
            </p>

            <p className="pop500 ">
            Il nostro servizio si compone di tre pacchetti che puoi scegliere in base ai tuoi obiettivi, così paghi solo 
            quello che ti serve davvero. Puoi sempre disdire quando vuoi, senza penali, e riattivare il servizio quando preferisci.
            </p>

            <div className="flex xsm:justify-center sm:justify-center md:justify-center  pt-10">
              <SecondButton onClick={() => navigate("/funzioni")} text="Scopri di più sui nostri pacchetti" />
            </div>

          </div>
          <div className="w-5/12 xsm:w-full sm:w-full  md:w-full  content-center flex">
            <img src={pack} alt="" className=" mx-auto self-center flex " />
          </div>
        </div>
      </div>

      <div className="px-10 mt-10 md:p-0 sm:p-0 xsm:p-0  bg-[#E5FFDD]">
        <div className="container m-auto p-20 md:w-[100%] md:p-12 md:max-w-none md:text-center sm:w-[100%] sm:p-12 sm:max-w-none sm:text-center xsm:w-[100%] xsm:p-12 xsm:max-w-none xsm:text-center">
          <h6 className="text-[#00B17A] Brevia850">
          “Siamo cresciuti con i like sulle pagine social e ci stanno contattando davvero tante persone. 
          Dire che sono soddisfatto è poco.”
          </h6>
          <p className="pop500  mt-5">Francesco, titolare del Ristorante “Il Piatto Pronto” di Bari</p>
        </div>
      </div>

      {/* Container 3 */}
      <div class="w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] mx-auto  flex flex-col ">
        <div className="py-10 md:py-24 sm:py-20 xsm:py-20">
          <h3 className="Brevia850 text-center 	text-[#114653] mb-4 ">
            Hai ancora dei dubbi su Restaurants Club?
          </h3>
          <p className="pop400 pb-8 text-center  ">
            Prenota una chiamata con un nostro esperto per scoprire come
            Restaurants Club<br className="md:hidden sm:hidden xsm:hidden" /> può aiutare il tuo ristorante. Saremo felici di
            rispondere alle tue domande.
          </p>
          <div className='flex flex-col items-center justify-center gap-y-20'>
          <CalendlyButtonGreen url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club" text="Prenota una chiamata"/>
            </div>
        </div>
      </div>
      
    </div>
  );
};


export default Plateform;
