import ProductTourButton from "../../ProductTourButon";
import Tag from "../../Tag";
import styles from "../Elements.module.css";

const Converted = ({ t }) => {
  const tags = [
    t("homePage.attractiveSection.converted.tags.1"),
    t("homePage.attractiveSection.converted.tags.2"),
    t("homePage.attractiveSection.converted.tags.3"),
  ];
  return (
    <div className={styles.scrolled_section}>
      <h3 className="Brevia700 text-white xsm:text-center sm:text-center md:text-center">
        {t("homePage.attractiveSection.converted.title")}
      </h3>
      <div className="flex flex-col gap-x-4 w-full my-4">
        <p className="pop400 text-base  text-white w-full xsm:text-center sm:text-center md:text-center md:w-[70%]  md:mx-auto">
          {t("homePage.attractiveSection.converted.descriptionPart1")}
          <span className="pop700">
            {t("homePage.attractiveSection.converted.bold1")}
          </span>
          {t("homePage.attractiveSection.converted.descriptionPart2")}
        </p>
      </div>
      <div className="pb-10 md:text-center sm:text-center">
        <h5 className="pop600 text-white py-2">
          {t("homePage.attractiveSection.doItWith")}
        </h5>
        <div className="flex flex-row w-full gap-2 flex-wrap md:justify-center sm:justify-center">
          {tags.map((tag) => (
            <Tag title={tag} color="white" textColor="black" />
          ))}
        </div>
      </div>
      <ProductTourButton fill="white" t={t} />
    </div>
  );
};
export default Converted;
