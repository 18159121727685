export const animatedScroll = (id, duration = 3000) => {
  // the position of the scroll bar before the user clicks the button
  const element = document.getElementById(id);
  const offset = 70; // Offset from the top of the target element
  const bodyRect = document.body.getBoundingClientRect().top;
  const elementRect = element.getBoundingClientRect().top;
  const elementPosition = elementRect - bodyRect;
  const offsetPosition = elementPosition - offset;

  // const duration = 1000 / scrollSpeed; // Calculate duration based on scroll speed

  window.scroll({
    top: offsetPosition,
    behavior: "smooth",
    duration, // Pass the duration for the smooth scroll animation
  });
};
