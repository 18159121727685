import { useInView } from "react-intersection-observer";
import styles from "./Attractive.module.css";
import cn from "classnames";
import Loyalty from "../../../Components/Home/HomeElements/AttractiveElements/Loyalty";
import Converted from "../../../Components/Home/HomeElements/AttractiveElements/Converted";
import Attractive from "../../../Components/Home/HomeElements/AttractiveElements/Attractive";
import ImagesContainer from "../../../Components/Home/HomeElements/AttractiveElements/ImagesContainer";
const AttractiveSection = ({ t }) => {
  const { ref: convertedSectionRef, inView: convertedSectionInView } =
    useInView();
  const { ref: loyaltySectionRef, inView: loyaltySectionInView } = useInView();
  const { ref: attractiveSectionRef, inView: attractiveSectionInView } =
    useInView();
  return (
    <div id="wibx" className={cn("flex flex-col", styles.desktop)}>
      <div
        className={cn(
          " w-full flex justify-center bg-white xsm:h-fit sm:h-fit md:h-fit sm:pb-14  md:pb-14",
          {
            [styles.bg]: convertedSectionInView,
          }
        )}
      >
        <div className=" w-[80%]  xsm:w-full sm:w-full  md:w-full  flex flex-col justify-center mx-auto ">
          <div className="w-full flex flex-row xsm:flex-col sm:flex-col  md:flex-col     ">
            <div className="w-1/3 xsm:mx-auto sm:mx-auto md:mx-auto sm:pt-16 md:pt-16">
              <ImagesContainer
                attractiveSectionInView={attractiveSectionInView}
                convertedSectionInView={convertedSectionInView}
                loyaltySectionInView={loyaltySectionInView}
              />
            </div>

            <div className="w-2/3 xsm:w-full sm:w-full md:w-full p-20 xsm:p-0 sm:p-0 ">
              <div ref={attractiveSectionRef}>
                <Attractive t={t} />
              </div>
              <div style={{ height: 850 }} />
              <div ref={convertedSectionRef}>
                <Converted t={t} />
              </div>
              <div style={{ height: 850 }} />
              <div ref={loyaltySectionRef}>
                <Loyalty t={t} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AttractiveSection;
