import CampaignIcon from "@mui/icons-material/Campaign";
import styles from "./HeroSection.module.css";
import cn from "classnames";
const PricingHero = ({ t }) => {
  return (
    <div className={cn("w-[80%] px-12 pb-12", styles.container)}>
      <div className={cn("mt-20 text-left", styles.sub_container)}>
        <h1 className="text-rcblue Brevia700">
          {t("pricesPage.pricingHero.title")}
        </h1>

        <div className="flex items-center gap-x-6 text-[#475467] mt-6">
          <span
            className={cn("rotate-pause animate-bounce", styles.svg_container)}
          >
            <CampaignIcon />
          </span>

          <h6 className={styles.text}>
            {t("pricesPage.pricingHero.description")}
          </h6>
        </div>
      </div>
    </div>
  );
};
export default PricingHero;
