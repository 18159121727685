import React from "react";
import { PopupButton } from "react-calendly";

const CalendlyButtonSecondlevel = ( { url, text } ) => {

  return (

    <div className="flex w-fit h-max xsm:w-full">

      <PopupButton
        url={url}
        rootElement={document.getElementById("root")}
        text={text}
        className="w-fit h-max px-12 py-3 xsm:px-6 xsm:py-4 sm:px-6 sm:py:4 pop600 border-[3px] border-black xsm:m-auto"
      />
            
    </div>

  )
}

export default CalendlyButtonSecondlevel



            