import axios from "axios";
import { useState } from "react";
import { baseUrl } from "../../../utils/config";
import Validators from "../../../utils/formValidator";
import React from "react";
import { logger } from "../../../Services/Logger";

export const useReachYourCustomers = (t) => {
  const url = `https://${process.env.REACT_APP_WIZARD_PREFIX}${baseUrl()}${process.env.REACT_APP_AUTH_API_PATH
    }/messages`;
  const [Loading, SetLoading] = React.useState(false);
  const [Message, SetMessage] = React.useState(null);

  const [Obiettivi, setObiettivi] = React.useState([]);

  const [recaptcha, setRecaptcha] = useState();
  const handleRecaptchaChange = (value) => setRecaptcha(value);

  const handleChangeObiettivi = (e) => {
    const { name, checked } = e.target;
    if (checked) {
      setObiettivi([...Obiettivi, name]);
    } else {
      setObiettivi(Obiettivi.filter((item) => item !== name));
    }
  };

  const SendEmail = async (data) => {
    if (!recaptcha) {
      return alert(t("homePage.ReachYourCustomersSection.recaptchaAlert"));
    }
    SetMessage(null);
    SetLoading(true);

    axios
      .post(url, {
        email: data.email,
        name: data.name,
        telephone: data.telephone,
        restaurantname: data.restaurantname,
        city: data.city,
        Obiettivi: data.Obiettivi,
        accessoanticipato: "landing_1",
        recaptcha: data.recaptcha,
      })
      .then(() => {
        SetMessage({
          type: "sucess",
          message: t("homePage.ReachYourCustomersSection.successMessage"),
        });
      })
      .catch((error) => logger.error("Reach customers", error))
      .finally(() => SetLoading(false));
  };
  const formInputs = [
    {
      name: "restaurantname",
      label: t("homePage.ReachYourCustomersSection.form.restoName.label"),
      placeholder: t(
        "homePage.ReachYourCustomersSection.form.restoName.placeholder"
      ),
      validate: Validators(t, [{ validation: "required" }]),
    },
    {
      name: "name",
      label: t("homePage.ReachYourCustomersSection.form.name.label"),
      placeholder: t(
        "homePage.ReachYourCustomersSection.form.name.placeholder"
      ),
      validate: Validators(t, [{ validation: "required" }]),
    },
    {
      name: "telephone",
      label: t("homePage.ReachYourCustomersSection.form.phone.label"),
      placeholder: t(
        "homePage.ReachYourCustomersSection.form.phone.placeholder"
      ),
      validate: Validators(t, [
        { validation: "required" },
        { validation: "phone" },
      ]),
    },
    {
      name: "email",
      label: t("homePage.ReachYourCustomersSection.form.email.label"),
      placeholder: t(
        "homePage.ReachYourCustomersSection.form.email.placeholder"
      ),
      validate: Validators(t, [
        { validation: "required" },
        { validation: "email" },
      ]),
    },
    {
      name: "city",
      label: t("homePage.ReachYourCustomersSection.form.city.label"),
      placeholder: t(
        "homePage.ReachYourCustomersSection.form.city.placeholder"
      ),
      validate: Validators(t, [{ validation: "required" }]),
    },
  ];
  const formCheckboxes = [
    {
      name: "0",
      title: t("homePage.ReachYourCustomersSection.form.checkbox1"),
      className: "items-center",
    },
    {
      name: "1",
      title: t("homePage.ReachYourCustomersSection.form.checkbox2"),
      className: "items-center",
    },
    {
      name: "2",
      title: t("homePage.ReachYourCustomersSection.form.checkbox3"),
      className: "items-center",
    },
    {
      name: "3",
      title: t("homePage.ReachYourCustomersSection.form.checkbox4"),
      link: "/privacypolicy",
      linkTitle: t("homePage.ReachYourCustomersSection.form.linkTitle"),
      textClassName: "text-white pop300 font-light text-sm",
      className: "items-center",
      textStyle: { maxWidth: 300 },
    },
  ];
  return {
    Loading,
    Message,
    Obiettivi,
    handleRecaptchaChange,
    handleChangeObiettivi,
    SendEmail,
    formInputs,
    formCheckboxes,
  };
};
