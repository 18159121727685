import cn from "classnames";
import { Link } from "react-router-dom";

const AppCheckbox = ({
  onChange,
  value,
  className,
  link,
  title,
  name,
  textClassName,
  textStyle,
  linkTitle,
}) => (
  <div className={cn("flex flex-row", className)}>
    <input
      type="checkbox"
      name={name}
      className="h-8 w-8 sm:h-8 sm:w-8 md:h-8 md:w-8 mt-2 accent-[#00B27A] mr-2"
      onChange={onChange}
      value={value}
    />
    <p
      style={textStyle}
      className={textClassName || "text-white pop500 font-semibold text-xl"}
    >
      {title}{" "}
      {link && (
        <Link to={link} className="pop500 underline">
          {linkTitle}
        </Link>
      )}
    </p>
  </div>
);
export default AppCheckbox;
