import styles from "./Banner.module.css";
import cn from "classnames";
const Banner = ({ t }) => {
  return (
    <div className="w-full py-1 bg-[#114653] items-center justify-center flex relative uppercase">
      <p style={{ margin: 0 }} className={cn("text-white", styles.title)}>
        {t("banner")}
      </p>
    </div>
  );
};
export default Banner;
