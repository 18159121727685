import React from "react";
import cn from "classnames";
import styles from "./HeroTitle.module.css";

const HeroTitle = ({ t }) => {
  return (

    <div className={cn("w-full")}>
      <div className={cn("w-[70%] mx-auto mt-8 text-center",)}>
        <h1 className={styles.title}>{t("functionPage.functionHero.title")}</h1>
        <p className={styles.paragraph}>{t("functionPage.functionHero.description")}</p>
      </div>
    </div>

  );
};
export default HeroTitle;
