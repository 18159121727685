import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import styles from "./Accordion.module.css";
import cn from "classnames";
const AppAccordion = ({ title, tableData, t }) => (
  <Accordion
    style={{
      borderTopWidth: 4,
      borderBottomWidth: 0,
      marginBottom: 10,
      paddingTop: 24,
    }}
  >
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="funzioni"
    >
      <h4 className={cn("Brevia700 text-[#114653] italic", styles.title)}>
        {title}
      </h4>
    </AccordionSummary>
    <AccordionDetails>
      <div id="tabella" className={styles.table}>
        <table id="funzioni" className="w-full mt-5">
          <tr className="">
            <th className="h-24 w-1/5 sm:w-fit">
              <h5 className=" text-center text-[#114653]">
                {t("homePage.faqSection.tableHeaders.module")}
              </h5>
            </th>
            <th className="h-24 w-1/5 sm:w-fit">
              <h5 className=" text-center text-[#114653]">
                {t("homePage.faqSection.tableHeaders.advantages")}
              </h5>
            </th>
            <th className="h-24 w-1/5 sm:w-fit">
              <h5 className=" text-center text-[#114653]">
                {t("homePage.faqSection.tableHeaders.instruments")}
              </h5>
            </th>
          </tr>
          {tableData.map((element, index) => (
            <tr className={index / 2 === 0 ? "bg-[#F6F6F4]" : ""}>
              <td className="h-24  ">
                <h6 className=" text-center text-[#000000] ">
                  {element.module}
                </h6>
              </td>
              <td className="h-24  ">
                <p className="pop400 text-center text-[#000000] ">
                  {element.advantages}
                </p>
              </td>
              <td className="h-24  ">
                <p className="pop400 text-center text-[#000000] ">
                  {element.instruments}
                </p>
              </td>
            </tr>
          ))}
        </table>
      </div>
    </AccordionDetails>
  </Accordion>
);
export default AppAccordion;
