import React from "react";
import ArrowDownButton from "../../../Components/Home/HomeElements/ArrowDownButton";
import { animatedScroll } from "../../../utils/animatedScroll";

const AdvantagesSection = ({ t }) => {
  return (
    <div className="py-8 sm:h-fit sm:gap-y-7 sm:py-8 flex flex-col gap-y-5 items-center  bg-[#F6F6F4]  h-fit relative ">
      <div className="w-full xl:w-[90%] px-[40px] mx-auto flex flex-row xsm:flex-col sm:flex-col   gap-x-10 xsm:gap-y-20 sm:gap-y-20 md:gap-y-20 xmd:gap-y-20 pb-[10px] pt-3">
        <div className=" w-1/3 xsm:w-full sm:w-full   xmd:w-full  mx-auto flex flex-col  ">
          <h5 className="pop700  not-italic  text-center text-black  mb-[15px] ">
            {t("homePage.advantagesSection.part1.title")}
          </h5>
          <p className="pop500  not-italic   text-center text-black">
            {t("homePage.advantagesSection.part1.description")}
          </p>
        </div>

        <div className=" w-1/3 xsm:w-full sm:w-full    xmd:w-full  mx-auto flex flex-col   ">
          <h5 className="pop700  not-italic text-center text-black  mb-[15px] ">
            {t("homePage.advantagesSection.part2.title")}
          </h5>
          <p className="pop500  not-italic text-center text-black">
            {t("homePage.advantagesSection.part2.description")}
          </p>
        </div>

        <div className=" w-1/3 xsm:w-full sm:w-full  xmd:w-full  mx-auto flex flex-col ">
          <h5 className="pop700  not-italic text-center text-black  mb-[15px]   ">
            {t("homePage.advantagesSection.part3.title")}
          </h5>
          <p className="pop500  not-italic text-center text-black">
            {t("homePage.advantagesSection.part3.description")}
          </p>
        </div>
      </div>

      <div className=" w-[78.5%] px-[40px] mx-auto flex flex-row gap-x-3 pb-[24px] ">
        <div className=" w-[77px] mx-auto flex">
          <ArrowDownButton onClick={() => animatedScroll("wibx")} />
        </div>
      </div>
    </div>
  );
};

export default AdvantagesSection;
