import React from "react";
import { useNavigate } from "react-router-dom";

import { PopupButton } from "react-calendly";
import ScrollToTop from "../../Reusable/ScrollToTop";

import bidding from "./Img/bidding.png";
import ani1 from "./Img/ani1.png";
import ani2 from "./Img/ani2.png";

import post1 from "./Img/post1.png";
import post2 from "./Img/post2.png";
import post3 from "./Img/post3.svg";
import post4 from "./Img/post4.svg";
import chef from "./Img/chef.png";


import Frame4 from "../../Assets/img1.svg";
import Frame5 from "../../Assets/converti.svg";
import Frame6 from "../../Assets/img3.svg";
import Phone from "../../Assets/Phone.svg";


import { useInView } from "react-intersection-observer";
import BottomForm from '../../Reusable/BottomForm';
import BiddingForm from './BiddingForm';
import BiddingForm1 from '../Bidding/BiddingForm1';
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function Home() {
  const { ref, inView, entry } = useInView();
  const navigate = useNavigate();

  return (
    <div className=' w-full flex flex-col gap-y-8'>
      <ScrollToTop />






      <div className=' flex flex-col '>

        <div className='w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] mx-auto  items-center flex flex-row sm:flex-col xsm:flex-col  gap-x-12  h-fit relative '>
          <div className='w-1/2 sm:w-full xsm:w-full md:w-full xmd:w-full text-left flex flex-col mx-auto items-left justify-center  gap-8 ' >

            <p className='Brevia850 text-5xl font-black not-italic leading-[60px] xmd:text-3xl md:text-3xl sm:text-3xl xsm:text-3xl  sm:text-center xsm:text-center text-[#114653]'>
              TheFork ti obbliga a fare sconti?<br /> Ora scelgli tu. </p>

            <p className='pop500 text-lg xmd:text-lg sm:text-xmd  sm:text-center xsm:text-center text-[#114653]'>
              <b><i>Restaurants Club</i></b> è la piattaforma con cui puoi far conoscere il tuo ristorante online e creare una solida base clienti fedele.Sei sempre tu a scegliere cosa fare. Sempre senza commissioni.</p>


              <div className='w-full  mx-auto my-6' >
        
        <BiddingForm1 />
        </div>
    

          </div>

          <div className='h-fit xsm:w-full sm:w-full p-3 m-10 flex items-center justify-center w-1/3 '>
            <img
              src={bidding}
              alt='hero'
              className='h-full xl:w-[95%] lg:w-[95%] '
            />
          </div>
        </div>


             

        <div className='w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] mx-auto flex flex-col   h-fit relative pt-9 pb-5 '>
          <div className='w-full xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto pb-6 '>
            <p className='Brevia850 text-5xl xsm:text-4xl sm:text-4xl md:text-4xl xmd:text-4xl text-[#114653] text-center font-black  '>
              Smetti di pagare tu per il loro successo.
            </p>
          </div>

          <div className='flex flex-row sm:flex-col my-2'>
            <div className=' '>
              <div className='flex flex-col items-center w-8/10 xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto  pb-6 '>
                <p className='pop500 text-xl text-center text-black font-medium leading-9'>
                  TheFork ti obbliga a fare sconti per attirare sempre nuovi utenti sulla loro App? No, grazie. Con Restaurants Club puoi decidere TU se e quali promozioni vuoi fare con il Modulo Offerte. Perchè non offrire una bibita o il dessert in omaggio solo ai tuoi clienti più affezionati?                 </p>
              </div>
            </div>
          </div>
        </div>


        <div className='w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] xmd:w-[95%]  mx-auto flex flex-col  '>

        <div className='w-full flex flex-row xsm:flex-col sm:flex-col  md:flex-col  gap-y-5'>
          <div className='w-1/2 xsm:mx-auto sm:mx-auto md:mx-auto sm:pt-5   md:pt-16  xmd:pt-16  xsm:w-full sm:w-full  md:w-full '>
              <div className=' w-full flex items-center justify-center flex-column p-10'>
                    <div className="">
                    <img src={post3} /> 
                    </div>
                    </div>


            <div className="animation-color-div-flex">
              <div className="ani-img">
                <img src={ani1} />
              </div>
              <div className="ani-txt bg-pink">
                <p>Sconti obbligatori su TheFork</p>
              </div>
            </div>
          </div>



          <div className='w-1/2 xsm:mx-auto sm:mx-auto md:mx-auto sm:pt-5  md:pt-16  xmd:pt-16  xsm:w-full sm:w-full  md:w-full '>
          <div className=' w-full flex items-center justify-center flex-column p-10 '>
                    <div className="">
                    <img src={post4} className=''/> 
                    </div>
                    </div>


            <div className="animation-color-div-flex">
              <div className="ani-img">
                <img src={ani2} />
              </div>
              <div className="ani-txt bg-green">
                <p>Offerte create da te per i tuoi clienti con Restaurants Club</p>
              </div>

            </div>
          </div>
</div>

        </div>






        <div className="blue-grad">
          <div className=' w-[85%] px-[40px]  xsm:w-full sm:w-full    flex flex-col justify-center mx-auto py-10 '>

            <div className='w-full gap-4 flex flex-row xsm:flex-col sm:flex-col  '>

              <div className='w-1/3 xsm:w-full sm:w-full '>
                <p className='text-4xl  text-[#fff] Brevia850 line-height-4'>
                  Riprenditi i tuoi clienti.<br></br>
                  Inizia ora.
                </p>
              </div>

              <div className='w-2/3 flex flex-col gap-6 xsm:w-full sm:w-full  md:w-full p-3 md:p-5 md:py-3 sm:p-0 mx-auto '>
                <BiddingForm />
              </div>
            </div>

            <div className='  w-full py-3 '>
              <div className=' flex flex-row xsm:flex-col sm:flex-col  md:flex-col gap-5 w-full  m-3' >
              <PopupButton
                className='pop400 text-base text-[#fff] text-underline'
                url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club"
                rootElement={document.getElementById("root")}
                text="Oppure chiamaci per avere maggiori informazioni"

              />
                   <WhatsAppIcon color="white" size={20} className="text-white" />{" "}
            <a href="https://wa.me/+393312910408" className="text-white">
              331 291 0408
            </a>
              </div>
            </div>
          </div>
        </div>


        <div className='w-[80%] px-[40px]  xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto flex flex-col   h-fit relative pt-9 pb-5 '>
          <div className='w-full xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto pb-6 '>
            <p className='Brevia850 text-5xl xsm:text-4xl sm:text-4xl md:text-4xl xmd:text-4xl text-[#114653] text-center font-black '>
              Smetti di pagare commissioni sul tuo lavoro.
            </p>
          </div>

          <div className='flex flex-row sm:flex-col my-2'>
            <div className=' '>
              <div className='flex flex-col items-center w-8/10 xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto  pb-6 '>
                <p className='pop500 text-xl text-center text-black font-medium leading-9'>
                Stanco di pagare commissioni che si portano via il tuo fatturato? Ricevere molte prenotazioni online senza marketplace come TheFork è possibile: utilizza il modulo Prenotazioni sul tuo sito web e app forniti da Restaurants Club e inizia subito a ricevere prenotazioni tavolo, senza pagare commissioni.
                </p>
              </div>
            </div>
          </div>
        </div>


        <div className=' w-[80%] px-[40px]  xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto flex flex-col   h-fit relative pt-9 pb-5 '>
          <div className="animation-color-div-flex">
            <div className="ani-img">
              <img src={ani1} />
            </div>
            <div className="ani-txt bg-pink">
              <p>10 prenotazioni su TheFork</p>
              <p className="big-p">=</p>
              <p className="big-p">2€</p>
              <p>per ogni prenotazione</p>
            </div>
          </div>
          <div className="animation-color-div-flex">

            <div className="ani-txt bg-green">
              <p>10 Clienti presi con il tuo sito</p>
              <p className="big-p">=</p>
              <p className="big-p">0€</p>
              <p>Commissioni. Sempre.</p>
            </div>
            <div className="ani-img">
              <img src={ani2} />
            </div>
          </div>
        </div>



        <div className="blue-grad">
          <div className=' w-[85%] px-[40px]  xsm:w-full sm:w-full    flex flex-col justify-center mx-auto py-10 '>

            <div className='w-full gap-4 flex flex-row xsm:flex-col sm:flex-col  '>

              <div className='w-1/3 xsm:w-full sm:w-full '>
                <p className='text-4xl  text-[#fff] Brevia850 line-height-4'>
                Smetti di pagare le commissioni.<br></br>
                Inizia ora.
                </p>
              </div>

              <div className='w-2/3 flex flex-col gap-6 xsm:w-full sm:w-full  md:w-full p-3 md:p-5 md:py-3 sm:p-0 mx-auto '>
                <BiddingForm />
              </div>
            </div>

            <div className='  w-full py-3 '>
              <div className=' flex flex-row xsm:flex-col sm:flex-col  md:flex-col gap-5 w-full  m-3' >
              <PopupButton
                className='pop400 text-base text-[#fff] text-underline'
                url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club"
                rootElement={document.getElementById("root")}
                text="Oppure chiamaci per avere maggiori informazioni"

              />
                            <WhatsAppIcon color="white" size={20} className="text-white" />{" "}
            <a href="https://wa.me/+393312910408" className="text-white">
              331 291 0408
            </a>
              </div>
            </div>
          </div>
        </div>

        <div className='  w-[80%] px-[40px]  xsm:w-full sm:w-full  md:w-full  xmd:w-full   mx-auto flex flex-col   h-fit relative pt-9 pb-5 '>
          <div className='w-full xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto pb-6 '>
            <p className='Brevia850 text-5xl xsm:text-4xl sm:text-4xl md:text-4xl xmd:text-4xl text-[#114653] text-center font-black '>
            Fai parlare del tuo ristorante. Non di TheFork.
            </p>
          </div>

          <div className='flex flex-row sm:flex-col my-2'>
            <div className=' '>
              <div className='flex flex-col items-center w-8/10 xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto  pb-6 '>
                <p className='pop500 text-xl text-center text-black font-medium leading-9'>
                I Marketplace come TheFork lavorano facendo pubblicità al Marketplace stesso, non al tuo ristorante. Il tuo ristorante è uno dei tanti, un mezzo per ottenere più clienti e quindi più commissioni dal tuo lavoro.
                </p>
              </div>
            </div>
          </div>
        </div>


        <div className=' w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] xmd:w-[95%]  mx-auto flex flex-col  '>

              <div className='w-full flex flex-row xsm:flex-col sm:flex-col  md:flex-col     '>
                <div className='w-1/2 xsm:mx-auto sm:mx-auto md:mx-auto sm:pt-16   md:pt-16  xmd:pt-16  xsm:w-full sm:w-full  md:w-full'>
                  <div className=' w-full flex items-center justify-center flex-column '>
                    <div className="same-height">
                      <img
                        src={post1}
                      />
                    </div>
                    <div className="animation-color-div-flex">
                      <div className="ani-img">
                        <img src={ani1} />
                      </div>
                      <div className="ani-txt bg-pink">
                        <p>La pubblicità di TheFork sui social</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='w-1/2 xsm:mx-auto sm:mx-auto md:mx-auto sm:pt-16   md:pt-16  xmd:pt-16  xsm:w-full sm:w-full  md:w-full'>
                  <div className=' w-full flex items-center justify-center flex-column '>
                    <div className="same-height"><img
                      src={post2}
                    /></div>
                    <div className="animation-color-div-flex">
                      <div className="ani-img">
                        <img src={ani2} />
                      </div>
                      <div className="ani-txt bg-green">
                        <p>La pubblicità de “Il canguro”  con Restaurants Club</p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>



        <div className="blue-grad">
          <div className=' w-[85%] px-[40px]  xsm:w-full sm:w-full    flex flex-col justify-center mx-auto py-10 '>

            <div className='w-full gap-4 flex flex-row xsm:flex-col sm:flex-col  '>

              <div className='w-1/3 xsm:w-full sm:w-full '>
                <p className='text-4xl  text-[#fff] Brevia850 line-height-4'>
                  Riprenditi i tuoi clienti.
                  Inizia ora.
                </p>
              </div>

              <div className='w-2/3 flex flex-col gap-6 xsm:w-full sm:w-full  md:w-full p-3 md:p-5 md:py-3 sm:p-0 mx-auto '>
                <BiddingForm />
              </div>
            </div>

            <div className='  w-full py-3 '>
              <div className=' flex flex-row xsm:flex-col sm:flex-col  md:flex-col gap-5 w-full  m-3' >
              <PopupButton
                className='pop400 text-base text-[#fff] text-underline'
                url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club"
                rootElement={document.getElementById("root")}
                text="Oppure chiamaci per avere maggiori informazioni"

              />
                            <WhatsAppIcon color="white" size={20} className="text-white" />{" "}
            <a href="https://wa.me/+393312910408" className="text-white">
              331 291 0408
            </a>
              </div>
            </div>
          </div>
        </div>


        <div className=' w-[80%] px-[40px] xsm:w-full sm:w-full  md:w-full  xmd:w-full   mx-auto flex flex-col   h-fit relative pt-9 pb-5 '>
          <div className='w-full xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto pb-6 '>
            <p className='Brevia850 text-5xl xsm:text-4xl sm:text-4xl md:text-4xl xmd:text-4xl text-[#114653] text-center font-black '>
              Perché Restaurants Club
            </p>
          </div>

          <div className='flex flex-row sm:flex-col my-1 justify-center'>
            <div className=' '>
              <div className='flex flex-col items-center w-8/10 xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto'>
                <p className='pop500 text-xl text-center text-black font-medium leading-9 '>
                  <i>Cosa puoi fare con gli strumenti di Restaurants Club</i>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='py-5 ' >
          <div className='w-[80%] px-[40px] sm:px-2 sm:w-full sm:flex-col gap-y-6 md:w-full md:px-6 md:flex-col    xsm:flex-col  mx-auto flex flex-row gap-x-9'>

            <div className='w-1/3 md:w-full sm:w-full xmd:w-full xsm:w-full  mx-auto flex flex-col gap-y-5 items-center'>

              <div className=' w-full flex items-center justify-center  p-6'>
                <img src={Frame6} className='xsm:w-1/2 sm:w-1/2  md:w-1/2 w-full h-full' alt="" />
              </div>
              <p className='Brevia700 not-italic font-bold  text-5xl sm:text-3xl text-center text-[#114653] '>Attrai</p>
              <p className='pop400    not-italic font-normal text-xl sm:text-lg text-center text-black  mx-auto'>Cattura l’attenzione su internet e fai conoscere la tua attività</p>

            </div>

            <div className=' w-1/3 md:w-full sm:w-full xmd:w-full xsm:w-full  mx-auto flex flex-col gap-y-5 '>
              <div className=' w-full  flex items-center justify-center  p-6'>
                <img src={Frame5} className='xsm:w-1/2 sm:w-1/2  md:w-1/2 w-full h-full' alt="" />
              </div>
              <p className='Brevia700 not-italic font-bold  text-5xl sm:text-3xl  text-center text-[#114653]   '>Converti </p>
              <p className='pop400 not-italic font-normal  text-xl sm:text-lg text-center text-black  mx-auto'>Porta nuovi clienti al tuo ristorante e aumenta il tuo fatturato</p>

            </div>

            <div className=' w-1/3 md:w-full sm:w-full xmd:w-full xsm:w-full mx-auto flex flex-col gap-y-5 '>
              <div className='w-full flex items-center justify-center  p-6'>
                <img src={Frame4} className='xsm:w-1/2 sm:w-1/2  md:w-1/2 w-full h-full' alt="" />
              </div>
              <p className='Brevia700  not-italic font-bold  text-5xl sm:text-3xl  text-center text-[#114653]   '>Fidelizza </p>
              <p className='pop400     not-italic font-normal text-xl sm:text-lg text-center text-black mx-auto'>Crea una base clienti fedele e affezionata con cui crescere</p>

            </div>

          </div>
          <div className='my-10 mx-auto w-[80%]  '>
            <div className='mx-auto my-5 w-72 h-12 border-[3px] bg-[#DCFFCF] border-black items-center flex justify-center pop600 text-lg text-black'>

              <PopupButton
                url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club"
                rootElement={document.getElementById("root")}
                text="Vedilo in azione"
              />

            </div>

            <div className='mx-auto flex text-center'>

              <PopupButton
                className='text-center pop600 text-lg mx-auto text-black sm:text-sm xmd:text-sm underline'
                url="https://calendly.com/d/ytd-4dt-3w3/chiamata-con-il-team-di-restaurants-club"
                rootElement={document.getElementById("root")}
                text="Oppure chiamaci per avere maggiori informazioni"

              />

            </div>
          </div>
        </div>


        <div className='w-[80%] px-[40px]  sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] xsm:w-full sm:w-full  md:w-full  xmd:w-full  mx-auto flex flex-col   h-fit relative pt-9 pb-5 '>
          <div className='w-full mx-auto pb-6 '>
            <p className='Brevia850 text-5xl xsm:text-4xl sm:text-4xl md:text-4xl xmd:text-4xl text-[#00B27A] text-center font-black '>La storia del ristorante “Il Canguro”</p>
          </div>
        </div>


        <div className='w-[80%] px-[40px] sm:px-[5px] xsm:px-[5px] md:px-[5px] xmd:px-[5px] flex flex-col mb-10  mx-auto  '>


          <div className='w-full flex flex-row xsm:flex-col sm:flex-col  md:flex-col  gap-x-14   '>


            <div className='w-2/3 xsm:w-full sm:w-full  md:w-full  xmd:w-full  content-center grid pb-5'>
              <div className=''>
                <div className='flex flex-col gap-x-4 w-full  '>
                  <p className='text-center pop400  text-lg text-black '>
                    "Ora è molto più semplice prenotare per i clienti. Le persone riconoscono il mio locale, vedono l'insegna e chiedono subito un tavolo."
                  </p>
                  <span className="mt-5 mb-3 pop400  text-lg text-black w-full text-right">-Daniele, proprietario de “Il Canguro”</span>
                  <div className="w-full flex flex-row xsm:flex-col sm:flex-col  md:flex-col justify-center ">
                    <div className='xsm:w-full sm:w-full  md:w-full  xmd:w-full  px-[10px] mx-auto flex flex-col   h-fit relative pt-9 pb-5'>
                      <div className='w-full xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto  '>
                        <p className='mb-3 Brevia850 text-4xl xsm:text-4xl sm:text-4xl md:text-4xl xmd:text-4xl text-[#00B27A] text-center font-black '>
                          + 51 mila
                        </p>
                        <p className='text-center pop400  text-lg text-black w-full xsm:text-center sm:text-center md:text-center'>
                          utenti raggiunti tramite social media </p>
                      </div>
                    </div>
                    <div className='xsm:w-full sm:w-full  md:w-full  xmd:w-full  px-[10px] mx-auto flex flex-col   h-fit relative pt-9 pb-5'>
                      <div className='w-full xsm:w-full sm:w-full  md:w-full  xmd:w-full mx-auto '>
                        <p className='mb-3 Brevia850 text-4xl xsm:text-4xl sm:text-4xl md:text-4xl xmd:text-4xl text-[#00B27A] text-center font-black '>
                          + 48,8%
                        </p>
                        <p className='text-center pop400  text-lg text-black w-full xsm:text-center sm:text-center md:text-center'>
                          richieste di indicazioni stradali su Google </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=' md:w-1/2 md:mx-auto'>
              <div className=' w-full flex items-center justify-center after-img'>
                <img src={chef} />
              </div>
            </div>
          </div>
        </div>



      </div>
      <BottomForm title={`Hai ancora dubbi?  Chiedi un parere a un nostro esperto!`} />
    </div>
  );
}

export default Home;
