const TableSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <mask
        id="mask0_13_4056"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="18"
        height="18"
      >
        <rect width="18" height="18" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_13_4056)">
        <path
          d="M3.24372 6.75H14.7562L14.1187 4.5H3.89997L3.24372 6.75ZM12.6 8.25H5.41872L5.21247 9.75H12.7875L12.6 8.25ZM2.99997 15L3.91872 8.25H2.24997C1.99997 8.25 1.8031 8.15 1.65935 7.95C1.5156 7.75 1.47497 7.53125 1.53747 7.29375L2.60622 3.54375C2.65622 3.38125 2.74372 3.25 2.86872 3.15C2.99372 3.05 3.14372 3 3.31872 3H14.6812C14.8562 3 15.0062 3.05 15.1312 3.15C15.2562 3.25 15.3437 3.38125 15.3937 3.54375L16.4625 7.29375C16.525 7.53125 16.4843 7.75 16.3406 7.95C16.1968 8.15 16 8.25 15.75 8.25H14.1L15 15H13.5L12.9937 11.25H5.00622L4.49997 15H2.99997Z"
          fill="#00B27A"
        />
      </g>
    </svg>
  );
};
export default TableSvg;
